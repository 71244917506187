import { ShortUrl } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicSourcesListKey = ({
  shortId,
  boardId,
}: {
  shortId: string | undefined;
  boardId: Board['id'] | undefined;
}) => ['PUBLIC_SOURCES', { shortId, boardId }];

export const usePublicSourcesList = ({ shortId, boardId }: { shortId: string; boardId: Board['id'] | undefined }) => {
  const key = getPublicSourcesListKey({ shortId, boardId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      // no need for search here - the number of sources is limited, shouldn't be more than ~20
      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 50,
        descendantBoardId: boardId,
        shortId,
        field: 'source',
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!shortId,
  });
};
