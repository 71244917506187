import { ShortUrl } from '@air/api';
import { Clip } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

const PUBLIC_CLIP = 'PUBLIC_CLIP';

export const getPublicClipKey = (clipId: Clip['id']) => [PUBLIC_CLIP, { clipId }];

export function usePublicClip(shortId: string, clipId: Clip['id']) {
  return useQuery({
    queryKey: getPublicClipKey(clipId),
    queryFn: () => ShortUrl.getBoardClip(shortId, clipId),
    enabled: !!shortId && !!clipId,
  });
}
