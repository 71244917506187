import { useTrackClickedPublicBoardLink } from '@air/analytics';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { memo, useCallback } from 'react';

import { SignupModal } from '~/components/Modals/SignupModal/SignUpModal';

export const PublicSignUpButton = memo(() => {
  const [showSignUpModal] = useAirModal(SignupModal);
  const { trackClickedPublicBoardLink } = useTrackClickedPublicBoardLink();

  const onSignUpClick = useCallback(() => {
    showSignUpModal({
      ssoCallbackData: {
        url: Router.asPath,
      },
      accountCreationSource: 'share-link',
    });

    trackClickedPublicBoardLink({
      name: 'clicked login',
      page_area: 'banner',
      page_type: 'public board',
    });
  }, [showSignUpModal, trackClickedPublicBoardLink]);

  return (
    <button
      className="cursor-pointer px-0.5 text-14 font-semibold text-grey-10 hover:underline"
      onClick={onSignUpClick}
    >
      Sign up
    </button>
  );
});

PublicSignUpButton.displayName = 'PublicSignUpButton';
