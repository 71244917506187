import { useState } from 'react';
import { useMount, useMountedState } from 'react-use';

/**
 * Sometimes you need to cause an additional re-render on mount. This is often to fix rehydration issues with server-side and client-side discrepancies.
 */
export const useRerenderOnMount = () => {
  const getIsMounted = useMountedState();
  const [_, rerender] = useState(0);
  const isMounted = getIsMounted();

  useMount(() => {
    rerender(1);
  });

  return { isMounted };
};
