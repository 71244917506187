import { useAirModal } from '@air/provider-modal';
import { useEffect, useState } from 'react';

import LoginModal, { LoginModalProps } from '~/components/Modals/LoginModal/LoginModal';

export interface UseAnonymousSignUpModalLogicProps {
  onLoginSuccess: LoginModalProps['onSuccess'];
  ssoCallbackData: LoginModalProps['ssoCallbackData'];
}

/**
 * This hook is used to switch between anonymous signup and login modals.
 * When user clicks "Log in", the hook hides anonymous sign up modal and shows login/signup modals
 * When user clicks 'Close' on LoginModal/SignUpModal, it shows back AnonymousSignUpModal
 */
export const useAnonymousSignUpModalLogic = ({
  onLoginSuccess,
  ssoCallbackData,
}: UseAnonymousSignUpModalLogicProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal] = useAirModal(LoginModal);

  useEffect(() => {
    // AnonymousSignUpModal is rendered with AnonymousSignUpOverlay, which is a portal
    // if we render modal first, it will be behind the overlay
    setIsOpen(true);
  }, []);

  const onFullLoginClick = () => {
    setIsOpen(false);
    setTimeout(() => {
      showLoginModal({
        ssoCallbackData,
        onSuccess: onLoginSuccess,
        // Set background to transparent to avoid switching background while changing modals.
        // This modal should be displayed with AnonymousSignUpOverlay rendered somewhere in DOM
        overlayClassName: 'bg-transparent',
        onCancel: () => setIsOpen(true),
        accountCreationSource: 'share-link',
      });
    }, 100);
  };

  return {
    onFullLoginClick,
    isOpen,
  };
};
