import { Link } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';

import { SharePublicBoardModal } from '~/components/Modals/SharePublicBoardModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { PublicBoardDownloadMenu } from '~/components/Shared/HeaderBar/PublicBoardDownloadMenu';
import { SHARE_BUTTON } from '~/constants/testIDs';
import { BoardPageBoard } from '~/store/centralizedBoard/types';
import { getPublicSubboardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export interface PublicBoardHeaderActionsProps {
  board: BoardPageBoard;
}

export const PublicBoardHeaderActions = ({ board }: PublicBoardHeaderActionsProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const [showSharePublicBoardModal] = useAirModal(SharePublicBoardModal);
  const { permissions } = usePublicPermissions();
  const { shortId } = useShortIdContext();

  const canDownload = canGenerateZip(permissions);

  if (!isAboveMediumScreen) {
    return null;
  }

  const onShareClick = () =>
    showSharePublicBoardModal({
      id: board.id,
      url: getPublicSubboardShareUrl(shortId, board.id),
      trackLocation: 'header',
    });

  return (
    <div className="flex shrink-0">
      {canDownload ? (
        <IconButton
          label="Share"
          icon={Link}
          size="large"
          appearance="filled"
          color="grey"
          data-testid={SHARE_BUTTON}
          onClick={onShareClick}
        />
      ) : (
        <Button size="large" appearance="filled" color="grey" data-testid={SHARE_BUTTON} onClick={onShareClick}>
          Share
        </Button>
      )}
      {canDownload && <PublicBoardDownloadMenu board={board} />}
    </div>
  );
};
