import { memo, useCallback } from 'react';

import {
  CamerasFilterCard,
  CamerasFilterCardProps,
} from '~/components/Filters/CamerasFilter/components/CamerasFilterCard';
import { PublicCameraMakeSelect } from '~/components/Filters/CamerasFilter/components/PublicCameraMakeSelect';
import { PublicCameraModelsSelect } from '~/components/Filters/CamerasFilter/components/PublicCameraModelsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';

interface PublicCamerasFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicCamerasFilterCard = memo(({ boardId, ...props }: PublicCamerasFilterCardProps) => {
  const renderStringSelect = useCallback<CamerasFilterCardProps['CameraModelsSelect']>(
    (props) => <PublicCameraModelsSelect boardId={boardId} {...props} />,
    [boardId],
  );

  const renderMakeSelect = useCallback<CamerasFilterCardProps['CameraMakeSelect']>(
    (props) => <PublicCameraMakeSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return <CamerasFilterCard CameraModelsSelect={renderStringSelect} CameraMakeSelect={renderMakeSelect} {...props} />;
});

PublicCamerasFilterCard.displayName = 'PublicCamerasFilterCard';
