import { useActionBar } from '@air/provider-action-bar';
import { useToasts } from '@air/provider-toast';
import { memo, useEffect } from 'react';

import { useGetPublicAssetMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicAssetMenuOptionsCallback';
import { useGetPublicBoardMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicBoardMenuOptionsCallback';
import { BoardsAndAssetsSelectionBar } from '~/components/SelectionActionBar/BoardsAndAssetsSelectionBar';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';

export const PublicBoardsAndAssetsSelectionBar = memo(() => {
  const { getBoardMenuOptionsCallback } = useGetPublicBoardMenuOptionsCallback();
  const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { actionBar } = useActionBar();
  const { setToastBottomOffset } = useToasts();

  useEffect(() => {
    setToastBottomOffset(actionBar ? 72 : 0);
  }, [actionBar, setToastBottomOffset]);

  return (
    <BoardsAndAssetsSelectionBar
      getBoardMenuOptions={getBoardMenuOptionsCallback}
      getFileMenuOptions={getAssetMenuOptionsCallback}
      getAssetMenuOptions={getAssetMenuOptionsCallback}
      getSelectionMenuOptions={getSelectionMenuOptions}
    />
  );
});

PublicBoardsAndAssetsSelectionBar.displayName = 'PrivateBoardsAndAssetsSelectionBar';
