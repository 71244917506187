import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

const PUBLIC_SEARCH_SUGGESTIONS = 'PUBLIC_SEARCH_SUGGESTIONS';

export const getPublicSuggestionsKey = (query: string, shortId?: string) => [
  PUBLIC_SEARCH_SUGGESTIONS,
  { query, shortId },
];

export const usePublicSearchSuggestions = ({
  search,
  shortId,
  canFetch,
}: {
  search: string;
  shortId?: string;
  canFetch: boolean;
}) => {
  const query = qs.stringify({ search, shortId });

  return useQuery({
    queryKey: getPublicSuggestionsKey(query, shortId),
    queryFn: () => ShortUrl.getSuggestions(shortId!, search, 8),
    enabled: !!shortId && !!canFetch,
  });
};
