import { BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import {
  PanelCustomFieldContainer,
  PanelCustomFieldContainerProps,
} from '~/components/RightPanel/CustomFields/PanelCustomFieldContainer';

export type PublicBoardPanelCustomFieldContainerProps = Omit<
  PanelCustomFieldContainerProps<BoardCustomFieldValue>,
  'canDeleteCustomFields' | 'canEditCustomFields' | 'onClick'
>;

export const PublicBoardPanelCustomFieldContainer = memo((params: PublicBoardPanelCustomFieldContainerProps) => {
  return <PanelCustomFieldContainer canDeleteCustomFields={false} canEditCustomFields={false} {...params} />;
});

PublicBoardPanelCustomFieldContainer.displayName = 'PublicBoardPanelCustomFieldContainer';
