import { useTrackBoardCustomFieldsSidePanelOpened } from '@air/analytics';
import { CustomFieldsPanel, CustomFieldsPanelContent } from '@air/component-custom-fields-panel';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PublicBoardHeaderAncestors } from '~/_app/(public-board)/_components/PublicBoardHeaderAncestors';
import { usePublicCustomFieldsPanel } from '~/components/PublicBoard/components/PublicBoardCustomFieldsPanelContent/hooks/usePublicCustomFieldsPanel';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { usePublicBoardCustomFieldsData } from '~/swr-hooks/customFields/usePublicBoardCustomFieldsData';

import { PublicBoardPanelCustomFieldDateInput } from './PublicBoardPanelCustomFieldDateInput';
import { PublicBoardPanelCustomFieldPlainText } from './PublicBoardPanelCustomFieldPlainText';
import { PublicBoardPanelCustomFieldSelect } from './PublicBoardPanelCustomFieldSelect';

export const PublicBoardCustomFieldsPanel = memo(() => {
  const boardId = useURLBoardIdSelector();
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const { trackBoardCustomFieldsSidePanelOpened } = useTrackBoardCustomFieldsSidePanelOpened();
  const { data: customFields } = usePublicBoardCustomFieldsData();
  const { closePanel, isPanelOpen } = usePublicCustomFieldsPanel();

  useEffect(() => {
    if (isPanelOpen && boardId) {
      trackBoardCustomFieldsSidePanelOpened({ boardId });
    }
  }, [boardId, isPanelOpen, trackBoardCustomFieldsSidePanelOpened]);

  if (!isPanelOpen) {
    return null;
  }

  return (
    <CustomFieldsPanel breadcrumbs={<PublicBoardHeaderAncestors />} onClose={closePanel} title={boardTitle}>
      <CustomFieldsPanelContent
        fields={customFields?.data}
        SelectCustomField={PublicBoardPanelCustomFieldSelect}
        DateCustomField={PublicBoardPanelCustomFieldDateInput}
        PlainTextCustomField={PublicBoardPanelCustomFieldPlainText}
      />
    </CustomFieldsPanel>
  );
});

PublicBoardCustomFieldsPanel.displayName = 'PublicBoardCustomFieldsPanel';
