import { useShortIdContext } from '@air/provider-short-id';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';

import { AnonymousSignUpFormWrapper } from '~/components/AnonymousSignUpForm/AnonymousSignUpFormWrapper';
import { AnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/AnonymousSignUpModal';
import { useAnonymousSignUpModalLogic } from '~/components/PublicLinkAnonymousSignUpModal/hooks/useAnonymousSignUpModalLogic';
import { AnonymousSignUpModalHeader } from '~/components/PublicLinkAnonymousSignUpModal/shared/AnonymousSignUpModalHeader';

export interface PublicLinkAnonymousSignUpModalProps {
  onLoggedIn?: () => void;
}

export const PublicBoardAnonymousSignUpModal = memo(
  ({ onClose, onLoggedIn }: AirModalProps<PublicLinkAnonymousSignUpModalProps>) => {
    const { shortId, objectId } = useShortIdContext();
    const { asPath } = useRouter();

    const _onLoggedIn = useCallback(() => {
      onClose();
      onLoggedIn?.();
    }, [onClose, onLoggedIn]);

    const { onFullLoginClick, isOpen } = useAnonymousSignUpModalLogic({
      ssoCallbackData: { url: asPath },
      onLoginSuccess: _onLoggedIn,
    });

    return (
      <AnonymousSignUpModal isOpen={isOpen} data-testid="PUBLIC_BOARD_ANONYMOUS_SIGNUP_MODAL">
        <AnonymousSignUpModalHeader
          alt="Clouds with paper plane"
          imageSrc="/assets/illustrations/clouds-paper-plane.svg"
        />
        <AnonymousSignUpFormWrapper
          title="Files were shared with you!"
          description="Please enter your info to continue."
          onLoggedIn={_onLoggedIn}
          onFullLoginClick={onFullLoginClick}
          trackLocation="board-share-link"
          objectId={objectId}
          shortId={shortId}
          objectType="board"
        />
      </AnonymousSignUpModal>
    );
  },
);

PublicBoardAnonymousSignUpModal.displayName = 'PublicLinkAnonymousSignUpModal';
