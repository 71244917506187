import { memo, ReactNode } from 'react';

interface GalleryCardContentProps {
  children?: ReactNode;
}

export const GalleryCardContent = memo(({ children }: GalleryCardContentProps) => (
  <div className="pointer-events-none relative flex h-full w-full flex-col">{children}</div>
));

GalleryCardContent.displayName = 'GalleryCardContent'
