import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BoardThumbnail, { BoardThumbnailProps } from '~/components/BoardThumbnail/BoardThumbnail';
import { useThumbnailPreference } from '~/hooks/useThumbnailPreference';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { currentThumbnailPreferenceSelector } from '~/store/configViews/selectors';

export type BoardGalleryCardThumbnailProps = Pick<BoardThumbnailProps, 'board' | 'height' | 'sizes'>;

export const BoardGalleryCardThumbnail = ({ board, height, sizes }: BoardGalleryCardThumbnailProps) => {
  const { getPreference } = useThumbnailPreference();
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const configViewThumbnailPreference = useSelector(currentThumbnailPreferenceSelector);

  const thumbnailPreference = useMemo(() => {
    return configViewThumbnailPreference ?? getPreference(boardId);
  }, [boardId, getPreference, configViewThumbnailPreference]);

  return (
    <BoardThumbnail
      board={board}
      objectFit={thumbnailPreference === 'default' ? 'cover' : thumbnailPreference}
      imgixParams={{
        fit: thumbnailPreference === 'default' ? 'crop' : 'clip',
      }}
      height={height}
      sizes={sizes}
      width="100%"
    />
  );
};
