import { memo } from 'react';

import { TableCell } from '~/components/TableView/TableCell';

import { getTableColumnWidth } from '../types';
import { CustomFieldTableHeaderProps } from './types';

export const PublicCustomFieldTableHeader = memo(({ customFieldName }: CustomFieldTableHeaderProps) => {
  return (
    <TableCell width={getTableColumnWidth('customField')} data-testid={customFieldName}>
      {customFieldName}
    </TableCell>
  );
});
PublicCustomFieldTableHeader.displayName = 'PublicCustomFieldTableHeader';
