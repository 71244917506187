import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getConfigViewOptionsKey } from '~/constants/react-query-keys';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { filterOutIPTCFields } from '~/swr-hooks/gallery/viewFields/filterOutIPTCFields';
import { useCanSeePublicIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePublicIPTCFields';

export const useGetPublicConfigViewOptions = () => {
  const { shortId } = useShortIdContext();
  const viewType = useSelector(currentViewTypeNameSelector);

  const { canSeePublicIPTCFields } = useCanSeePublicIPTCFields();

  return useQuery({
    queryKey: getConfigViewOptionsKey({ viewType, canSeeIPTCFields: canSeePublicIPTCFields }),

    queryFn: async () => {
      const response = await ShortUrl.listViewFields(shortId, viewType!);
      return canSeePublicIPTCFields ? response : filterOutIPTCFields(response);
    },
    enabled: !!viewType,
  });
};
