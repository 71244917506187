import { useMemo } from 'react';

import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export const useGetPublicKanbanCustomField = () => {
  const { customFields } = usePublicCustomFieldsContext();
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const baseCustomField = useMemo(
    () => customFields?.find((cf) => cf.id === customFieldId),
    [customFieldId, customFields],
  );
  return baseCustomField;
};
