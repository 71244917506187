import { Board } from '@air/api/types';
import { memo, ReactNode, useCallback, useMemo } from 'react';

import { PublicAudioCodingFilterCard } from '~/components/Filters/AudioCodingFilter/PublicAudioCodingFilterCard';
import { PublicAudioSampleRateFilterCard } from '~/components/Filters/AudioSampleRateFilter/PublicAudioSampleRateFilterCard';
import { PublicCamerasFilterCard } from '~/components/Filters/CamerasFilter/PublicCamerasFilterCard';
import { PublicCityFilterCard } from '~/components/Filters/CityFilter/PublicCityFilterCard';
import { ColorFilterCard, ColorFilterCardProps } from '~/components/Filters/ColorFilter/ColorFilterCard';
import {
  PublicCopyrightFilterCard,
  PublicCopyrightFilterCardProps,
} from '~/components/Filters/CopyrightFilter/PublicCopyrightFilterCard';
import { PublicCountryFilterCard } from '~/components/Filters/CountryFilter/PublicCountryFilterCard';
import {
  PublicCreatorFilterCard,
  PublicCreatorFilterCardProps,
} from '~/components/Filters/CreatorFilter/PublicCreatorFilterCard';
import {
  PublicCustomFieldFilterCard,
  PublicCustomFieldFilterCardProps,
} from '~/components/Filters/CustomFieldFilter/PublicCustomFieldFilterCard';
import { PublicExtensionsFilterCard } from '~/components/Filters/ExtensionFilter/PublicExtensionsFilterCard';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { BoardFiltersButton } from '~/components/Filters/FiltersButton/BoardFiltersButton';
import { FiltersDropdownTrigger } from '~/components/Filters/FiltersButton/FiltersDropdownTrigger';
import { FiltersDropdown } from '~/components/Filters/FiltersDropdown/FiltersDropdown';
import { PublicSourceFilterCard } from '~/components/Filters/SourceFilter/PublicSourceFilterCard';
import { PublicStateFilterCard } from '~/components/Filters/StateFilter/PublicStateFilterCard';
import { getPublicOtherFilters } from '~/components/Filters/utils/getPublicOtherFilters';
import { PublicVideoAspectRatioFilterCard } from '~/components/Filters/VideoAspectRatioFilter/PublicVideoAspectRatioFilterCard';
import { PublicVideoFrameRateFilterCard } from '~/components/Filters/VideoFrameRateFilter/PublicVideoFrameRateFilterCard';
import { FilterType, sortFiltersByOrder } from '~/components/FiltersMenu/types';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useDidFilterChange } from '~/components/SavedFilter/shared/hooks/useDidFilterChange';
import { useAppliedCustomFieldsFilters } from '~/hooks/filters/useAppliedCustomFieldsFilters';
import { useAppliedTagsFilters } from '~/hooks/filters/useAppliedTagsFilters';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { isKanbanViewSelector } from '~/store/configViews/selectors';
import { getSelectCustomFields } from '~/swr-hooks/customFields/utils';
import { useGetPublicFilterOptions } from '~/swr-hooks/filters/useGetPublicFilterOptions';
import { useGetPublicFiltersEntities } from '~/swr-hooks/filters/useGetPublicFiltersEntities';
import { useCanSeePublicIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePublicIPTCFields';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface PublicBoardFiltersButtonProps {
  boardId: Board['id'];
}

export const PublicBoardFiltersButton = memo(({ boardId }: PublicBoardFiltersButtonProps) => {
  const { data: filterOptions } = useGetPublicFilterOptions({ boardId });
  const { permissions } = usePublicPermissions();
  const isKanbanView = useAirSelector(isKanbanViewSelector);
  const { customFields } = usePublicCustomFieldsContext();
  const { appliedTagsFilters } = useAppliedTagsFilters();
  const { appliedCustomFieldsFilters } = useAppliedCustomFieldsFilters();
  const { data: entities } = useGetPublicFiltersEntities({
    tagLabels: appliedTagsFilters,
    customFields: appliedCustomFieldsFilters,
  });
  const { isFilterChanged } = useDidFilterChange({ options: filterOptions, entities });

  const selectCustomFields = useMemo(() => (customFields ? getSelectCustomFields(customFields) : []), [customFields]);

  const { canSeePublicIPTCFields } = useCanSeePublicIPTCFields();

  const availableOtherFilters = useMemo(
    () =>
      getPublicOtherFilters({
        canViewAssetVersions: canSeeAssetVersions(permissions),
        canViewDiscussions: canViewAssetDiscussions(permissions),
      }),
    [permissions],
  );

  const renderColorFilterCard = useCallback(
    (props: Omit<ColorFilterCardProps, 'colors'>) => (
      <ColorFilterCard colors={filterOptions?.colors ?? []} {...props} />
    ),
    [filterOptions?.colors],
  );

  const renderSourceFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicSourceFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderExtensionFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicExtensionsFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderCustomFieldCard = useCallback(
    (props: Omit<PublicCustomFieldFilterCardProps, 'CustomFieldValuesFilterSelect'>) => (
      <PublicCustomFieldFilterCard {...props} />
    ),
    [],
  );

  const renderCopyrightFilterCard = useCallback(
    (props: Omit<PublicCopyrightFilterCardProps, 'boardId'>) => (
      <PublicCopyrightFilterCard boardId={boardId} {...props} />
    ),
    [boardId],
  );

  const renderCreatorFilterCard = useCallback(
    (props: Omit<PublicCreatorFilterCardProps, 'boardId'>) => <PublicCreatorFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const availableFilters = useMemo(() => {
    const availableFilters: FilterType[] = [
      'type',
      'color',
      'customField',
      'date',
      'extension',
      'source',
      'dateCreated',
      'dateModified',
      'dateUploaded',
    ];

    if (canSeePublicIPTCFields) {
      availableFilters.push(
        'dateTaken',
        'camera',
        'copyright',
        'creator',
        'audioVisual',
        'videoFrameRate',
        'videoAspectRatio',
        'audioCoding',
        'audioSampleRate',
        'location',
        'city',
        'state',
        'country',
      );
    }

    if (availableOtherFilters.length > 0) {
      availableFilters.push('other');
    }

    return availableFilters.sort(sortFiltersByOrder);
  }, [availableOtherFilters.length, canSeePublicIPTCFields]);

  const renderCamerasFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicCamerasFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderVideoAspectRatioFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicVideoAspectRatioFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderCountryFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicCountryFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderCityFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicCityFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderStateFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicStateFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderVideoFrameRateFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicVideoFrameRateFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderAudioCodingFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicAudioCodingFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderAudioSampleRateFilterCard = useCallback(
    (props: BaseFilterCardProps) => <PublicAudioSampleRateFilterCard boardId={boardId} {...props} />,
    [boardId],
  );

  const renderDropdown = useCallback<({ closePopover }: { closePopover: () => void }) => ReactNode>(
    ({ closePopover }) => (
      <FiltersDropdown
        availableFilters={availableFilters}
        otherFilters={availableOtherFilters}
        onApplyClick={closePopover}
        customFields={selectCustomFields}
        VideoFrameRateFilterCard={renderVideoFrameRateFilterCard}
        VideoAspectRatioFilterCard={renderVideoAspectRatioFilterCard}
        CreatorFilterCard={renderCreatorFilterCard}
        CountryFilterCard={renderCountryFilterCard}
        CityFilterCard={renderCityFilterCard}
        StateFilterCard={renderStateFilterCard}
        AudioCodingFilterCard={renderAudioCodingFilterCard}
        AudioSampleRateFilterCard={renderAudioSampleRateFilterCard}
        title="Filter board"
        CameraFilterCard={renderCamerasFilterCard}
        ColorFilterCard={renderColorFilterCard}
        CopyrightFilterCard={renderCopyrightFilterCard}
        SourceFilterCard={renderSourceFilterCard}
        CustomFieldFilterCard={renderCustomFieldCard}
        ExtensionFilterCard={renderExtensionFilterCard}
        trackLocation="board"
        isFilterChanged={isFilterChanged}
      />
    ),
    [
      availableFilters,
      availableOtherFilters,
      selectCustomFields,
      renderVideoFrameRateFilterCard,
      renderVideoAspectRatioFilterCard,
      renderCreatorFilterCard,
      renderCountryFilterCard,
      renderCityFilterCard,
      renderStateFilterCard,
      renderAudioCodingFilterCard,
      renderAudioSampleRateFilterCard,
      renderCamerasFilterCard,
      renderColorFilterCard,
      renderCopyrightFilterCard,
      renderSourceFilterCard,
      renderCustomFieldCard,
      renderExtensionFilterCard,
      isFilterChanged,
    ],
  );

  if (isKanbanView) return null;

  return <FiltersDropdownTrigger renderTrigger={BoardFiltersButton} renderFiltersDropdown={renderDropdown} />;
});

PublicBoardFiltersButton.displayName = 'PublicBoardFiltersButton';
