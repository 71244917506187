import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicVideoAspectRatiosList } from '~/swr-hooks/filters/usePublicVideoAspectRatiosList';

interface PublicVideoAspectRatiosSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicVideoAspectRatiosSelect = memo(({ boardId, ...props }: PublicVideoAspectRatiosSelectProps) => {
  const { shortId } = useShortIdContext();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicVideoAspectRatiosList({
    search: debouncedSearchValue,
    shortId,
    boardId,
  });

  return (
    <StringSelect
      placeholder="Search for signal aspect ratio i.e. 16:9"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PublicVideoAspectRatiosSelect.displayName = 'PublicVideoAspectRatiosSelect';
