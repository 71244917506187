import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { createBoardRoute } from '~/constants/routes';
import { LocationBoard } from '~/store/router/types';

export function useOpenBoardInNewTab() {
  const openBoardInNewTab = useCallback(({ board }: { board: LocationBoard | Board; trackLocation?: string }) => {
    const boardRoute = createBoardRoute(board.id, board.title);

    return window.open(boardRoute, '_blank', 'noopener,noreferrer');
  }, []);

  return { openBoardInNewTab };
}
