import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicCreatorsList } from '~/swr-hooks/filters/usePublicCreatorsList';

interface PublicCreatorSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicCreatorSelect = memo(({ boardId, ...props }: PublicCreatorSelectProps) => {
  const { shortId } = useShortIdContext();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicCreatorsList({
    search: debouncedSearchValue,
    shortId,
    boardId,
  });

  return (
    <StringSelect
      placeholder="Search for creator"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PublicCreatorSelect.displayName = 'PublicCreatorSelect';
