import { ComponentType, memo, ReactNode } from 'react';

import { AssetModalMobileTabNavigation } from './MobileTabNavigation/MobileTabNavigation';
import { Visualizer } from './Visualizer/Visualizer';

export type AssetModalMobileLayoutProps = {
  Banner?: ComponentType;
  PDFVisualizer: ComponentType;
  ImageVisualizer: ComponentType;
  PreviousAndNextArrows?: ComponentType;
  VideoVisualizer?: ComponentType;
  panel?: ReactNode;
  header: ReactNode;
  tabs?: ReactNode;
  navigation?: ReactNode;
};

export const AssetModalMobileLayout = memo(
  ({
    Banner,
    PDFVisualizer,
    PreviousAndNextArrows,
    VideoVisualizer,
    ImageVisualizer,
    header,
    panel,
    navigation,
    tabs,
  }: AssetModalMobileLayoutProps) => {
    return (
      <div
        data-testid="MOBILE_ASSET_MODAL"
        className="fixed inset-y-0 left-0 flex w-screen flex-col items-start justify-start overflow-auto bg-pigeon-850"
      >
        {!!Banner && <Banner />}
        {header}
        <div className="flex h-auto min-h-[200px] w-full shrink-0 bg-black">
          <Visualizer
            VideoVisualizer={VideoVisualizer}
            PDFVisualizer={PDFVisualizer}
            ImageVisualizer={ImageVisualizer}
          />
        </div>
        {!!PreviousAndNextArrows && <PreviousAndNextArrows />}
        {!!navigation ? navigation : <AssetModalMobileTabNavigation tabs={tabs} />}
        {panel}
      </div>
    );
  },
);

AssetModalMobileLayout.displayName = 'AssetModalMobileLayout';
