import { DoubleChevronLeft } from '@air/next-icons';
import { noop } from 'lodash';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { PUBLIC_WORKSPACE_NAVIGATION, PUBLIC_WORKSPACE_NAVIGATION_BUTTON } from '~/constants/testIDs';

export interface PublicBoardWorkspaceNavigationProps {
  onWorkspaceNavigate: () => void;
}

export const PublicWorkspaceNavigation = ({ onWorkspaceNavigate = noop }: PublicBoardWorkspaceNavigationProps) => {
  const { workspaceName } = usePublicWorkspace();

  return (
    <div className="flex items-center" data-testid={PUBLIC_WORKSPACE_NAVIGATION}>
      <button
        className="flex cursor-pointer items-center border-none bg-transparent p-0 text-16 font-medium text-grey-10 hover:underline"
        data-testid={PUBLIC_WORKSPACE_NAVIGATION_BUTTON}
        onClick={onWorkspaceNavigate}
      >
        <DoubleChevronLeft className="mr-2 inline-block h-4 w-4" />
        <div className="truncate text-16 text-inherit">View in&nbsp;{workspaceName}</div>
      </button>
    </div>
  );
};
