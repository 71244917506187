import { Filters } from '@air/next-icons';
import { Button, type ButtonProps } from '@air/primitive-button';
import classNames from 'classnames';
import { forwardRef, memo } from 'react';

import { NEW_FILTERS_BUTTON } from '~/constants/testIDs';

export interface FiltersButtonProps extends ButtonProps {
  isActive: boolean;
  filtersCount?: number;
}

const _FiltersButton = forwardRef<HTMLButtonElement, FiltersButtonProps>(
  ({ filtersCount, isActive, ...props }: FiltersButtonProps, ref) => {
    const hasFilters = !!filtersCount;
    return (
      <Button
        appearance="ghost"
        color={isActive || hasFilters ? 'blue' : 'grey'}
        className={classNames(
          'px-2.5',
          isActive || hasFilters ? 'bg-blue-3 hover:bg-blue-5 active:bg-blue-5' : undefined,
        )}
        data-testid={NEW_FILTERS_BUTTON}
        ref={ref}
        size="large"
        {...props}
      >
        <Filters className="h-5 w-5" />
        {!!filtersCount && (
          <div
            data-testid="NEW_FILTERS_BUTTON_COUNT"
            className="ml-2 mr-0.5 rounded-2xl bg-jay-300/15 px-1.5 text-14 font-semibold text-inherit"
          >
            {filtersCount}
          </div>
        )}
      </Button>
    );
  },
);

_FiltersButton.displayName = '_FiltersButton';

export const FiltersButton = memo(_FiltersButton);

FiltersButton.displayName = 'FiltersButton';
