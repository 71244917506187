import { useShortIdContext } from '@air/provider-short-id';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { GALLERY_ASSETS, GALLERY_BOARDS, GALLERY_FILES } from '~/constants/react-query-keys';
import { useRegisterClipEvents } from '~/hooks/useRegisterClipEvents';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  currentViewTypeNameSelector,
} from '~/store/configViews/selectors';
import { useGalleryViewItems } from '~/swr-hooks/gallery/galleryView/useGalleryViewItems';
import { usePublicBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePublicBoardsFetcher';
import {
  galleryViewDataToUpdateMediaPlayer,
  getAssetFromAssetsData,
  publicClipsFetcher,
  publicFilesFetcher,
} from '~/swr-hooks/gallery/galleryView/utils';
import { defaultAssetsData, defaultBoardsData } from '~/swr-hooks/gallery/types';
import { useCanShowAssets, useCanShowBoards, useCanShowFiles } from '~/swr-hooks/gallery/useCanShow';
import { usePublicMediaPlayerDataUpdate } from '~/swr-hooks/mediaPlayer/usePublicMediaPlayerData';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';

export function usePublicGalleryViewItems() {
  const { publicBoardsFetcher } = usePublicBoardsFetcher();
  const currentBoardId = useURLBoardIdSelector();
  const { shortId } = useShortIdContext();
  const { workspaceId } = usePublicWorkspace();
  const { updatePublicMediaPlayerData } = usePublicMediaPlayerDataUpdate(shortId);
  const sortFieldName = useSelector(currentSortFieldNameSelector);
  const sortDirection = useSelector(currentSortFieldDirectionSelector);
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);

  const clipsFetchParams = useClipSearchParams();
  const boardsFetchParams = useBoardSearchParams();

  const { canShowBoards } = useCanShowBoards();
  const { canShowFiles } = useCanShowFiles();
  const { canShowAssets } = useCanShowAssets();

  const dataCacheKey = {
    ...clipsFetchParams,
    ...boardsFetchParams,
    canShowFiles,
    canShowBoards,
    canShowAssets,
    sortFieldName,
    sortDirection,
    shortId,
    boardId: currentBoardId,
  };

  const {
    isLoading: areBoardsLoading,
    data: boardsData,
    fetchNextPage: loadNextBoardPage,
    isFetchingNextPage: areBoardsValidating,
    hasNextPage: hasMoreBoards,
  } = useInfiniteQuery({
    queryKey: [GALLERY_BOARDS, dataCacheKey],
    queryFn: canShowBoards
      ? ({ pageParam }) =>
          publicBoardsFetcher({
            shortId,
            parentId: currentBoardId!,
            params: boardsFetchParams,
            sortFieldName,
            sortDirection,
            cursor: pageParam || null,
          })
      : () => defaultBoardsData,
    enabled: !!currentBoardId && !!currentViewTypeName,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const assetParams = { shortId, params: clipsFetchParams, sortFieldName, sortDirection };

  const areAllBoardsLoaded = !!boardsData && !(canShowBoards && hasMoreBoards) && !!currentViewTypeName;

  const {
    data: clipsData,
    fetchNextPage: loadNextClipsPage,
    isFetchingNextPage: areClipsValidating,
    isLoading: areClipsLoading,
    hasNextPage: hasMoreClips,
  } = useInfiniteQuery({
    queryKey: [GALLERY_ASSETS, dataCacheKey],
    queryFn: canShowAssets
      ? ({ pageParam }) =>
          publicClipsFetcher({ ...assetParams, shortId, cursor: pageParam || null, parentId: currentBoardId! })
      : () => defaultAssetsData,
    enabled: areAllBoardsLoaded && !!currentBoardId,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  useRegisterClipEvents({
    clips: getAssetFromAssetsData(clipsData?.pages),
    workspaceId,
  });

  const areAllClipsLoaded = !!clipsData && !(canShowAssets && hasMoreClips);

  const {
    data: filesData,
    fetchNextPage: loadNextFilesPage,
    isFetchingNextPage: areFilesValidating,
    isLoading: areFilesLoading,
    hasNextPage: hasMoreFiles,
  } = useInfiniteQuery({
    queryKey: [GALLERY_FILES, dataCacheKey],
    queryFn: canShowFiles
      ? ({ pageParam }) =>
          publicFilesFetcher({ ...assetParams, shortId, parentId: currentBoardId!, cursor: pageParam || null })
      : () => defaultAssetsData,
    enabled: areAllBoardsLoaded && areAllClipsLoaded && !!currentBoardId,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const itemsData = useGalleryViewItems({
    boards: {
      isValidating: areBoardsLoading || areBoardsValidating,
      data: boardsData?.pages ?? [],
      loadNextPage: loadNextBoardPage,
      isVisible: canShowBoards,
      hasMore: !!hasMoreBoards,
    },
    clips: {
      isValidating: areClipsLoading || areClipsValidating,
      data: clipsData?.pages ?? [],
      loadNextPage: loadNextClipsPage,
      isVisible: canShowAssets,
      hasMore: !!hasMoreClips,
    },
    files: {
      isValidating: areFilesLoading || areFilesValidating,
      data: filesData?.pages ?? [],
      loadNextPage: loadNextFilesPage,
      isVisible: canShowFiles,
      hasMore: !!hasMoreFiles,
    },
  });

  useEffect(() => {
    updatePublicMediaPlayerData(galleryViewDataToUpdateMediaPlayer(itemsData));
  }, [itemsData, updatePublicMediaPlayerData]);

  return itemsData;
}
