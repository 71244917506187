import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { memo, useCallback, useState } from 'react';

import {
  CustomFieldSelect,
  CustomFieldSelectProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldSelect';
import { chipsToCustomField, chipToCustomField } from '~/components/CustomFields/CustomFieldInput/utils';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicAssetCustomFieldSelectProps
  extends Omit<CustomFieldSelectProps, 'onSelectionChange' | 'cfOptions'> {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PublicAssetCustomFieldSelect = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PublicAssetCustomFieldSelectProps) => {
    const [shouldLoadOptions, setShouldLoadOptions] = useState(false);

    const { data: cfOptions } = useGetPublicCustomFieldOptions({
      customFieldId: field.id,
      enabled: !shouldLoadOptions,
    });
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const onSelectionChange: CustomFieldSelectProps['onSelectionChange'] = useCallback(
      async ({ addedChips, removedChips, chips }) => {
        if (field.type.title === CustomFieldTypeName.singleSelect) {
          const value = chips.length && !!cfOptions ? chipToCustomField(chips[0], cfOptions.data) : null;
          await updatePublicItemsCustomFieldValue({
            assetIds: [assetId],
            baseCustomField: field,
            nextValue: value,
            trackingLocation,
          });
        } else {
          const selectedFields = chips.length && !!cfOptions ? chipsToCustomField(chips, cfOptions.data) : [];

          await updatePublicItemsCustomFieldValue({
            assetIds: [assetId],
            baseCustomField: field,
            nextValues: selectedFields,
            multiValuesToAdd: addedChips && cfOptions ? chipsToCustomField(addedChips, cfOptions.data) : undefined,
            multiValueIdsToRemove: removedChips?.map((chip) => chip.value),
            trackingLocation,
          });
        }
      },
      [assetId, cfOptions, field, trackingLocation, updatePublicItemsCustomFieldValue],
    );

    return (
      <CustomFieldSelect
        {...rest}
        field={field}
        onSelectionChange={onSelectionChange}
        cfOptions={cfOptions}
        onOptionsOpen={() => setShouldLoadOptions(true)}
        isLoading={shouldLoadOptions && !cfOptions}
        disabled={!canEditCustomFields}
      />
    );
  },
);

PublicAssetCustomFieldSelect.displayName = 'PublicAssetCustomFieldSelect';
