import { GallerySection } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { noop } from 'lodash';
import { CSSProperties, memo, useCallback, useMemo, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { AssetGalleryCardSize, useGalleryAssets } from '~/components/Gallery/useGalleryAssets';
import {
  BoardGalleryCardDesktopSize,
  BoardGalleryCardMobileSize,
  useGalleryBoards,
} from '~/components/Gallery/useGalleryBoards';
import { FileGalleryCardSize, useGalleryFiles } from '~/components/Gallery/useGalleryFiles';
import LoadMoreSpinner from '~/components/LoadMoreSpinner';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import { PublicBoardGalleryAsset } from '~/components/PublicBoard/PublicBoardGalleryAsset';
import { PublicBoardGalleryBoard } from '~/components/PublicBoard/PublicBoardGalleryBoard';
import { PublicBoardGalleryFile } from '~/components/PublicBoard/PublicBoardGalleryFile';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import {
  SelectableGalleryView,
  SelectableGalleryViewProps,
} from '~/components/SelectableGalleryView/SelectableGalleryView';
import { ASSET_CARD_ROW_MT } from '~/constants/assetCard';
import { WKSPC_CLIP_INFO_HEIGHT, WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT } from '~/constants/WorkspaceSpacing';
import { useCardSizePreference } from '~/hooks/useCardSizePreference';
import { useGroupedGallerySelectedItems } from '~/hooks/useGroupedGallerySelectedItems';
import { usePublicWorkspaceHorizontalPadding } from '~/hooks/usePublicWorkspaceHorizontalPadding';
import { isTitleAndMetadataVisibleSelector, visibleCustomFieldsLengthSelector } from '~/store/configViews/selectors';
import { usePublicGalleryViewItems } from '~/swr-hooks/gallery/galleryView/usePublicGalleryViewItems';
import { canChangeAssetCustomFields } from '~/utils/permissions/assetPermissions';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

import { PublicBoardNullState } from './PublicBoardNullState';

const style: CSSProperties = {
  paddingBottom: '16rem',
};

export type PublicBoardGalleryViewProps = Pick<SelectableGalleryViewProps, 'scrollElementRef'>;

export const PublicBoardGalleryView = memo(({ scrollElementRef }: PublicBoardGalleryViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { responsiveHorizontalPadding } = usePublicWorkspaceHorizontalPadding();
  const {
    data: { boards: boardsData, clips: clipsData, files: filesData },
    isLoading,
    loadNextPage,
    isEmpty,
  } = usePublicGalleryViewItems();

  const isTitleAndMetadataVisible = useAirSelector(isTitleAndMetadataVisibleSelector);
  const visibleCustomFieldsLength = useAirSelector(visibleCustomFieldsLengthSelector);

  const { isAboveSmallScreen } = useBreakpointsContext();
  const { cardSize } = useCardSizePreference();

  useGroupedGallerySelectedItems(boardsData?.items ?? [], clipsData?.items ?? [], filesData?.items ?? []);

  const { width = 0, ref: widthRef } = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 300,
  });

  const { permissions } = usePublicPermissions();

  const canDownload = canGenerateZip(permissions);

  const isClipSelectable = useCallback(
    () => canChangeAssetCustomFields(permissions) || canDownload,
    [canDownload, permissions],
  );

  const isBoardSelectable = useCallback(
    () => canChangeBoardCustomFields(permissions) || canDownload,
    [canDownload, permissions],
  );

  const boardsSection = useGalleryBoards({
    data: boardsData,
    containerWidth: width,
    renderBoard: ({ data, index, box }) => <PublicBoardGalleryBoard data={data} index={index} box={box} />,
    loadMore: loadNextPage,
    containerHorizontalPadding: responsiveHorizontalPadding,
    isSelectable: isBoardSelectable,
    itemHeight: isAboveSmallScreen ? BoardGalleryCardDesktopSize[cardSize] : BoardGalleryCardMobileSize[cardSize],
  });

  const fileAdditionalHeight = useMemo(() => {
    const additionalRows = Math.max(0, visibleCustomFieldsLength - 2);
    return additionalRows * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
  }, [visibleCustomFieldsLength]);

  const assetAdditionalHeight = useMemo(() => {
    let height = 0;
    if (isTitleAndMetadataVisible) height += WKSPC_CLIP_INFO_HEIGHT;
    if (visibleCustomFieldsLength) height += ASSET_CARD_ROW_MT;
    return height + visibleCustomFieldsLength * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
  }, [visibleCustomFieldsLength, isTitleAndMetadataVisible]);

  const assetsSection = useGalleryAssets({
    renderAsset: ({ data, index, box }) => <PublicBoardGalleryAsset data={data} index={index} box={box} />,
    isFirstSection: !boardsData?.items?.length,
    data: clipsData,
    containerWidth: width,
    loadMore: loadNextPage,
    containerHorizontalPadding: responsiveHorizontalPadding,
    itemAdditionalHeight: assetAdditionalHeight,
    isSelectable: isClipSelectable,
    itemHeight: AssetGalleryCardSize[cardSize],
  });

  const filesSection = useGalleryFiles({
    renderFile: ({ data, index, box }) => <PublicBoardGalleryFile data={data} index={index} box={box} />,
    isFirstSection: !boardsData?.items?.length && !clipsData?.items.length,
    data: filesData,
    containerWidth: width,
    loadMore: loadNextPage,
    containerHorizontalPadding: responsiveHorizontalPadding,
    isSelectable: isClipSelectable,
    itemHeight: FileGalleryCardSize[cardSize] + fileAdditionalHeight,
  });

  const sections = [boardsSection, assetsSection, filesSection].filter(
    (section): section is GallerySection<GalleryMetadata> => !!section,
  );

  return (
    <div className="mx-auto w-full">
      <div ref={widthRef} />
      {isLoading || typeof document === 'undefined' ? (
        <LoadMoreSpinner isVisible isLoading={isLoading} loadMore={noop} />
      ) : isEmpty ? (
        <PublicBoardNullState />
      ) : width ? (
        <div className="relative mt-6 min-h-full" ref={containerRef}>
          <SelectableGalleryView
            scrollElementRef={scrollElementRef}
            containerRef={containerRef}
            sections={sections}
            width={width}
            style={style}
          />
        </div>
      ) : null}
    </div>
  );
});

PublicBoardGalleryView.displayName = 'PublicBoardGalleryView';
