import { Air } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';
import { NEW_TAB } from '~/constants/urls';

export const AirLogoLink = memo(() => (
  <Link className="mr-1 flex items-center justify-center" href={Routes.root} target={NEW_TAB} rel="noopener noreferrer">
    <VisuallyHidden>Go to landing page</VisuallyHidden>
    <Air aria-hidden="true" className="h-11 text-blue-9" />
  </Link>
));

AirLogoLink.displayName = 'AirLogoLink';
