import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { TableViewProps } from '~/components/TableView';
import { PublicCustomFieldTableHeader } from '~/components/TableView/CustomFieldTableHeader/PublicCustomFieldTableHeader';
import { SelectableTableView, SelectableTableViewProps } from '~/components/TableView/SelectableTableView';
import { TableHeaders } from '~/components/TableView/TableHeaders';
import { TablePageContainer } from '~/components/TableView/TablePageContainer';
import { getMinTableWidth } from '~/components/TableView/types';
import { usePublicTableRows } from '~/components/TableView/usePublicTableRows';
import { useTableSelectableItems } from '~/components/TableView/useTableSelectableItems';
import { BOARD_PAGE_TABLE_VIEW } from '~/constants/testIDs';
import { usePublicWorkspaceHorizontalPadding } from '~/hooks/usePublicWorkspaceHorizontalPadding';
import { useTableHeaderPosition } from '~/hooks/useTableHeaderPosition';
import { activeTableColumnsSelector } from '~/store/configViews/selectors';
import { usePublicTableViewItems } from '~/swr-hooks/gallery/tableView/usePublicTableViewItems';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

import { PublicBoardNullState } from './PublicBoardNullState';

export type PublicBoardTableViewProps = Pick<SelectableTableViewProps, 'scrollElementRef'>;

export const PublicBoardTableView = memo(({ scrollElementRef }: PublicBoardTableViewProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { tableHeaderTopPos } = useTableHeaderPosition();
  const { responsiveHorizontalPadding } = usePublicWorkspaceHorizontalPadding();
  const { data, hasMore, isEmpty, isLoading, isLoadingMore, loadNextPage } = usePublicTableViewItems();

  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const canDownload = canGenerateZip(permissions);

  /**
   * If the parent shortUrl has the `canDownloadClips` or `canEditCustomFields` permission,
   * the user can select all items (assets or boards) in the table.
   */
  const canSelectItems = canDownload || canEditCustomFields;

  const rows = usePublicTableRows({
    data,
    hasMore,
    isLoading,
    isLoadingMore,
    loadMore: loadNextPage,
    isAssetSelectable: canSelectItems,
    isBoardSelectable: canSelectItems,
  });

  const selectableItems = useMemo(() => (canSelectItems ? data : []), [canSelectItems, data]);

  useTableSelectableItems(selectableItems);

  const activeTableColumns = useSelector(activeTableColumnsSelector);
  const minTableWidth = getMinTableWidth(activeTableColumns);

  const hasData = !!data?.length;

  const renderTableHeaders: Required<TableViewProps>['renderHeaders'] = useCallback(
    (ref) =>
      hasData &&
      isAboveMediumScreen && (
        <TableHeaders
          ref={ref}
          CustomFieldTableHeaderComponent={PublicCustomFieldTableHeader}
          horizontalPadding={responsiveHorizontalPadding}
          topPos={tableHeaderTopPos}
        />
      ),
    [hasData, isAboveMediumScreen, responsiveHorizontalPadding, tableHeaderTopPos],
  );

  return (
    <TablePageContainer data-testid={BOARD_PAGE_TABLE_VIEW}>
      {isEmpty && typeof document !== 'undefined' ? (
        <PublicBoardNullState />
      ) : (
        <SelectableTableView
          canScrollHorizontally={isAboveMediumScreen && hasData}
          minTableWidth={minTableWidth}
          horizontalPadding={responsiveHorizontalPadding}
          renderHeaders={renderTableHeaders}
          rows={rows}
          scrollElementRef={scrollElementRef}
        />
      )}
    </TablePageContainer>
  );
});

PublicBoardTableView.displayName = 'PublicBoardTableView';
