import { ShortUrl } from '@air/api';
import { Clip, ClipGetOptions } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

export const PUBLIC_BOARD_CLIP = 'PUBLIC_BOARD_CLIP';

export const getPublicBoardClipKey = (params: { clipId?: Clip['id']; shortId?: string }) => {
  return [PUBLIC_BOARD_CLIP, params];
};

const defaultClipOptions: Partial<Pick<ClipGetOptions, 'withOpenDiscussionStatus'>> = {
  withOpenDiscussionStatus: true,
};

export const useGetPublicBoardClip = ({
  clipId,
  shortId,
  options = defaultClipOptions,
}: {
  shortId?: string;
  clipId?: string;
  options?: Partial<Pick<ClipGetOptions, 'withOpenDiscussionStatus'>>;
}) => {
  return useQuery({
    queryKey: getPublicBoardClipKey({ clipId, shortId }),

    queryFn: async () => {
      if (!shortId || !clipId) return;
      const { clip } = await ShortUrl.getBoardClip(shortId, clipId, options);
      return clip;
    },
    enabled: !!clipId && !!shortId,
  });
};
