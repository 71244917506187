import { Comment } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { centralizedClipOpenDiscussionCountSelector } from '~/store/centralizedClip/selectors';

export const MobileCommentsTab = memo(() => {
  const openDiscussionCount = useSelector(centralizedClipOpenDiscussionCountSelector);
  const { showPanel } = useAssetModalPanelContext();

  return (
    <div className="relative">
      <IconButton
        data-testid="COMMENTS_PANEL"
        appearance="ghost"
        color="grey"
        icon={Comment}
        onClick={() => showPanel(AssetModalPanel.COMMENTS)}
        size="large"
        label="Comments panel"
      />
      {!!openDiscussionCount && openDiscussionCount > 0 && (
        <div className="absolute right-[7px] top-[7px] h-2.5 w-2.5 rounded-full bg-red-9" />
      )}
    </div>
  );
});

MobileCommentsTab.displayName = 'PrivateMobileCommentsTab';
