import { Button } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { memo } from 'react';

import { ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER } from '~/constants/testIDs';

import { usePublicDownloadOptions } from '../shared/hooks/usePublicDownloadOptions';

export const PublicDownloadButton = memo(() => {
  const options = usePublicDownloadOptions();

  return (
    <DropdownMenu
      trigger={
        <Button appearance="filled" color="blue" size="large" data-testid={ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER}>
          Download
        </Button>
      }
    >
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

PublicDownloadButton.displayName = 'PublicDownloadButton';
