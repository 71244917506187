import { memo } from 'react';
import { useSelector } from 'react-redux';

import { ViewCardSizePreference } from '~/components/ViewControls/ViewVisiblityToggleMenu/ViewCardSizePreference';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import {
  configViewVisiblityOptionsSelector,
  currentViewTypeNameSelector,
  customFieldVisibilityOptionsSelector,
} from '~/store/configViews/selectors';

import { ConfigViewVisbilityOptions } from './ConfigViewVisbilityOptions';
import { CustomFieldsVisbilityOptions } from './CustomFieldsVisbilityOptions';
import { ViewVisibilityToggleMenu } from './ViewVisibilityToggleMenu';

export const PublicViewVisibilityToggleMenu = memo(() => {
  const customFieldOptions = useSelector(customFieldVisibilityOptionsSelector);
  const configViewOptions = useSelector(configViewVisiblityOptionsSelector);
  const showCustomFieldsOptions = customFieldOptions.length > 0;
  const showConfigViewOptions = configViewOptions.length > 0;
  const { customFields } = usePublicCustomFieldsContext();
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);

  return (
    <ViewVisibilityToggleMenu>
      <>
        {currentViewTypeName === 'gallery' && (
          <>
            <ViewCardSizePreference />
            <div className="-mx-1.5 my-2 h-px shrink-0 bg-grey-4" />
          </>
        )}
        {showConfigViewOptions && <ConfigViewVisbilityOptions options={configViewOptions} />}
        {showConfigViewOptions && showCustomFieldsOptions && <div className="-mx-1.5 my-2 h-px bg-grey-5" />}
        {showCustomFieldsOptions && customFields && (
          <CustomFieldsVisbilityOptions customFields={customFields} options={customFieldOptions} />
        )}
      </>
    </ViewVisibilityToggleMenu>
  );
});

PublicViewVisibilityToggleMenu.displayName = 'PublicViewVisibilityToggleMenu';
