import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldDateInput,
  CustomFieldDateInputProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldDateInput';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicBoardCustomFieldDateInputProps extends Omit<CustomFieldDateInputProps, 'onChange'> {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  boardId: string;
}

export const PublicBoardCustomFieldDateInput = memo(
  ({ field, trackingLocation, canEditCustomFields, boardId, ...rest }: PublicBoardCustomFieldDateInputProps) => {
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const updateCustomFieldDate: CustomFieldDateInputProps['onChange'] = useCallback(
      async (date) => {
        await updatePublicItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextDateValue: date?.toISOString(),
          trackingLocation,
        });
      },
      [boardId, updatePublicItemsCustomFieldValue, field, trackingLocation],
    );

    return (
      <CustomFieldDateInput
        {...rest}
        field={field}
        onChange={updateCustomFieldDate}
        canEditCustomFields={canEditCustomFields}
      />
    );
  },
);

PublicBoardCustomFieldDateInput.displayName = 'PublicBoardCustomFieldDateInput';
