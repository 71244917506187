import { useTrackViewedAsset } from '@air/analytics';
import { PublicClip } from '@air/api';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePublicPageViewNotification } from '~/components/AirNotifications/hooks/usePublicPageViewNotification';
import { AssetModal } from '~/components/AssetModal/AssetModal';
import { usePublicAssetClip } from '~/components/PublicAssetModal/hooks/usePublicAssetClip';
import { PublicAssetAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicAssetAnonymousSignUpModal';
import { usePublicSSOAssetCallback } from '~/components/PublicSSOCallback/usePublicSSOAssetCallback';
import { useOnMissingUserSession } from '~/hooks/useOnMissingUserSession';
import { useAccountContext } from '~/providers/AccountProvider';
import {
  centralizedClipAssetIdSelector,
  centralizedClipIdSelector,
  centralizedClipIsDemoSelector,
} from '~/store/centralizedClip/selectors';

import { PublicAssetModalDesktopLayout } from './components/PublicAssetModalDesktopLayout';
import { PublicAssetModalMobileLayout } from './components/PublicAssetModalMobileLayout';

interface PublicAssetProps {
  initialClip: PublicClip;
  requireAccount: boolean;
}

export const PublicAsset = memo(({ initialClip, requireAccount }: PublicAssetProps) => {
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const urlClipId = useSelector(centralizedClipIdSelector);
  const { shortId } = useShortIdContext();
  const isDemo = useSelector(centralizedClipIsDemoSelector);
  const { trackViewedAsset } = useTrackViewedAsset();
  const clipId = urlClipId || initialClip.id;

  const { data: account } = useAccountContext();

  usePublicAssetClip({ clipId, initialData: initialClip });

  usePublicSSOAssetCallback();

  const [showAnonymousSignupModal] = useAirModal(PublicAssetAnonymousSignUpModal);

  const { sendPublicPageViewEvent } = usePublicPageViewNotification();

  useOnMissingUserSession({
    onMissingSession: () => showAnonymousSignupModal({}),
    enabled: requireAccount,
  });

  useEffect(() => {
    if (!requireAccount || (requireAccount && !!account)) {
      sendPublicPageViewEvent(shortId);
    }
  }, [account, requireAccount, sendPublicPageViewEvent, shortId]);

  useEffect(() => {
    if (assetId) {
      trackViewedAsset({ assetId, isDemo, clip_id: clipId });
    }
  }, [assetId, clipId, isDemo, trackViewedAsset]);

  const desktop = useMemo(() => <PublicAssetModalDesktopLayout />, []);
  const mobile = useMemo(() => <PublicAssetModalMobileLayout />, []);

  return <AssetModal desktop={desktop} mobile={mobile} />;
});

PublicAsset.displayName = 'PublicAsset';
