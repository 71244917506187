import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import { isEqual } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BOARD_CRUMBS } from '~/constants/testIDs';
import {
  centralizedBoardAncestorsSelector,
  CentralizedBoardSelectors,
  centralizedBoardTitleSelector,
} from '~/store/centralizedBoard/selectors';
import { LocationBoard } from '~/store/router/types';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';

export const PublicBoardHeaderAncestors = memo(() => {
  const ancestors = useSelector(centralizedBoardAncestorsSelector, isEqual);
  const id = useSelector(CentralizedBoardSelectors.boardId);
  const title = useSelector(centralizedBoardTitleSelector);
  const { goToPublicBoard } = useGoToPublicBoard();

  const onBoardClick = useCallback(
    (locationBoard: LocationBoard) => {
      goToPublicBoard(locationBoard, 'breadcrumb');
    },
    [goToPublicBoard],
  );

  const breadcrumbItems = useMemo(() => {
    const items: BreadcrumbsItem[] = [];

    ancestors?.map((ancestor) =>
      items.push({
        id: ancestor.id,
        label: ancestor.title,
        onClick: () => onBoardClick({ id: ancestor.id, title: ancestor.title }),
        type: 'button',
      }),
    );

    if (id && title) {
      items.push({
        id,
        label: title,
        type: 'item',
      });
    }

    return items;
  }, [ancestors, id, onBoardClick, title]);

  if (breadcrumbItems.length < 2) return null;

  return (
    <Breadcrumbs
      className="-ml-1"
      data-testid={BOARD_CRUMBS}
      isCompact={breadcrumbItems.length > 3}
      items={breadcrumbItems}
    />
  );
});

PublicBoardHeaderAncestors.displayName = 'PublicBoardHeaderAncestors';
