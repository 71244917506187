import { useBreakpointsContext } from '@air/provider-media-query';

export const PasswordPageBackground = () => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  return (
    <svg
      fill="none"
      viewBox="0 0 8381 3136"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        zIndex: -1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: !isAboveMediumScreen ? 6948 : 8381,
        height: !isAboveMediumScreen ? 2600 : 3136,
        transform: 'translate(-50%, -50%)',
      }}
    >
      <path
        d="M5456.77 1128.39c-31.32 36.53-33.92 87.64-10.11 129.2 35.75 62.45 168.71 323.98-34.45 592.72l-.24.1c-176.99 206.21-531.8 348.18-948.88 380.13-115.35 8.96-228.57 8.73-336.14.84-.93-.16-1.78.48-1.94 1.38-1.75 13.18-16.36 108.36-101.89 221.54-.88 1.2-.25 2.59 1.26 2.78 144.56 16.2 299.38 19.2 457.58 6.94 487.7-37.36 894.08-206.23 1114.83-463.65l.24-.1c255.93-342.28 163.9-694.17 55.62-866.71-41.74-66.27-138.19-72.8-189.27-13.23l-6.61 8.06zm-2371.7 1677.67c-25.31-59.43 3.86-127.93 65.41-151.6 192.93-73.44 415.68-205.71 527.79-336.26 171.16-199.58 124.31-391.61 8.47-485.51-141.09-114.66-239.24-73.78-271.55-36.28-82.54 96.43 18.91 195.62 51.95 223.58 24.77 20.97 53.23 40.65 84.8 59.01 59.62 34.62 77.36 111.35 34.88 164.78-2 2.51-4 5.02-6 7.52-37 44.75-102.63 57.45-153.32 28.15-43.79-25.32-83.74-52.83-118.87-82.94-187.36-159.38-219.69-385.49-78.26-550.06 122.93-143.16 372.36-187.01 610.85 6.45 129.26 104.77 196.56 251.91 189.58 414.6-5.9 139.84-65.46 282.32-167.5 401.13-187.44 218.39-471.47 345.2-625.96 401.75-60.15 21.7-127.46-6.62-152.27-64.32z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8094.52 617.182c-68.18 22.408-148 66.734-218.84 133.529v.003c-154.57 145.738-299.75 297.016-439.43 442.576-6.42 6.67-12.81 13.34-19.2 20-144.57 150.61-284.37 295.52-419.15 415.93l-.02.02c-216.18 193.04-480.66 266.31-678.05 199.41-102.35-34.7-185.15-108.17-218.55-216.58-32.4-105.17-13.33-226.62 48.73-353.24v-.01c16.97-34.6 34.69-69.38 51.9-103.16l2.77-5.44c18.23-35.78 35.84-70.41 52.25-104.22 33.25-68.481 59.08-128.53 73.25-179.713 14.68-53.023 12.61-81.958 7.53-95.891-2.22-6.084-7.56-19.215-43.21-31.337-1.79.214-13.05.093-38.69 5.135-33.4 6.568-78.06 19.074-133.16 37.59-109.77 36.885-250.41 93.99-405.62 162.443-309.86 136.653-669.07 314.623-937.83 455.103-134.71 70.41-271.22 93.85-389.78 65.68-121.4-28.84-215.2-110.6-253.18-227.72-73.65-227.14 71.66-525.997 436.33-798.331 48.29-36.058 117.49-27.234 154.58 19.707 37.09 46.941 28.01 114.225-20.27 150.282-344.53 257.289-390.29 471.612-360.37 563.892 13.81 42.59 45.46 72.12 95.25 83.95 52.62 12.5 133.03 6.02 233.03-46.25 271.53-141.93 635.39-322.264 951.16-461.519 157.59-69.499 305.4-129.747 424.68-169.828 59.42-19.968 114.44-35.848 161.48-45.097 40.8-8.021 96.76-15.818 145.33-.586l.03.007c90.37 28.359 158 82.891 188.04 165.253 27.26 74.774 16.54 154.709-2.43 223.237-19.49 70.367-52.32 144.543-86.77 215.493-17.43 35.9-35.93 72.27-54.07 107.87l-2.55 5.02c-17.4 34.15-34.43 67.57-50.65 100.65-47.39 96.7-48.22 161.68-36.56 199.53 10.66 34.61 35.88 60.74 79.94 75.67 95.43 32.35 277.29 5 456.32-154.87 127.46-113.87 261.45-252.57 407.12-404.33 6.72-7 13.45-14.02 20.22-21.06 138.88-144.732 287.47-299.561 446.1-449.137 94.03-88.665 202.07-149.965 301.73-182.722 49.82-16.376 100.03-26.428 147.23-27.994 45.73-1.518 97.23 4.69 142.36 29.371 53.06 29.021 71.88 94.368 42.03 145.957-29.75 51.413-96.61 69.759-149.59 41.158.02.013.03.021.03.025-.03.086-7.71-2.936-27.31-2.286-21.34.708-50.05 5.624-84.14 16.83zM6289.76 737.511l.06.017-.06-.017z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
