import { config, ShortUrl } from '@air/api';
import { matchesAirror, MISSING_OR_INVALID_SHORT_URL_PASSCODE } from '@air/errors';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { Form, Formik, FormikConfig } from 'formik';
import * as Yup from 'yup';

import PasswordInput from '~/components/Form/PasswordInput';
import { PASSCODE_PROTECTED_SHARE_PAGE_INPUT, SEND_PASSCODE_BUTTON } from '~/constants/testIDs';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

const PasscodeFormSchema = Yup.object()
  .shape({
    password: Yup.string().default('').required('Passcode is required.'),
  })
  .required();

const initialValues = PasscodeFormSchema.cast({});

interface PasswordPageInputProps {
  shortId: string;
  onVerified: () => Promise<void>;
}

export const PasswordPageInput = ({ shortId, onVerified }: PasswordPageInputProps) => {
  const { showToast } = useToasts();
  const submitPasscode: FormikConfig<typeof initialValues>['onSubmit'] = async (
    { password },
    { resetForm, setSubmitting },
  ) => {
    try {
      const token = await ShortUrl.verifyShortUrl(shortId, password);
      config.setShortUrlToken(shortId, token.id);

      await onVerified();
    } catch (error) {
      if (matchesAirror(error, MISSING_OR_INVALID_SHORT_URL_PASSCODE)) {
        showToast('Incorrect passcode, please try again.');
      } else {
        showToast('Something went wrong, please try again.');

        reportErrorToBugsnag({ error, context: 'Failed to submit passcode' });
      }

      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <div className="relative">
      <Formik
        onSubmit={submitPasscode}
        initialValues={initialValues}
        validationSchema={PasscodeFormSchema}
        render={({ values, isSubmitting }) => (
          <Form className="flex flex-col gap-6">
            <PasswordInput size="large" testId={PASSCODE_PROTECTED_SHARE_PAGE_INPUT} />
            <Button
              className="w-full"
              data-testid={SEND_PASSCODE_BUTTON}
              disabled={!values.password}
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      />
    </div>
  );
};

PasswordPageInput.displayName = 'PasswordPageInput';
