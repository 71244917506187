import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldDateInput,
  CustomFieldDateInputProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldDateInput';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicAssetCustomFieldDateInputProps extends Omit<CustomFieldDateInputProps, 'onChange'> {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PublicAssetCustomFieldDateInput = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PublicAssetCustomFieldDateInputProps) => {
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const onChange: CustomFieldDateInputProps['onChange'] = useCallback(
      async (date) => {
        await updatePublicItemsCustomFieldValue({
          assetIds: [assetId],
          baseCustomField: field,
          nextDateValue: date?.toISOString(),
          trackingLocation,
        });
      },
      [assetId, field, trackingLocation, updatePublicItemsCustomFieldValue],
    );

    return (
      <CustomFieldDateInput {...rest} field={field} onChange={onChange} canEditCustomFields={canEditCustomFields} />
    );
  },
);

PublicAssetCustomFieldDateInput.displayName = 'PublicAssetCustomFieldDateInput';
