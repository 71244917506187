import { memo } from 'react';

import { AssetModalDesktopLayout } from '~/components/AssetModal/AssetModalDesktopLayout';
import { useFullscreenContext } from '~/components/AssetModal/shared/context/FullscreenContext';
import { useAssetModalEscKeyHandler } from '~/components/AssetModal/shared/hooks/useAssetModalEscKeyHandler';
import { PublicImageVisualizer } from '~/components/AssetModal/Visualizer/ImageVisualizer/PublicImageVisualizer';
import { PublicPDFVisualizer } from '~/components/AssetModal/Visualizer/PDFVisualizer/PublicPDFVisualizer';
import { Visualizer } from '~/components/AssetModal/Visualizer/Visualizer';
import { PublicAssetModalFooter } from '~/components/PublicAssetModal/components/PublicAssetModalFooter';
import { PublicPreviousAndNextArrows } from '~/components/PublicAssetModal/components/PublicPreviousAndNextArrows';
import { PublicVideoVisualizer } from '~/components/PublicAssetModal/components/PublicVideoVisualizer';

import { PublicBoardAssetModalHeader } from './PublicBoardAssetModalHeader';
import { PublicBoardAssetModalPanel } from './PublicBoardAssetModalPanel';

export const PublicBoardAssetModalDesktopLayout = memo(() => {
  useAssetModalEscKeyHandler();
  const { isFullscreen } = useFullscreenContext();
  const showArrows = !isFullscreen;

  return (
    <AssetModalDesktopLayout
      arrows={showArrows ? <PublicPreviousAndNextArrows /> : undefined}
      header={<PublicBoardAssetModalHeader />}
      panel={<PublicBoardAssetModalPanel showArrows={showArrows} />}
      content={
        <>
          <Visualizer
            PDFVisualizer={PublicPDFVisualizer}
            VideoVisualizer={PublicVideoVisualizer}
            ImageVisualizer={PublicImageVisualizer}
          />
          <PublicAssetModalFooter />
        </>
      }
    />
  );
});

PublicBoardAssetModalDesktopLayout.displayName = 'PublicBoardAssetModalDesktopLayout';
