import { useShortIdContext } from '@air/provider-short-id';
import { useRouter } from 'next/router';
import { memo, ReactNode, useCallback } from 'react';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { SocketContextProvider } from '~/providers/SocketContext/SocketContext';

export interface PublicBoardSocketContextProviderProps {
  children: ReactNode;
}

export const PublicBoardSocketContextProvider = memo(({ children }: PublicBoardSocketContextProviderProps) => {
  const { shortId } = useShortIdContext();
  const { isReady } = useRouter();
  const { workspaceId } = usePublicWorkspace();
  const getAuthToken = useCallback(() => Promise.resolve(shortId), [shortId]);

  return (
    <SocketContextProvider connect={isReady} getAuthToken={getAuthToken} currentWorkspaceId={workspaceId}>
      {children}
    </SocketContextProvider>
  );
});

PublicBoardSocketContextProvider.displayName = '_PublicBoardSocketContextProvider';
