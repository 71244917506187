import { Board } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { NextSeo } from 'next-seo';
import { memo } from 'react';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { createShortUrlBoardRoute } from '~/constants/routes';
import { getSeoTitle } from '~/swr-hooks/shortUrl/utils';

interface PublicBoardSEOProps {
  board: Required<Pick<Board, 'id' | 'title' | 'description' | 'thumbnails'>>;
}

export const PublicBoardSEO = memo(({ board }: PublicBoardSEOProps) => {
  const { workspaceName } = usePublicWorkspace();
  const { shortId } = useShortIdContext();
  const url = `https://app.air.inc${createShortUrlBoardRoute(shortId, board.id)}`;
  const thumbnail = board.thumbnails[0];

  return (
    <NextSeo
      nofollow
      noindex
      title={getSeoTitle('Board', board.title, workspaceName)}
      description={board.description ?? undefined}
      canonical={url}
      openGraph={{ url, images: !!thumbnail ? [{ url: thumbnail, alt: '' }] : undefined }}
    />
  );
});

PublicBoardSEO.displayName = 'PublicBoardSEO';
