import { useShortIdContext } from '@air/provider-short-id';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';

import { AnonymousSignUpFormWrapper } from '~/components/AnonymousSignUpForm/AnonymousSignUpFormWrapper';
import { AnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/AnonymousSignUpModal';
import { useAnonymousSignUpModalLogic } from '~/components/PublicLinkAnonymousSignUpModal/hooks/useAnonymousSignUpModalLogic';
import { AnonymousSignUpModalHeader } from '~/components/PublicLinkAnonymousSignUpModal/shared/AnonymousSignUpModalHeader';

export interface PublicLinkAnonymousSignUpModalProps {
  onLoggedIn?: () => void;
}

export const PublicAssetAnonymousSignUpModal = memo(
  ({ onClose, onLoggedIn }: AirModalProps<PublicLinkAnonymousSignUpModalProps>) => {
    const { asPath } = useRouter();
    const { shortId, objectId } = useShortIdContext();

    const _onLoggedIn = useCallback(() => {
      onClose();
      onLoggedIn?.();
    }, [onClose, onLoggedIn]);

    const { onFullLoginClick, isOpen } = useAnonymousSignUpModalLogic({
      ssoCallbackData: { url: asPath },
      onLoginSuccess: _onLoggedIn,
    });

    return (
      <AnonymousSignUpModal isOpen={isOpen}>
        <AnonymousSignUpModalHeader
          alt="Clouds with paper plane"
          imageSrc="/assets/illustrations/clouds-paper-plane.svg"
        />
        <AnonymousSignUpFormWrapper
          title="A file was shared with you!"
          description="Please enter your info to continue."
          onLoggedIn={_onLoggedIn}
          onFullLoginClick={onFullLoginClick}
          objectType="clip"
          trackLocation="clip-share-link"
          objectId={objectId}
          shortId={shortId}
        />
      </AnonymousSignUpModal>
    );
  },
);

PublicAssetAnonymousSignUpModal.displayName = 'PublicLinkAnonymousSignUpModal';
