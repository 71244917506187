import { ShortUrl } from '@air/api';
import { Board, ShortUrl as ShortUrlType } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

const PUBLIC_FILTER_OPTIONS = 'PUBLIC_FILTER_OPTIONS';

export const getPublicFiltersOptionsKey = (
  shortId: ShortUrlType['id'] | undefined,
  boardId: Board['id'] | undefined,
) => [PUBLIC_FILTER_OPTIONS, { shortId, boardId }];

export const useGetPublicFilterOptions = ({ boardId }: { boardId: Board['id'] | undefined }) => {
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getPublicFiltersOptionsKey(shortId, boardId),
    queryFn: () => ShortUrl.getFilterLookupsForShortId(shortId, boardId),
    enabled: !!boardId,
  });
};
