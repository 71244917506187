import { Search as SearchIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, Ref } from 'react';
import { useSelector } from 'react-redux';

import { TopBar } from '~/_components/TopBar';
import { PublicBoardFiltersButton } from '~/components/Filters/FiltersButton/PublicBoardFiltersButton';
import { PublicSearch } from '~/components/Search/PublicSearch';
import { SearchRow } from '~/components/Shared/HeaderBar/SearchRow';
import { useMobileSearchBar } from '~/hooks/search/useMobileSearchBar';
import { usePublicHeaderHorizontalPadding } from '~/hooks/usePublicHeaderHorizontalPadding';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';

import { PublicBoardHeaderActions } from './PublicBoardHeaderActions';

export type HeaderBarProps = {
  headerRef?: Ref<HTMLDivElement>;
};

export const PublicHeaderBar = memo(({ headerRef }: HeaderBarProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { openMobileSearch, isMobileSearchOpen, closeMobileSearch } = useMobileSearchBar();
  const { responsiveHorizontalPadding } = usePublicHeaderHorizontalPadding();

  const board = useSelector(centralizedBoardSelector);

  return (
    <TopBar
      className="bg-grey-1 pb-0 md:pb-0"
      ref={headerRef}
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        zIndex: isMobileSearchOpen ? 6 : 5,
      }} // increase zIndex to show it above workspace
    >
      <div className="flex w-full justify-between pb-2 md:pb-3">
        <SearchRow>
          <div className="flex grow justify-end md:justify-start">
            {isAboveMediumScreen || isMobileSearchOpen ? (
              <PublicSearch onCloseClick={closeMobileSearch} />
            ) : (
              <IconButton
                appearance="ghost"
                color="grey"
                size="large"
                icon={SearchIcon}
                onClick={openMobileSearch}
                label="Search"
              />
            )}
            <div className="ml-2">
              <PublicBoardFiltersButton boardId={board?.id ?? ''} />
            </div>
          </div>
        </SearchRow>
        {!!board && <PublicBoardHeaderActions board={board} />}
      </div>
    </TopBar>
  );
});

PublicHeaderBar.displayName = 'PublicHeaderBar';
