import { useTrackChangedViewType } from '@air/analytics';
import { ViewTypeName } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PublicKanbanNullState } from '~/components/PublicBoard/components/PublicKanbanView/components/PublicKanbanNullState';
import { setCurrentViewTypeAction } from '~/store/configViews/actions';

export const KanbanNoCustomFieldPermissions = memo(() => {
  const dispatch = useDispatch();

  const { trackChangedViewType } = useTrackChangedViewType();

  const onSwitchToGalleryView = useCallback(() => {
    dispatch(setCurrentViewTypeAction({ viewTypeName: ViewTypeName.gallery }));
    trackChangedViewType({ viewType: ViewTypeName.gallery });
  }, [dispatch, trackChangedViewType]);

  return (
    <PublicKanbanNullState
      action={
        <Button appearance="outline" color="blue" size="large" onClick={onSwitchToGalleryView}>
          Switch to Gallery view
        </Button>
      }
    ></PublicKanbanNullState>
  );
});

KanbanNoCustomFieldPermissions.displayName = 'KanbanNoCustomFieldPermissions';
