import { memo } from 'react';

import { AssetModalDesktopLayout } from '~/components/AssetModal/AssetModalDesktopLayout';
import { PublicImageVisualizer } from '~/components/AssetModal/Visualizer/ImageVisualizer/PublicImageVisualizer';
import { PublicPDFVisualizer } from '~/components/AssetModal/Visualizer/PDFVisualizer/PublicPDFVisualizer';
import { Visualizer } from '~/components/AssetModal/Visualizer/Visualizer';
import { PublicAssetModalFooter } from '~/components/PublicAssetModal/components/PublicAssetModalFooter';
import { PublicVideoVisualizer } from '~/components/PublicAssetModal/components/PublicVideoVisualizer';

import { PublicAssetModalHeader } from './PublicAssetModalHeader';
import { PublicAssetModalPanel } from './PublicAssetModalPanel';

export const PublicAssetModalDesktopLayout = memo(() => {
  return (
    <AssetModalDesktopLayout
      header={<PublicAssetModalHeader />}
      panel={<PublicAssetModalPanel />}
      content={
        <>
          <Visualizer
            PDFVisualizer={PublicPDFVisualizer}
            VideoVisualizer={PublicVideoVisualizer}
            ImageVisualizer={PublicImageVisualizer}
          />
          <PublicAssetModalFooter />
        </>
      }
    />
  );
});

PublicAssetModalDesktopLayout.displayName = 'AssetModalContent';
