import { PublicClip } from '@air/api';
import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { itemIsHighlightedOnShiftSelectSelector } from '@air/redux-selected-items';
import { memo, useMemo, useState } from 'react';

import { AssetItemProps } from '~/components/Gallery/types';
import { AssetCellThumbnail } from '~/components/TableView/NameCell/AssetCellThumbnail';
import { NameCellContent } from '~/components/TableView/NameCell/NameCellContent';
import { PublicAssetNameCellTitle } from '~/components/TableView/PrivateTableView/PublicAssetNameCellTitle';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface PublicAssetNameCellProps extends AssetItemProps {
  isHovering: boolean;
  isSelected: boolean;
  clip: PublicClip;
}

export const PublicAssetNameCell = memo(
  ({
    isHovering,
    isSelected,
    clip,
    getItemMenuOptions,
    getSelectionMenuOptions,
    onItemClick,
    onItemCmdClick,
    onCommentsClick,
    onVersionsClick,
  }: PublicAssetNameCellProps) => {
    const itemTitle = clip.title || clip.importedName || 'Untitled';

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const isHighlightedFromShiftSelect = useAirSelector((st) => itemIsHighlightedOnShiftSelectSelector(st, clip.id));

    const shouldShowMenu = !isHighlightedFromShiftSelect && (isHovering || menuIsOpen);

    const _title = useMemo(() => {
      return (
        <PublicAssetNameCellTitle
          id={clip.id}
          title={itemTitle}
          onClick={onItemClick}
          onCmdClick={onItemCmdClick}
          onCommentsClick={onCommentsClick}
          onVersionsClick={onVersionsClick}
          openCommentCount={clip.openCommentCount || 0}
          assetVersionCount={clip.assetVersionCount || 0}
        />
      );
    }, [
      clip.assetVersionCount,
      clip.id,
      clip.openCommentCount,
      itemTitle,
      onCommentsClick,
      onItemClick,
      onItemCmdClick,
      onVersionsClick,
    ]);

    const _image = useMemo(() => {
      return <AssetCellThumbnail {...clip} description={itemTitle} />;
    }, [clip, itemTitle]);

    const dataTestId = `ASSET_NAME_CELL`;

    const ellipsisMenuOptions = useMemo(
      () => getItemMenuOptions({ trackLocation: 'table-item-ellipsis-menu' }),
      [getItemMenuOptions],
    );

    const selectionMenuOptions = getSelectionMenuOptions('table-multi-select-ellipsis-menu');
    const menuOptions = isSelected && selectionMenuOptions.length ? selectionMenuOptions : ellipsisMenuOptions;

    return (
      <NameCellContent data-testid={dataTestId} title={_title} image={_image}>
        {shouldShowMenu && !!menuOptions.length && (
          <DropdownMenu
            onOpenChange={setMenuIsOpen}
            trigger={
              <IconButton
                appearance="ghost"
                color="grey"
                data-testid={`${dataTestId}-menu`}
                icon={EllipsisVertical}
                label="More options"
                onClick={(event) => event.stopPropagation()}
                onDoubleClick={(event) => event.stopPropagation()}
                size="medium"
              />
            }
          >
            {renderDropdownItems({ options: menuOptions })}
          </DropdownMenu>
        )}
      </NameCellContent>
    );
  },
);

PublicAssetNameCell.displayName = 'PublicAssetNameCell';
