import { Lock } from '@air/next-icons';
import { memo } from 'react';

import { getKeyForShortIDVerificationStatus } from '~/utils/SessionHelpers';

import { PasswordPageBackground } from './PasswordPageBackground';
import { PasswordPageHeader } from './PasswordPageHeader/PasswordPageHeader';
import { PasswordPageInput } from './PasswordPageInput/PasswordPageInput';

export interface PasswordPageProps {
  shortId: string;
  onVerified: () => Promise<void>;
}

export const PasswordPage = memo(({ shortId, onVerified }: PasswordPageProps) => {
  const onVerifiedHandler = async () => {
    window.sessionStorage.setItem(getKeyForShortIDVerificationStatus(shortId), 'verified');
    await onVerified();
  };

  return (
    <div className="relative flex h-screen items-center justify-center overflow-hidden">
      <PasswordPageBackground />
      <PasswordPageHeader />
      <div className="m-auto w-full max-w-[350px] p-6">
        <Lock className="ml-[-5px] w-8 text-grey-10" />
        <div className="mt-6 text-24 font-semibold text-grey-12">This link is private</div>
        <div className="my-4 text-16 text-grey-11">Please enter the passcode to view</div>
        <PasswordPageInput shortId={shortId} onVerified={onVerifiedHandler} />
      </div>
    </div>
  );
});

PasswordPage.displayName = 'PasswordPage';
