import { PublicBoard } from '@air/api/types';
import { isEqual } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { BoardGalleryCard } from '~/components/Gallery/BoardGalleryCard/BoardGalleryCard';
import { GalleryViewRender } from '~/components/Gallery/types';
import { PublicGalleryBoardAncestors } from '~/components/PublicGallery/PublicGalleryBoardAncestors';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useOpenPublicBoardInNewTab } from '~/hooks/useOpenPublicBoardInNewTab';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';
import { canChangeBoardCustomFields, canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

import { useGetPublicBoardMenuOptionsCallback } from './useGetPublicBoardMenuOptionsCallback';

type PublicBoardGalleryBoardProps = GalleryViewRender<PublicBoard>;

export const PublicBoardGalleryBoard = memo(({ data: board, box }: PublicBoardGalleryBoardProps) => {
  const { isSearchActive } = useIsSearchActive();
  const ancestors = useMemo(
    () => (isSearchActive && board.ancestors ? <PublicGalleryBoardAncestors ancestors={board.ancestors} /> : undefined),
    [isSearchActive, board.ancestors],
  );
  const { permissions } = usePublicPermissions();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPublicBoardMenuOptionsCallback();
  const _getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });
  const { goToPublicBoard } = useGoToPublicBoard();
  const { openPublicBoardInNewTab } = useOpenPublicBoardInNewTab();
  const onItemClick = useCallback(() => goToPublicBoard(board, 'board'), [board, goToPublicBoard]);
  const onItemCmdClick = useCallback(() => openPublicBoardInNewTab({ board }), [board, openPublicBoardInNewTab]);

  const canDownload = canGenerateZip(permissions);
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const canViewCustomFields = canSeeBoardCustomFields(permissions);
  const isSelectable = canDownload || canEditCustomFields;

  return (
    <BoardGalleryCard
      board={board}
      cardWidth={box.width}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={isSelectable}
      onClick={onItemClick}
      onCmdClickTitle={onItemCmdClick}
      ancestors={ancestors}
      canViewCustomFields={canViewCustomFields}
    />
  );
}, isEqual);

PublicBoardGalleryBoard.displayName = 'PublicBoardGalleryBoard';
