import { useCustomFieldsPanel } from '@air/component-custom-fields-panel';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import { useCanOpenPublicCustomFieldsPanel } from '~/components/PublicBoard/components/PublicBoardCustomFieldsPanelContent/hooks/useCanOpenPublicCustomFieldsPanel';

export const usePublicCustomFieldsPanel = () => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { canOpenCustomFieldsPanel } = useCanOpenPublicCustomFieldsPanel();
  const panelProps = useCustomFieldsPanel();

  const openPanel = useMemo(
    () => (canOpenCustomFieldsPanel && isAboveMediumScreen ? panelProps.openPanel : undefined),
    [canOpenCustomFieldsPanel, isAboveMediumScreen, panelProps.openPanel],
  );

  const togglePanel = useMemo(
    () => (openPanel ? panelProps.togglePanel : undefined),
    [openPanel, panelProps.togglePanel],
  );

  return {
    ...panelProps,
    openPanel,
    togglePanel,
  };
};
