import { useSelector } from 'react-redux';

import { NoSearchResults } from '~/components/NoSearchResults/NoSearchResults';
import { NoSearchResultsInfo } from '~/components/NoSearchResults/shared/NoSearchResultsInfo';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';

export const PublicBoardNoSearchResults = () => {
  const currentBoardTitle = useSelector(centralizedBoardTitleSelector);

  return (
    <NoSearchResults location={currentBoardTitle}>
      <NoSearchResultsInfo>Check for typos or filters, or try a different search query.</NoSearchResultsInfo>
    </NoSearchResults>
  );
};
