import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';

import { ErroredAvatar } from '../FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type CopyToWorkspaceFailedPanelItemProps = {
  onClear: () => void;
};

export const CopyToWorkspaceFailedPanelItem = memo(({ onClear }: CopyToWorkspaceFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<ErroredAvatar />}
      title={<StatusTrackingTitle>{`Failed to save!`}</StatusTrackingTitle>}
      buttons={<PaneButton label="Clear" Icon={Close} onClick={() => onClear()} />}
    />
  );
});

CopyToWorkspaceFailedPanelItem.displayName = 'CopyToWorkspaceFailedPanelItem';
