import { memo, useMemo } from 'react';

import { AssetModalMobileLayout } from '~/components/AssetModal/AssetModalMobileLayout';
import { MobileHeader } from '~/components/AssetModal/components/MobileHeader';
import { MobileCommentsPanelWrapper } from '~/components/AssetModal/MobileTabNavigation/components/MobileCommentsPanelWrapper';
import { MobileCommentsTab } from '~/components/AssetModal/MobileTabNavigation/components/MobileCommentsTab';
import { PublicMobileVersionsPanelWrapper } from '~/components/AssetModal/MobileTabNavigation/MobileVersionsPanelWrapper/PublicMobileVersionsPanelWrapper';
import { useCloseAssetModal } from '~/components/AssetModal/shared/useCloseAssetModal';
import { PublicImageVisualizer } from '~/components/AssetModal/Visualizer/ImageVisualizer/PublicImageVisualizer';
import { PublicPDFVisualizer } from '~/components/AssetModal/Visualizer/PDFVisualizer/PublicPDFVisualizer';
import { MobileVersionsTab } from '~/components/PrivateAssetModal/components/MobileVersionsTab';
import { PublicAssetModalInfoPanel } from '~/components/PublicAssetModal/components/PublicAssetModalInfoPanel';
import { PublicComments } from '~/components/PublicAssetModal/components/PublicCommentsPanel';
import { PublicMobileEllipsisMenu } from '~/components/PublicAssetModal/components/PublicMobileEllipsisMenu';
import { PublicPreviousAndNextArrows } from '~/components/PublicAssetModal/components/PublicPreviousAndNextArrows';
import { PublicVideoVisualizer } from '~/components/PublicAssetModal/components/PublicVideoVisualizer';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicBoardAssetModalMobileLayout = memo(() => {
  const { panel, hidePanel } = useAssetModalPanelContext();
  const { closeAssetModal } = useCloseAssetModal();

  const { permissions } = usePublicPermissions();
  const canViewComments = canViewAssetDiscussions(permissions);
  const canViewAssetVersions = canSeeAssetVersions(permissions);

  const content = useMemo(
    () => (
      <>
        {panel === AssetModalPanel.COMMENTS && canViewComments ? (
          <MobileCommentsPanelWrapper onClose={hidePanel} CommentsPanel={<PublicComments />} />
        ) : panel === AssetModalPanel.VERSIONS && canViewAssetVersions ? (
          <PublicMobileVersionsPanelWrapper onClose={hidePanel} />
        ) : null}
        <PublicAssetModalInfoPanel permissions={permissions} />
      </>
    ),
    [canViewAssetVersions, canViewComments, hidePanel, panel, permissions],
  );

  const tabs = useMemo(
    () => (
      <>
        {canViewComments && <MobileCommentsTab />}
        {canViewAssetVersions && <MobileVersionsTab />}
        <PublicMobileEllipsisMenu />
      </>
    ),
    [canViewAssetVersions, canViewComments],
  );

  const header = useMemo(() => <MobileHeader onClose={closeAssetModal} />, [closeAssetModal]);

  return (
    <AssetModalMobileLayout
      PreviousAndNextArrows={PublicPreviousAndNextArrows}
      PDFVisualizer={PublicPDFVisualizer}
      VideoVisualizer={PublicVideoVisualizer}
      ImageVisualizer={PublicImageVisualizer}
      tabs={tabs}
      panel={content}
      header={header}
    />
  );
});

PublicBoardAssetModalMobileLayout.displayName = 'PublicBoardAssetModalMobileLayout';
