import { Portal } from '@air/primitive-portal';
import { memo } from 'react';

import { CopyToWorkspacePanel } from '~/components/CopyToWorkspace/CopyToWorkspacePanel';

export const PublicClipActivityContainer = memo(() => {
  return (
    <Portal>
      <div className="fixed bottom-6 right-4">
        <CopyToWorkspacePanel />
      </div>
    </Portal>
  );
});

PublicClipActivityContainer.displayName = 'PublicClipActivityContainer';
