import { memo, useCallback } from 'react';

import { PublicAudioCodingsSelect } from '~/components/Filters/AudioCodingFilter/components/PublicAudioCodingsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PublicAudioCodingFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicAudioCodingFilterCard = memo(({ boardId, ...props }: PublicAudioCodingFilterCardProps) => {
  const renderAudioCodingsSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PublicAudioCodingsSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="audioCodings"
      filterCardType="audioCoding"
      StringSelect={renderAudioCodingsSelect}
      {...props}
    />
  );
});

PublicAudioCodingFilterCard.displayName = 'PublicAudioCodingFilterCard';
