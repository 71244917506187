import { Board, PublicBoard } from '@air/api/types';
import { ComponentType, memo, ReactNode, useCallback, useMemo } from 'react';

import { GalleryItemType, ItemProps } from '~/components/Gallery/types';
import { ConfigurableTableCells } from '~/components/TableView/TableRow/ConfigurableTableCells';
import { BoardCustomFieldTableCellProps } from '~/components/TableView/TableRow/CustomFieldTableCell/types';
import { BasicTableRowProps } from '~/components/TableView/types';
import { boardTableRowInfo } from '~/components/TableView/utils';
import { ConfigViewOption } from '~/store/configViews/types';

import { SelectableTableRow } from './SelectableTableRow';

export interface BoardTableRowProps<B> extends BasicTableRowProps<B> {
  boardProps: ItemProps;
  CustomFieldTableCell: ComponentType<BoardCustomFieldTableCellProps<B>>;
  canSelect?: boolean;
  renderNameCell: (_params: { isHovering: boolean; isSelected: boolean }) => ReactNode;
}

function _BoardTableRow<B extends Board | PublicBoard>({
  data,
  boardProps,
  CustomFieldTableCell,
  canSelect = false,
  renderNameCell,
}: BoardTableRowProps<B>) {
  const renderCustomFieldTableCellComponent = useCallback(
    (option: ConfigViewOption) => (
      <CustomFieldTableCell
        key={option.customFieldId}
        customFieldId={option.customFieldId ?? ''}
        customFieldName={option.fieldName}
        board={data}
      />
    ),
    [CustomFieldTableCell, data],
  );

  const contextMenuOptions = useMemo(
    () => boardProps.getItemMenuOptions({ trackLocation: 'table-item-context-menu' }),
    [boardProps],
  );

  const item = useMemo(() => {
    /**
     * Had to declare the type explicitly here cause useMemo screws types up
     */
    const item: {
      id: string;
      type: GalleryItemType.board;
      item: Board | PublicBoard;
    } = {
      id: data.id,
      type: GalleryItemType.board,
      item: data,
    };

    return item;
  }, [data]);

  const renderCells = useCallback(
    ({ isSelected, isHovering }: { isSelected: boolean; isHovering: boolean }) => {
      return (
        <>
          {renderNameCell({ isHovering, isSelected })}
          <ConfigurableTableCells
            renderCustomFieldTableCellComponent={renderCustomFieldTableCellComponent}
            rowInfo={boardTableRowInfo(data)}
          />
        </>
      );
    },
    [data, renderCustomFieldTableCellComponent, renderNameCell],
  );

  const onRowClick = useCallback(() => boardProps.onItemClick('table-cell'), [boardProps]);

  return (
    <SelectableTableRow
      isSelectable={canSelect}
      item={item}
      onRowClick={onRowClick}
      getSelectionMenuOptions={boardProps.getSelectionMenuOptions}
      contextMenuOptions={contextMenuOptions}
      renderCells={renderCells}
    />
  );
}

export const BoardTableRow = memo(_BoardTableRow) as typeof _BoardTableRow;
