import { memo, useMemo } from 'react';

import { Panel } from '~/components/AssetModal/Panel/Panel';
import { CommentsTabButton } from '~/components/AssetModal/Panel/Tabs/components/CommentsTabButton';
import { CustomFieldsTabButton } from '~/components/AssetModal/Panel/Tabs/components/CustomFieldsTabButton';
import { InfoTabButton } from '~/components/AssetModal/Panel/Tabs/components/InfoTabButton';
import { PublicAssetModalInfoPanel } from '~/components/PublicAssetModal/components/PublicAssetModalInfoPanel';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useOpenPublicCommentsPanel } from '~/hooks/useOpenPublicCommentsPanel';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { canSeeAssetCustomFields, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

import { PublicCustomFieldsPanelContainer } from '../shared/PublicCustomFieldsPanelContainer';
import { PublicAssetCommentsPanelContainer } from './PublicAssetCommentsPanelContainer';

export const PublicAssetModalPanel = memo(() => {
  const { panel } = useAssetModalPanelContext();
  const { permissions } = usePublicPermissions();
  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);

  useOpenPublicCommentsPanel();

  const tabs = useMemo(
    () => (
      <>
        {canViewDiscussions && <CommentsTabButton />}
        {canViewCustomFields && <CustomFieldsTabButton />}
        <InfoTabButton />
      </>
    ),
    [canViewCustomFields, canViewDiscussions],
  );

  const renderPanel = useMemo(() => {
    switch (panel) {
      case AssetModalPanel.FIELDS:
        return canViewCustomFields ? <PublicCustomFieldsPanelContainer /> : null;

      case AssetModalPanel.COMMENTS:
        return canViewDiscussions ? <PublicAssetCommentsPanelContainer /> : null;

      case AssetModalPanel.INFO:
        return <PublicAssetModalInfoPanel permissions={permissions} />;

      default:
    }
  }, [canViewCustomFields, canViewDiscussions, panel, permissions]);

  return <Panel panel={renderPanel} tabs={tabs} />;
});

PublicAssetModalPanel.displayName = 'PublicAssetModalPanel';
