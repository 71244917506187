import { useEffect } from 'react';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { useShowPublicCustomFieldBulkEditModal } from '~/hooks/customFields/useShowPublicCustomFieldBulkEditModal';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

import { clearSSOCallbackData, getSSOCallbackData } from './utils';

/**
 * Use this hook to continue what user tried to do before SSO login
 */
export const usePublicBoardSSOCallback = () => {
  const { copyToAir } = useCopyToWorkspace();
  const { showBulkEditModal } = useShowPublicCustomFieldBulkEditModal();
  const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

  useEffect(() => {
    const ssoCallbackData = getSSOCallbackData();
    if (ssoCallbackData) {
      switch (ssoCallbackData.action) {
        case 'copy-to-air':
          if (ssoCallbackData.data) {
            copyToAir(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
        case 'bulk-edit-custom-field-value':
          if (ssoCallbackData.data) {
            showBulkEditModal(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
        case 'edit-custom-field-value':
          if (ssoCallbackData.data) {
            updateItemsCustomFieldValue(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
      }
    }
  }, [copyToAir, showBulkEditModal, updateItemsCustomFieldValue]);
};
