import { Close } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { CTWTask, GetInProgressMetadata } from '~/store/tasks/types';

import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type CopyToWorkspacePanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<CTWTask>, 'targetWorkspaceName'>;
  onCancel: () => void;
};

export const CopyToWorkspacePanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { targetWorkspaceName } }: CopyToWorkspacePanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={<StatusTrackingTitle>{`Saving to "${targetWorkspaceName}"`}</StatusTrackingTitle>}
        buttons={<PaneButton label="Cancel" Icon={Close} onClick={() => onCancel()} />}
      />
    );
  },
);

CopyToWorkspacePanelInProgressPanelItem.displayName = 'CopyToWorkspacePanelInProgressPanelItem';
