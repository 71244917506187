import { PublicClip } from '@air/api';
import { isEqual } from 'lodash';
import Router from 'next/router';
import { memo, useCallback } from 'react';

import { FileGalleryCard } from '~/components/Gallery/FileGalleryCard/FileGalleryCard';
import { GalleryViewRender } from '~/components/Gallery/types';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { QueryParamNames } from '~/constants/search';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canSeeAssetVersions,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';

import { useGetPublicAssetMenuOptionsCallback } from './useGetPublicAssetMenuOptionsCallback';

type PublicBoardGalleryFileProps = GalleryViewRender<PublicClip>;

export const PublicBoardGalleryFile = memo(({ data: file, box: container }: PublicBoardGalleryFileProps) => {
  const { permissions } = usePublicPermissions();
  const { customFields } = usePublicCustomFieldsContext();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();
  const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: file });

  const canDownload = canDownloadVersion(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canViewDiscussions = canViewAssetDiscussions(permissions);

  const isSelectable = canDownload || canEditCustomFields;

  const onItemClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset: file,
        trackLocation: 'card-click',
      });
    });
  }, [file, goToAssetPage]);

  const onItemCmdClick = useCallback(() => {
    openAssetInNewTab({
      asset: file,
      trackLocation: 'card-click',
    });
  }, [file, openAssetInNewTab]);

  const onCustomFieldsClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.FIELDS,
        },
        trackLocation: 'custom-field-click',
      });
    });
  }, [file, goToAssetPage]);

  const onDiscussionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [file, goToAssetPage],
  );

  const onVersionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.versions]: true,
        },
        trackLocation: 'versions-click',
      }),
    [file, goToAssetPage],
  );

  return (
    <FileGalleryCard
      cardWidth={container.width}
      cardHeight={container.height}
      file={file}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={isSelectable}
      onCustomFieldsClick={onCustomFieldsClick}
      onDiscussionsClick={canViewDiscussions ? onDiscussionsClick : undefined}
      onVersionsClick={
        canViewAssetVersions && !!file.assetVersionCount && file.assetVersionCount > 1 ? onVersionsClick : undefined
      }
      onFileClick={onItemClick}
      onFileTitleCmdClick={onItemCmdClick}
      canViewCustomFields={canViewCustomFields}
      allCustomFields={customFields}
    />
  );
}, isEqual);

PublicBoardGalleryFile.displayName = 'PublicBoardGalleryFile';
