import { ScopedPermissions } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { AssetModalPanelContainer } from '~/components/AssetModal/Panel/Content/Container';
import { ReadonlyAssetTitleAndDescription } from '~/components/AssetModal/shared/components/InfoPanel/components/AssetTitleAndDescription/ReadonlyAssetTitleAndDescription';
import { InfoPanelCameraDetails } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelCameraDetails/InfoPanelCameraDetails';
import { InfoPanelCopyright } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelCopyright';
import { InfoPanelCreator } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelCreator';
import { InfoPanelDateCreated } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelDateCreated';
import { InfoPanelDateModified } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelDateModified';
import { InfoPanelFileDetails } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelFileDetails';
import { InfoPanelKeywords } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelKeywords/InfoPanelKeywords';
import { InfoPanelLoadingSkeleton } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelLoadingSkeleton';
import { InfoPanelLocation } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelLocation';
import { InfoPanelUploadedBy } from '~/components/AssetModal/shared/components/InfoPanel/components/InfoPanelUploadedBy';
import { InfoPanel } from '~/components/AssetModal/shared/components/InfoPanel/InfoPanel';
import { centralizedClipIdSelector, centralizedClipVersionNumberSelector } from '~/store/centralizedClip/selectors';
import { usePublicClipMetadata } from '~/swr-hooks/clips/usePublicClipMetadata';
import { canSeeAssetVersions } from '~/utils/permissions/assetPermissions';

export interface PublicAssetModalInfoPanelProps {
  permissions: ScopedPermissions | undefined;
}

export const PublicAssetModalInfoPanel = memo(({ permissions }: PublicAssetModalInfoPanelProps) => {
  const canViewAllVersions = canSeeAssetVersions(permissions);
  const clipVersion = useSelector(centralizedClipVersionNumberSelector);

  const { shortId } = useShortIdContext();
  const clipId = useSelector(centralizedClipIdSelector);

  const { data: clipMetadata, isLoading } = usePublicClipMetadata({ clipId, shortId });

  const isInitialLoading = isLoading && !clipMetadata;

  return (
    <AssetModalPanelContainer
      title={canViewAllVersions ? `Version ${clipVersion} info` : 'Info'}
      panel={
        <InfoPanel title={<ReadonlyAssetTitleAndDescription />}>
          {isInitialLoading ? (
            <InfoPanelLoadingSkeleton />
          ) : (
            <>
              <InfoPanelKeywords />
              <InfoPanelDateCreated />
              {!!clipMetadata && <InfoPanelLocation clipMetadata={clipMetadata.data} />}
              <InfoPanelFileDetails clipMetadata={clipMetadata?.data} />
              {!!clipMetadata && <InfoPanelCreator clipMetadata={clipMetadata.data} />}
              {!!clipMetadata && <InfoPanelCameraDetails clipMetadata={clipMetadata.data} />}
              <InfoPanelDateModified />
              <InfoPanelUploadedBy />
              {!!clipMetadata && <InfoPanelCopyright clipMetadata={clipMetadata.data} />}
            </>
          )}
        </InfoPanel>
      }
    />
  );
});

PublicAssetModalInfoPanel.displayName = 'PublicAssetModalInfoPanel';
