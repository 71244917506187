import { BoardsListOptions, ClipAndBoardListItem, ListResponse, SortDirection, ViewField } from '@air/api/types';
import { useCallback } from 'react';

import { usePublicBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePublicBoardsFetcher';

export const usePublicTableViewBoardsDataFetcher = () => {
  const { publicBoardsFetcher } = usePublicBoardsFetcher();

  const publicTableViewBoardsDataFetcher = useCallback(
    async ({
      params,
      sortFieldName,
      sortDirection,
      shortId,
      parentId,
      cursor,
    }: {
      cursor: string | null;
      shortId: string;
      parentId: string;
      params: Partial<BoardsListOptions>;
      sortFieldName: ViewField['name'];
      sortDirection: SortDirection;
    }): Promise<ListResponse<ClipAndBoardListItem>> => {
      const { pagination, data, total } = await publicBoardsFetcher({
        shortId,
        parentId,
        params,
        sortFieldName,
        sortDirection,
        cursor,
      });

      return {
        data: data.map((board) => ({
          id: board.id,
          type: 'board',
          data: board,
        })),
        total,
        pagination,
      };
    },
    [publicBoardsFetcher],
  );

  return {
    publicTableViewBoardsDataFetcher,
  };
};
