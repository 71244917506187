import { useShortIdContext } from '@air/provider-short-id';
import { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import { CopyToWorkspaceCompletedPanelItem } from '~/components/CopyToWorkspace/CopyToWorkspaceCompletedPanelItem';
import { CopyToWorkspaceFailedPanelItem } from '~/components/CopyToWorkspace/CopyToWorkspaceFailedPanelItem';
import { CopyToWorkspacePanelInProgressPanelItem } from '~/components/CopyToWorkspace/CopyToWorkspaceInProgressPanelItem';
import { PaneContainer } from '~/components/FileStatusTrackingPane/PaneContainer';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeCopyToWorkspaceTasksSelectorByShortId } from '~/store/tasks/selectors';
import { ReadyState } from '~/types/sockets';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { usePublicSyncTasks } from '~/utils/taskUtils/usePublicSyncTasks';

export const CopyToWorkspacePanel = memo(() => {
  const { workspaceId } = usePublicWorkspace();
  const { shortId } = useShortIdContext();
  const tasksSelector = useMemo(() => makeCopyToWorkspaceTasksSelectorByShortId(shortId), [shortId]);
  const dispatch = useDispatch();
  const ctwTasks = useAirSelector(tasksSelector);

  const { cancelTask } = useCancelTask();

  const { syncLocalTasks, loadFromStorage } = usePublicSyncTasks({
    shortId,
    workspaceId,
    tasksSelector,
    localType: 'CopyToWorkspace',
    remoteType: 'ContentDuplicator',
  });

  useMount(() => {
    loadFromStorage();
  });

  /**
   * When this panel unmounts (not possible in a public board but better safe than sorry),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: ctwTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  if (ctwTasks.length < 1) {
    return null;
  }

  return (
    <div>
      {ctwTasks.map((ctwTask) => {
        return (
          <PaneContainer key={ctwTask.localTaskId} className="mb-2 last:mb-0">
            {ctwTask.status === 'in-progress' ? (
              <CopyToWorkspacePanelInProgressPanelItem onCancel={() => cancelTask(ctwTask.localTaskId)} {...ctwTask} />
            ) : ctwTask.status === 'completed' ? (
              <CopyToWorkspaceCompletedPanelItem onClear={() => cancelTask(ctwTask.localTaskId)} {...ctwTask} />
            ) : (
              <CopyToWorkspaceFailedPanelItem onClear={() => cancelTask(ctwTask.localTaskId)} {...ctwTask} />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

CopyToWorkspacePanel.displayName = 'PublicCopyToWorkspacePanel';
