import { memo, ReactNode } from 'react';

import { KanbanNullState } from '~/components/KanbanNullState/KanbanNullState';

interface PublicKanbanNullStateProps {
  action?: ReactNode;
}

export const PublicKanbanNullState = memo(({ action }: PublicKanbanNullStateProps) => (
  <KanbanNullState action={action} text="There is no Kanban view available for this board." />
));

PublicKanbanNullState.displayName = 'PublicKanbanNullState';
