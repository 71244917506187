import { useBreakpointsContext } from '@air/provider-media-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';

import { PublicBoardEditCustomFieldsButton } from '~/_app/(public-board)/_components/PublicBoardEditCustomFieldsButton';
import { PublicBoardHeaderAncestors } from '~/_app/(public-board)/_components/PublicBoardHeaderAncestors';
import { PublicBoardHeaderCustomFields } from '~/_app/(public-board)/_components/PublicBoardHeaderCustomFields';
import { PublicBoardHeaderDescription } from '~/_app/(public-board)/_components/PublicBoardHeaderDescription';
import { PublicBoardHeaderTitle } from '~/_app/(public-board)/_components/PublicBoardHeaderTitle';
import { PublicBoardMobileShareButton } from '~/_app/(public-board)/_components/PublicBoardMobileShareButton';
import { PublicBoardMoreOptionsButton } from '~/_app/(public-board)/_components/PublicBoardMoreOptionsButton';
import { BoardHeader } from '~/_components/BoardHeader';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { canSeeAssetCustomFields } from '~/utils/permissions/assetPermissions';

export const PublicBoardHeader = memo(() => {
  const { permissions } = usePublicPermissions();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { mobileSearch: mobileSearchFlag } = useFlags();
  const canViewCustomFields = canSeeAssetCustomFields(permissions);

  return (
    <BoardHeader
      actions={
        <>
          {canViewCustomFields && <PublicBoardEditCustomFieldsButton />}
          {!isAboveMediumScreen && (
            <>
              <PublicBoardMobileShareButton />
              <PublicBoardMoreOptionsButton />
            </>
          )}
        </>
      }
      ancestors={(isAboveMediumScreen || !mobileSearchFlag) && <PublicBoardHeaderAncestors />}
      customFields={canViewCustomFields && <PublicBoardHeaderCustomFields />}
      description={<PublicBoardHeaderDescription />}
      title={<PublicBoardHeaderTitle />}
    />
  );
});

PublicBoardHeader.displayName = 'PublicBoardHeader';
