import { PublicClip } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { NextSeo } from 'next-seo';
import { memo } from 'react';

import { getSeoTitle } from '~/swr-hooks/shortUrl/utils';

interface PublicAssetSEOProps {
  clip: Pick<PublicClip, 'id' | 'title' | 'description' | 'assets' | 'workspaceName'>;
}

export const PublicAssetSEO = memo(({ clip }: PublicAssetSEOProps) => {
  const { shortId } = useShortIdContext();
  const url = `https://app.air.inc/a/${shortId}`;
  const thumbnail = clip.assets.image;

  return (
    <NextSeo
      nofollow
      noindex
      title={getSeoTitle('Asset', clip.title, clip.workspaceName)}
      description={clip.description ?? undefined}
      canonical={url}
      openGraph={{ url, images: !!thumbnail ? [{ url: thumbnail, alt: '' }] : undefined }}
    />
  );
});

PublicAssetSEO.displayName = 'PublicAssetSEO';
