import type { GetByShortIDBoardResponse } from '@air/api';
import { memo, ReactNode, useMemo } from 'react';
import { Provider } from 'react-redux';

import { makeStore } from '~/store';
import { setCentralizedPublicBoardAction } from '~/store/centralizedBoard/actions';
import { setSavedConfigurableViewsAction } from '~/store/configViews/actions';

export interface PublicBoardReduxProviderProps {
  children: ReactNode;
  initialBoard: GetByShortIDBoardResponse['data']['board'];
}

export const PublicBoardReduxProvider = memo(({ children, initialBoard }: PublicBoardReduxProviderProps) => {
  const { store } = useMemo(() => {
    const { store } = makeStore({
      centralizedBoard: initialBoard,
    });

    store.dispatch(
      setCentralizedPublicBoardAction({
        board: initialBoard,
      }),
    );

    store.dispatch(
      setSavedConfigurableViewsAction({
        savedConfigurableViews: initialBoard.views,
      }),
    );

    return { store };
  }, [initialBoard]);

  return <Provider store={store}>{children}</Provider>;
});

PublicBoardReduxProvider.displayName = 'PublicBoardReduxProvider';
