import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useSetPublicFiltersFromContextToRedux } from '~/hooks/filters/useSetPublicFiltersFromContextToRedux';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';

export const PublicFiltersReduxObserver = memo(() => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  useSetPublicFiltersFromContextToRedux({ boardId });

  return null;
});

PublicFiltersReduxObserver.displayName = 'PublicFiltersReduxObserver';
