import { useShortIdContext } from '@air/provider-short-id';
import { useCallback } from 'react';

import { createShortUrlBoardRoute } from '~/constants/routes';
import { LocationBoard } from '~/store/router/types';

export function useOpenPublicBoardInNewTab() {
  const { shortId } = useShortIdContext();

  const openPublicBoardInNewTab = useCallback(
    ({ board }: { board: LocationBoard; trackLocation?: string }) => {
      const boardRoute = createShortUrlBoardRoute(shortId, board.id);

      return window.open(boardRoute, '_blank', 'noopener,noreferrer');
    },
    [shortId],
  );

  return { openPublicBoardInNewTab };
}
