import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PublicSourcesSelect } from '~/components/Filters/SourceFilter/components/PublicSourcesSelect';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PublicSourceFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicSourceFilterCard = memo(({ boardId, ...props }: PublicSourceFilterCardProps) => {
  const renderSourceSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PublicSourcesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard filter="sources" filterCardType="source" StringSelect={renderSourceSelect} {...props} />
  );
});

PublicSourceFilterCard.displayName = 'PublicSourceFilterCard';
