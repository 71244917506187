import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';
import { PublicVideoFrameRatesSelect } from '~/components/Filters/VideoFrameRateFilter/components/PublicVideoFrameRatesSelect';

interface PublicVideoFrameRateFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicVideoFrameRateFilterCard = memo(({ boardId, ...props }: PublicVideoFrameRateFilterCardProps) => {
  const renderVideoFrameRateSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PublicVideoFrameRatesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="videoFrameRates"
      filterCardType="videoFrameRate"
      StringSelect={renderVideoFrameRateSelect}
      {...props}
    />
  );
});

PublicVideoFrameRateFilterCard.displayName = 'PublicVideoFrameRateFilterCard';
