/**
 * This key holds an encoded URI representing the redirect location after a log out is
 * finished. The value is an initialism representing "redirect path and query string".
 */
export const redirectURIKey = 'rpaqs';

/** We want to know if a password-protected short ID has been verified this session to prevent prefetches. */
export const getKeyForShortIDVerificationStatus = (shortID: string) => `${shortID}-sidvs`;

/** Session storage key to check if saml has been enforced in an
 * enterprise workspace that an account belongs to
 */
export const SAML_SSO_ENFORCED = 'SAML SSO enforced';

export const SELECTED_AUTH_PROVIDER = 'Selected auth provider';
