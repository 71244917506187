import { Stack } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export const MobileVersionsTab = memo(() => {
  const { showPanel } = useAssetModalPanelContext();

  return (
    <IconButton
      appearance="ghost"
      color="grey"
      data-testid="VERSIONS_PANEL"
      icon={Stack}
      onClick={() => showPanel(AssetModalPanel.VERSIONS)}
      size="large"
      label="Versions panel"
    />
  );
});

MobileVersionsTab.displayName = 'MobileVersionsTab';
