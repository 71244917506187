import { memo } from 'react';

import { EditCustomFieldsButton } from '~/_components/EditCustomFieldsButton';
import { usePublicCustomFieldsPanel } from '~/components/PublicBoard/components/PublicBoardCustomFieldsPanelContent/hooks/usePublicCustomFieldsPanel';

export const PublicBoardEditCustomFieldsButton = memo(() => {
  const { togglePanel } = usePublicCustomFieldsPanel();

  if (!togglePanel) return null;

  return <EditCustomFieldsButton onClick={togglePanel} />;
});

PublicBoardEditCustomFieldsButton.displayName = 'PublicBoardEditCustomFieldsButton';
