import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { ONE_HOUR } from '~/constants/unitsOfTime';

const getPublicLinkAddOnsKey = (shortId: string) => ['PUBLIC_LINK_ADDONS', { shortId }];

export const usePublicLinkAddOns = ({ shortId, enabled }: { shortId: string; enabled: boolean }) => {
  const key = getPublicLinkAddOnsKey(shortId);

  return useQuery({
    queryKey: key,
    queryFn: async () => ShortUrl.listAddOns({ shortId }),
    staleTime: ONE_HOUR,
    enabled: !!shortId && enabled,
  });
};
