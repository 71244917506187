import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicVideoAspectRatiosListKey = ({
  shortId,
  search,
  boardId,
}: {
  shortId: string | undefined;
  search: string;
  boardId: string | undefined;
}) => ['PUBLIC_VIDEO_ASPECT_RATIO_FORMATS', { shortId, search, boardId }];

export const usePublicVideoAspectRatiosList = ({
  search,
  shortId,
  boardId,
}: {
  search: string;
  shortId: string;
  boardId: string | undefined;
}) => {
  const key = getPublicVideoAspectRatiosListKey({ shortId, search, boardId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 10,
        search,
        shortId,
        field: 'videoAspectRatio',
        descendantBoardId: boardId,
      });

      return data.lookups.map(({ value }) => value.toString());
    },
    enabled: !!shortId,
  });
};
