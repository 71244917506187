import { useBreakpointsContext } from '@air/provider-media-query';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { memo, useCallback } from 'react';

import { useCanAnnotateCentralizedPublicClip } from '~/components/Annotations/hooks/useCanAnnotateCentralizedPublicClip';
import { InitialCommentInput } from '~/components/Discussions/InitialCommentInput/InitialCommentInput';
import { PublicCommentAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicCommentAnonymousSignUpModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useAccountContext } from '~/providers/AccountProvider';
import { useAnnotationContext } from '~/providers/AnnotationProvider/AnnotationProvider';
import { centralizedBoardAncestorsSelector } from '~/store/centralizedBoard/selectors';
import { useGetDiscussionMentionsMembers } from '~/swr-hooks/discussions/useGetDiscussionMentionsMembers';
import { useSubmitNewDiscussion } from '~/swr-hooks/discussions/useSubmitNewDiscussion';
import { useAirSelector } from '~/utils/ReduxUtils';

export const PublicDiscussionsForm = memo(() => {
  const { data: account } = useAccountContext();
  const { getValue } = useAnnotationContext();
  const { canSeeClipAnnotation } = useCanAnnotateCentralizedPublicClip();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { submitNewDiscussion } = useSubmitNewDiscussion();
  const [showSignUpModal] = useAirModal(PublicCommentAnonymousSignUpModal);
  const boardId = useURLBoardIdSelector();
  const { workspaceId: clipWorkspaceId } = usePublicWorkspace();
  const ancestors = useAirSelector(centralizedBoardAncestorsSelector);
  const mentionsList = useGetDiscussionMentionsMembers({ boardId, ancestors, workspaceId: clipWorkspaceId });

  const handleNewDiscussionSubmit = useCallback(
    (newComment: string, timestamp?: number) => {
      Router.ready(() => {
        const submitDiscussion = () => {
          submitNewDiscussion({
            trackLocation: 'public-board',
            newComment,
            timestamp,
            annotation: getValue().newAnnotation,
          });
        };

        if (account) {
          submitDiscussion();
        } else {
          const ssoCallbackData: SSOCallbackData = {
            url: Router.asPath,
            action: {
              action: 'create-public-discussion',
              data: {
                newComment,
                timestamp,
                annotation: getValue().newAnnotation,
                trackLocation: 'public-board',
              },
            },
          };

          showSignUpModal({
            onLoginSuccess: submitDiscussion,
            ssoCallbackData,
          });
        }
      });
    },
    [account, getValue, showSignUpModal, submitNewDiscussion],
  );

  return (
    <InitialCommentInput
      canAnnotateClip={canSeeClipAnnotation}
      onSubmit={handleNewDiscussionSubmit}
      placeholder="Share your thoughts…"
      mentionsList={mentionsList}
      shouldShowMentionsButton={isAboveMediumScreen}
      hasInternalAvatar={!isAboveMediumScreen}
    />
  );
});

PublicDiscussionsForm.displayName = 'PublicDiscussionsForm';
