import { type Clip } from '@air/api/types';
import pluralize from 'pluralize';
import { memo, useMemo } from 'react';

import { AssetMobileVersionsContent } from '~/_components/AssetMobileVersionsContent';
import { AssetMobileVersionsHeader } from '~/_components/AssetMobileVersionsHeader';
import { AssetMobileVersionsList } from '~/_components/AssetMobileVersionsList';
import { AssetMobileVersionsItem } from '~/_components/AssetMobileVersionsListItem';

export type AssetMobileVersionsProps = {
  isActiveVersion?: (version: Clip) => boolean;
  onVersionClick?: (version: Clip) => void;
  versions: Clip[] | undefined;
};

export const AssetMobileVersions = memo(
  ({ isActiveVersion, onVersionClick, versions = [] }: AssetMobileVersionsProps) => {
    const currentVersion = useMemo(() => {
      return versions[0];
    }, [versions]);

    const otherVersions = useMemo(() => {
      return versions.slice(1) ?? [];
    }, [versions]);

    return (
      <AssetMobileVersionsContent>
        <AssetMobileVersionsHeader />
        {!!currentVersion && (
          <AssetMobileVersionsList
            renderVersion={(version) => {
              return (
                <AssetMobileVersionsItem
                  isActive={isActiveVersion?.(version)}
                  key={version.id}
                  onClick={!!onVersionClick ? () => onVersionClick?.(version) : undefined}
                  version={version}
                />
              );
            }}
            title="Cover version"
            versions={[currentVersion]}
          />
        )}

        {!!otherVersions.length && (
          <AssetMobileVersionsList
            renderVersion={(version) => {
              return (
                <AssetMobileVersionsItem
                  isActive={isActiveVersion?.(version)}
                  key={version.id}
                  onClick={!!onVersionClick ? () => onVersionClick?.(version) : undefined}
                  version={version}
                />
              );
            }}
            title={`Other ${pluralize('version', otherVersions.length, true)}`}
            versions={otherVersions}
          />
        )}
      </AssetMobileVersionsContent>
    );
  },
);

AssetMobileVersions.displayName = 'AssetMobileVersions';
