import { useEffect } from 'react';

import { getActiveUserSessionType } from '~/utils/getActiveUserSessionType';

interface IUseOnMissingUserSession {
  onMissingSession: () => void;
  enabled: boolean;
}

export const useOnMissingUserSession = ({ onMissingSession, enabled }: IUseOnMissingUserSession) => {
  useEffect(() => {
    let isMounted = true;

    const checkIfLoggedIn = async () => {
      const activeSessionType = await getActiveUserSessionType();
      if (activeSessionType === 'none' && isMounted) {
        onMissingSession();
      }
    };

    if (enabled) {
      checkIfLoggedIn();
    }

    return () => {
      isMounted = false;
    };
  }, [enabled, onMissingSession]);
};
