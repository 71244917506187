import { AirActionTrackingLocation } from '@air/analytics';
import { PublicBoard } from '@air/api/types';
import { ChevronRight as ChevronRightIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import React, { CSSProperties, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { GalleryItemType, ItemProps } from '~/components/Gallery/types';
import { useGetPublicBoardMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicBoardMenuOptionsCallback';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { BoardTableRow, BoardTableRowProps } from '~/components/TableView/BoardTableRow';
import { MobileTableRowContainer } from '~/components/TableView/MobileTableRow/MobileTableRowContainer';
import { PublicBoardNameCell } from '~/components/TableView/PrivateTableView/PublicBoardNameCell';
import { PublicBoardCustomFieldTableCell } from '~/components/TableView/TableRow/CustomFieldTableCell/PublicBoardCustomFieldTableCell';
import { DesktopTableRowContainer } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { BasicTableRowProps } from '~/components/TableView/types';
import { TABLE_ROW } from '~/constants/testIDs';
import { useOpenPublicBoardInNewTab } from '~/hooks/useOpenPublicBoardInNewTab';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { usePublicWorkspaceHorizontalPadding } from '~/hooks/usePublicWorkspaceHorizontalPadding';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export interface PublicBoardTableRowProps extends BasicTableRowProps<PublicBoard> {
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style: CSSProperties;
}

export const PublicBoardTableRow = memo(({ data: board, style }: PublicBoardTableRowProps) => {
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPublicBoardMenuOptionsCallback();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { responsiveHorizontalPadding } = usePublicWorkspaceHorizontalPadding();
  const { permissions } = usePublicPermissions();
  const getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });
  const { goToPublicBoard } = useGoToPublicBoard();
  const { openPublicBoardInNewTab } = useOpenPublicBoardInNewTab();
  const onItemClick = useCallback(
    (trackLocation: AirActionTrackingLocation) => goToPublicBoard(board, trackLocation),
    [board, goToPublicBoard],
  );
  const onItemCmdClick = useCallback(() => openPublicBoardInNewTab({ board }), [board, openPublicBoardInNewTab]);

  const canDownload = canGenerateZip(permissions);
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const isSelectable = canDownload || canEditCustomFields;
  const memoizedBoardProps: ItemProps = useMemo(
    () => ({
      isSelectable,
      onItemClick,
      onItemCmdClick,
      getSelectionMenuOptions,
      getItemMenuOptions,
    }),
    [getItemMenuOptions, getSelectionMenuOptions, isSelectable, onItemClick, onItemCmdClick],
  );

  const renderNameCell: BoardTableRowProps<PublicBoard>['renderNameCell'] = useCallback(
    ({ isSelected, isHovering }) => (
      <PublicBoardNameCell isHovering={isHovering} isSelected={isSelected} board={board} {...memoizedBoardProps} />
    ),
    [board, memoizedBoardProps],
  );

  return isAboveMediumScreen ? (
    <DesktopTableRowContainer
      style={style}
      data-testid={`${TABLE_ROW}-${GalleryItemType.board}`}
      data-title={board.title}
    >
      <BoardTableRow
        renderNameCell={renderNameCell}
        data={board}
        boardProps={memoizedBoardProps}
        CustomFieldTableCell={PublicBoardCustomFieldTableCell}
        canSelect={isSelectable}
      />
    </DesktopTableRowContainer>
  ) : (
    <MobileTableRowContainer
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        ...style,
      }}
    >
      {renderNameCell({ isSelected: false, isHovering: false })}
      <IconButton
        appearance="ghost"
        color="grey"
        icon={ChevronRightIcon}
        onClick={() => {
          memoizedBoardProps.onItemClick('table-cell');
        }}
        size="extra-large"
        className="h-full"
        label="View board"
      />
    </MobileTableRowContainer>
  );
}, isEqual);

PublicBoardTableRow.displayName = 'PublicBoardTableRow';
