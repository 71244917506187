import pluralize from 'pluralize';

import { SEARCH_RESULTS_TEXT } from '~/constants/testIDs';

interface SearchResultsNumberProps {
  total?: number;
  location?: string;
}

export const SearchResultsNumber = ({ total, location }: SearchResultsNumberProps) =>
  total ? (
    <div className="truncate text-14 text-grey-11" data-testid={SEARCH_RESULTS_TEXT}>
      <span className="font-semibold">
        {total.toLocaleString()}&nbsp;
        {pluralize('result', total)}
      </span>
      {!!location && (
        <span>
          {` `}in {location}
        </span>
      )}
    </div>
  ) : null;
