import { memo, ReactNode } from 'react';

export interface AssetModalMobileTabNavigationProps {
  tabs: ReactNode;
}

export const AssetModalMobileTabNavigation = memo(({ tabs }: AssetModalMobileTabNavigationProps) => {
  return (
    <div className="flex h-14 w-full shrink-0 items-center justify-around bg-grey-1 [&_.isEmpty]:justify-end">
      {tabs}
    </div>
  );
});

AssetModalMobileTabNavigation.displayName = 'AssetModalMobileTabNavigation';
