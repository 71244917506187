import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

interface GetDownloadMenuOptions {
  onDownload: () => void;
  onExportImage?: () => void;
  onSaveToAir: () => void;
}

export const getDownloadMenuOptions = ({ onDownload, onExportImage, onSaveToAir }: GetDownloadMenuOptions) => {
  const downloadMenuOptions: DropdownMenuOption[] = [
    {
      ...getDropdownMenuOption('save-to-air'),
      onSelect: onSaveToAir,
      type: 'item',
    },
    {
      ...getDropdownMenuOption('download'),
      onSelect: onDownload,
      type: 'item',
    },
  ];

  if (onExportImage) {
    downloadMenuOptions.push({
      ...getDropdownMenuOption('export-as'),
      onSelect: onExportImage,
      type: 'item',
    });
  }

  return downloadMenuOptions;
};
