import { Account } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { formatInitials } from '@air/utilities';
import Router from 'next/router';
import { memo, useMemo } from 'react';

import { Routes } from '~/constants/routes';
import { formatFullName } from '~/utils/formatFullName';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

interface PublicTopBarMobileAccountInfoProps {
  account: Pick<Account, 'email' | 'firstName' | 'lastName' | 'avatar' | 'id'>;
  canCreateAccount: boolean;
}

export const PublicTopBarMobileAccountInfo = memo(
  ({ account: { lastName, firstName, email, avatar, id }, canCreateAccount }: PublicTopBarMobileAccountInfoProps) => {
    const fullName = formatFullName(firstName, lastName);
    const initials = formatInitials(firstName, lastName);

    const options = useMemo(() => {
      const options: DropdownMenuOption[] = [
        {
          id: 'account-name',
          type: 'children',
          children: (
            <p className="truncate text-14 text-grey-12">
              {fullName} ({email})
            </p>
          ),
        },
      ];

      if (canCreateAccount) {
        options.push({
          id: 'create-account',
          type: 'item',
          label: 'Create account',
          onSelect: () => {
            Router.push(`${Routes.auth.signUp}?email=${encodeURIComponent(email)}`);
          },
        });
      }

      return options;
    }, [fullName, email, canCreateAccount]);

    const trigger = useMemo(
      () => (
        <button>
          <Avatar
            alt={`${fullName} avatar`}
            colorSeed={id}
            size={24}
            src={getAvatarImageSrc(avatar, 24)}
            text={initials}
          />
        </button>
      ),
      [avatar, fullName, id, initials],
    );

    return (
      <DropdownMenu size="small" className="shrink-0" trigger={trigger}>
        {renderDropdownItems({ options, size: 'small' })}
      </DropdownMenu>
    );
  },
);

PublicTopBarMobileAccountInfo.displayName = 'PublicTopBarAccountInfo';
