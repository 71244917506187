import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import { HEADER_DESKTOP_HORIZONTAL_PADDING, HEADER_MOBILE_HORIZONTAL_PADDING } from '~/constants/WorkspaceSpacing';

export function usePublicHeaderHorizontalPadding() {
  const { isAboveMediumScreen } = useBreakpointsContext();

  const responsiveHorizontalPadding = useMemo(
    () => (!isAboveMediumScreen ? HEADER_MOBILE_HORIZONTAL_PADDING : HEADER_DESKTOP_HORIZONTAL_PADDING),
    [isAboveMediumScreen],
  );

  return { responsiveHorizontalPadding };
}
