import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

type CurrentWorkspaceProviderContextValue =
  | {
      workspaceId: string;
      workspaceName: string;
      workspaceImage: string;
    }
  | undefined;

const PublicWorkspaceProviderContext = createContext<CurrentWorkspaceProviderContextValue>(undefined);

export type PublicWorkspaceProviderProps = CurrentWorkspaceProviderContextValue & {
  children: ReactNode;
};

export const PublicWorkspaceProvider = memo(
  ({ children, workspaceId, workspaceImage, workspaceName }: PublicWorkspaceProviderProps) => {
    const value = useMemo(
      () => ({
        workspaceId,
        workspaceImage,
        workspaceName,
      }),
      [workspaceId, workspaceImage, workspaceName],
    );

    return <PublicWorkspaceProviderContext.Provider value={value}>{children}</PublicWorkspaceProviderContext.Provider>;
  },
);

PublicWorkspaceProvider.displayName = 'PublicWorkspaceProvider';

export function usePublicWorkspace() {
  const context = useContext(PublicWorkspaceProviderContext);

  if (!context) {
    throw 'PublicWorkspaceProviderContext used outside of PublicWorkspaceProvider';
  }

  return context;
}
