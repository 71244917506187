import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';
import { PublicVideoAspectRatiosSelect } from '~/components/Filters/VideoAspectRatioFilter/components/PublicVideoAspectRatiosSelect';

interface PublicVideoAspectRatioFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicVideoAspectRatioFilterCard = memo(({ boardId, ...props }: PublicVideoAspectRatioFilterCardProps) => {
  const renderVideoAspectRatiosSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PublicVideoAspectRatiosSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="videoAspectRatios"
      filterCardType="videoAspectRatio"
      StringSelect={renderVideoAspectRatiosSelect}
      {...props}
    />
  );
});

PublicVideoAspectRatioFilterCard.displayName = 'PublicVideoAspectRatioFilterCard';
