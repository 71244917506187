import { useShortIdContext } from '@air/provider-short-id';
import { memo } from 'react';

import { SourcesSelect } from '~/components/Selects/SourcesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePublicSourcesList } from '~/swr-hooks/filters/usePublicSourcesList';

interface PublicSourcesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicSourcesSelect = memo(({ boardId, ...props }: PublicSourcesSelectProps) => {
  const { shortId } = useShortIdContext();

  const { data = [], isLoading } = usePublicSourcesList({
    shortId,
    boardId,
  });

  return <SourcesSelect isLoading={isLoading} sources={data} {...props} />;
});

PublicSourcesSelect.displayName = 'PublicSourcesSelect';
