import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Portal } from '@air/primitive-portal';
import { memo, ReactNode } from 'react';

interface MobileCommentsPanelWrapperProps {
  onClose: () => void;
  CommentsPanel: ReactNode;
}

export const MobileCommentsPanelWrapper = memo(({ onClose, CommentsPanel }: MobileCommentsPanelWrapperProps) => {
  return (
    <Portal>
      <div className="fixed inset-y-0 left-0 flex w-screen flex-col bg-grey-1">
        <div className="flex h-12 items-center justify-between border-b border-b-grey-5 px-6">
          <div className="text-16 font-semibold text-grey-12">Comments</div>
          <IconButton
            appearance="ghost"
            color="grey"
            icon={Close}
            label="Close comments"
            onClick={onClose}
            size="small"
          />
        </div>
        <div className="flex w-full flex-1 shrink-0 flex-col overflow-auto">{CommentsPanel}</div>
      </div>
    </Portal>
  );
});

MobileCommentsPanelWrapper.displayName = 'MobileCommentsPanelWrapper';
