import { useShortIdContext } from '@air/provider-short-id';
import { useMemo } from 'react';

import { useFetchAnyPublicPermissions } from '~/hooks/useFetchAnyPublicPermissions';
import { usePublicLinkAddOns } from '~/swr-hooks/workspaces/addOns/usePublicLinkAddOns';
import { canViewAddOns } from '~/utils/permissions/workspacePermissions';

export const useCanSeePublicIPTCFields = () => {
  const { shortId } = useShortIdContext();

  const { data: permissions } = useFetchAnyPublicPermissions();

  const { data: addOns } = usePublicLinkAddOns({ shortId, enabled: canViewAddOns(permissions) });

  const canSeePublicIPTCFields = useMemo(
    () => !!addOns?.data.some((addOn) => addOn.key === 'metadata' && addOn.enabled && addOn.visible),
    [addOns],
  );

  return {
    canSeePublicIPTCFields,
  };
};
