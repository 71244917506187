import { memo, useCallback } from 'react';

import { PublicCreatorSelect } from '~/components/Filters/CreatorFilter/components/PublicCreatorSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PublicCreatorFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicCreatorFilterCard = memo(({ boardId, ...props }: PublicCreatorFilterCardProps) => {
  const renderStringSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicCreatorSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard filterCardType="creator" filter="creators" StringSelect={renderStringSelect} {...props} />
  );
});

PublicCreatorFilterCard.displayName = 'PublicCreatorFilterCard';
