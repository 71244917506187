import { PublicClip } from '@air/api';
import { isEqual } from 'lodash';
import Router from 'next/router';
import { memo, useCallback } from 'react';

import { AssetGalleryCard } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCard';
import { GalleryViewRender } from '~/components/Gallery/types';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { QueryParamNames } from '~/constants/search';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canSeeAssetVersions,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';

import { useGetPublicAssetMenuOptionsCallback } from './useGetPublicAssetMenuOptionsCallback';

type PublicBoardGalleryAssetProps = GalleryViewRender<PublicClip>;

export const PublicBoardGalleryAsset = memo(({ data: asset, box }: PublicBoardGalleryAssetProps) => {
  const { permissions } = usePublicPermissions();
  const { customFields } = usePublicCustomFieldsContext();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();
  const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });

  const onItemClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset,
        trackLocation: 'card-click',
      });
    });
  }, [asset, goToAssetPage]);

  const onItemCmdClick = useCallback(() => {
    openAssetInNewTab({
      asset,
      trackLocation: 'card-click',
    });
  }, [asset, openAssetInNewTab]);

  const onCustomFieldsClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.FIELDS,
        },
        trackLocation: 'custom-field-click',
      });
    });
  }, [asset, goToAssetPage]);

  const onDiscussionsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [asset, goToAssetPage],
  );

  const onVersionsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.versions]: true,
        },
        trackLocation: 'versions-click',
      }),
    [asset, goToAssetPage],
  );

  const canDownload = canDownloadVersion(permissions);
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const isSelectable = canDownload || canEditCustomFields;

  return (
    <AssetGalleryCard
      cardWidth={box.width}
      clip={asset}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={isSelectable}
      onAssetClick={onItemClick}
      onAssetTitleCmdClick={onItemCmdClick}
      onCustomFieldsClick={onCustomFieldsClick}
      onDiscussionsClick={onDiscussionsClick}
      onVersionsClick={onVersionsClick}
      showVersionsIndicator={canViewAssetVersions && !!asset.assetVersionCount && asset.assetVersionCount > 1}
      canViewCustomFields={canViewCustomFields}
      allCustomFields={customFields}
      shouldShowDiscussions={canViewDiscussions}
    />
  );
}, isEqual);

PublicBoardGalleryAsset.displayName = 'PublicBoardGalleryAsset';
