import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicCameraModelsList } from '~/swr-hooks/filters/usePublicCameraModelsList';

interface PublicCameraModelsSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
  make: string | undefined;
}

export const PublicCameraModelsSelect = memo(({ boardId, make, ...props }: PublicCameraModelsSelectProps) => {
  const { shortId } = useShortIdContext();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicCameraModelsList({
    search: debouncedSearchValue,
    shortId,
    boardId,
    make,
  });

  return (
    <StringSelect
      data-testid="CAMERA_MODELS_SELECT"
      placeholder="Search for model"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PublicCameraModelsSelect.displayName = 'PublicCameraModelsSelect';
