import { useShortIdContext } from '@air/provider-short-id';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AssetsSearchBar } from '~/components/AssetsSearchBar/AssetsSearchBar';
import { useBoardSearchToken } from '~/components/Search/SearchTokens/useBoardSearchToken';
import { SearchBarHandle } from '~/components/SearchBar/SearchBar';
import { BoardSuggestionType } from '~/components/SearchBar/types';
import { createShortUrlBoardRoute } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { useStickyResult } from '~/hooks/useStickyResult';
import { centralizedBoardAncestorsSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';
import { usePublicSearchSuggestions } from '~/swr-hooks/search/usePublicSearchSuggestions';
import { pushWithExistingQuery } from '~/utils/PathUtils';

export interface PublicSearchProps {
  onCloseClick: () => void;
}

export const PublicSearch = ({ onCloseClick }: PublicSearchProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { isFlattenedView } = useIsFlattenedView();

  const { shortId } = useShortIdContext();
  const boardName = useSelector(centralizedBoardTitleSelector);
  const boardAncestors = useSelector(centralizedBoardAncestorsSelector);

  const searchBarRef = useRef<SearchBarHandle>(null);

  const [isFocused, setIsFocused] = useState(false);

  const { search, setSearch, clearSearch } = useSearchParams();

  const onClose = useCallback(() => {
    clearSearch();
    onCloseClick();
  }, [clearSearch, onCloseClick]);

  const { goToPublicBoard } = useGoToPublicBoard();

  const onBoardClick = useCallback((board: BoardSuggestionType) => goToPublicBoard(board, 'search'), [goToPublicBoard]);

  const { data, refetch } = usePublicSearchSuggestions({ search: searchTerm, shortId, canFetch: isFocused });
  // this hook prevents passing empty data to SearchBar while suggestions are loading
  // It is used to keep previous suggestions visible while new ones are not returned yet
  const suggestions = useStickyResult(data);

  const onFocusChange = useCallback(
    (isFocused: boolean) => {
      setIsFocused(isFocused);
      if (isFocused) {
        refetch();
      }
    },
    [refetch],
  );

  const { onBackspacePress, wasTokenRemoved, BoardToken, showToken } = useBoardSearchToken({
    isFocused,
    hasValue: !!searchTerm,
    boardName,
    searchBarRef,
  });

  const showBoardToken = isFlattenedView || boardAncestors.length > 0;

  const onApplyTextSearch = useCallback(
    (newTerm: string) => {
      if (wasTokenRemoved) {
        const newQuery = { [QueryParamNames.Search]: newTerm };
        if (boardAncestors.length > 0) {
          pushWithExistingQuery({
            path: shortId ? createShortUrlBoardRoute(shortId, boardAncestors[0].id) : '/',
            newQuery,
          });
        } else {
          pushWithExistingQuery({
            newQuery,
          });
        }
      } else {
        setSearch(newTerm);
      }
      setSearchTerm(newTerm);
    },
    [boardAncestors, wasTokenRemoved, setSearch, shortId],
  );

  return (
    <AssetsSearchBar
      ref={searchBarRef}
      onBackspacePress={onBackspacePress}
      trackLocation="Public Board"
      searchTerm={search}
      onCloseClick={onClose}
      onApplyTextSearch={onApplyTextSearch}
      placeholder="Search board"
      onFocusChange={onFocusChange}
      onSearchClear={showToken}
      onSearchTermChange={setSearchTerm}
      textSuggestions={suggestions?.suggestions}
      boardSuggestions={suggestions?.boards}
      onBoardClick={onBoardClick}
    >
      {showBoardToken && <div className="flex">{BoardToken}</div>}
    </AssetsSearchBar>
  );
};
