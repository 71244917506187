import { useAirModal } from '@air/provider-modal';
import { useIsLoggedIn } from '@air/utils-auth';
import Router from 'next/router';
import { useCallback } from 'react';

import { PublicCustomFieldAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicCustomFieldAnonymousSignUpModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';

import { UpdateItemsCustomFieldValueParams, useUpdateItemsCustomFieldValue } from './useUpdateItemsCustomFieldValue';

export const useUpdatePublicItemsCustomFieldValue = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const [showSignUpModal] = useAirModal(PublicCustomFieldAnonymousSignUpModal);

  const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

  const updatePublicItemsCustomFieldValue = useCallback(
    async (values: Omit<UpdateItemsCustomFieldValueParams, 'workspaceId'>) => {
      if (isLoggedIn) {
        await updateItemsCustomFieldValue({ ...values, workspaceId: undefined });
      } else {
        await new Promise((resolve, reject) => {
          Router.ready(() => {
            const ssoCallbackData: SSOCallbackData = {
              url: Router.asPath,
              action: {
                action: 'edit-custom-field-value',
                data: { ...values, workspaceId: undefined },
              },
            };

            showSignUpModal({
              onLoginSuccess: () => {
                updateItemsCustomFieldValue({ ...values, workspaceId: undefined })
                  .then(resolve)
                  .catch(reject);
              },
              ssoCallbackData,
            });
          });
        });
      }
    },
    [isLoggedIn, showSignUpModal, updateItemsCustomFieldValue],
  );

  return {
    updatePublicItemsCustomFieldValue,
  };
};
