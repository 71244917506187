import { memo } from 'react';
import { useSelector } from 'react-redux';

import { ContentHeaderTitle } from '~/components/PageHeader/ContentHeaderTitle';
import { BOARD_BLURB_TITLE } from '~/constants/testIDs';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';

export const PublicBoardHeaderTitle = memo(() => {
  const title = useSelector(centralizedBoardTitleSelector);

  if (!title) return null;

  return <ContentHeaderTitle data-testid={BOARD_BLURB_TITLE}>{title}</ContentHeaderTitle>;
});

PublicBoardHeaderTitle.displayName = 'PublicBoardHeaderTitle';
