import { Ellipsis } from '@air/next-icons';
import { ActionSheet, renderActionSheetItems } from '@air/primitive-action-sheet';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback, useMemo, useState } from 'react';

import { MobileAssetModalNavigationItem } from '~/_components/MobileAssetModalNavigationItem';
import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { usePublicDownloadOptions } from '~/components/PublicAssetModal/shared/hooks/usePublicDownloadOptions';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { getPublicClipShareUrl } from '~/swr-hooks/shortUrl/utils';
import { useAirStore } from '~/utils/ReduxUtils';

export const PublicAssetMobileNavigationEllipsisMenu = memo(() => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const downloadOptions = usePublicDownloadOptions();
  const [sharePublicClip] = useAirModal(SharePublicClipModal);
  const store = useAirStore();
  const { shortId } = useShortIdContext();

  const onShareClick = useCallback(() => {
    const clipId = centralizedClipIdSelector(store.getState());

    if (!clipId) return;

    sharePublicClip({
      id: clipId,
      url: getPublicClipShareUrl(shortId),
      trackLocation: 'public-asset-page',
    });
  }, [sharePublicClip, shortId, store]);

  const options = useMemo<DropdownMenuOption[]>(() => {
    return [
      {
        id: 'share',
        label: 'Share',
        onSelect: onShareClick,
        type: 'item',
      },
      /**
       * @TODO - Move these to it's own buttons once design makes a decision.
       */
      ...downloadOptions,
    ];
  }, [downloadOptions, onShareClick]);

  if (!options.length) return null;

  return (
    <>
      <MobileAssetModalNavigationItem
        id="more-options"
        icon={Ellipsis}
        label="More options"
        onClick={() => setIsActionSheetOpen(true)}
        order={8}
      />

      {isActionSheetOpen && (
        <ActionSheet onClose={() => setIsActionSheetOpen(false)}>{renderActionSheetItems({ options })}</ActionSheet>
      )}
    </>
  );
});

PublicAssetMobileNavigationEllipsisMenu.displayName = 'PublicAssetMobileNavigationEllipsisMenu';
