import { memo, useCallback } from 'react';

import { PublicCitiesSelect } from '~/components/Filters/CityFilter/components/PublicCitiesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PublicCitiesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicCityFilterCard = memo(({ boardId, ...props }: PublicCitiesFilterCardProps) => {
  const renderCitiesSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicCitiesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return <StringArrayFilterCard filter="cities" filterCardType="city" StringSelect={renderCitiesSelect} {...props} />;
});
PublicCityFilterCard.displayName = 'PublicCityFilterCard';
