import { memo } from 'react';

import { PublicAssetMobileVersion } from '~/_app/(public-asset)/_components/PublicAssetMobileVersions';
import { MobileVersionsPanelWrapper } from '~/components/AssetModal/MobileTabNavigation/MobileVersionsPanelWrapper/MobileVersionsPanelWrapper';
interface PublicMobileVersionsPanelWrapperProps {
  onClose: () => void;
}

export const PublicMobileVersionsPanelWrapper = memo(({ onClose }: PublicMobileVersionsPanelWrapperProps) => {
  return (
    <MobileVersionsPanelWrapper onClose={onClose}>
      <PublicAssetMobileVersion />
    </MobileVersionsPanelWrapper>
  );
});

PublicMobileVersionsPanelWrapper.displayName = 'PublicMobileVersionsPanelWrapper';
