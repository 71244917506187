import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { ExportImagesModal } from '~/components/ExportImagesModal/ExportImagesModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useDownloadPublicAsset } from '~/hooks/useDownloadPublicAsset';
import {
  centralizedClipAssetIdSelector,
  centralizedClipAssetsSelector,
  centralizedClipDimensionsSelector,
  centralizedClipExtSelector,
  centralizedClipIdSelector,
  centralizedDisplayTitleSelector,
} from '~/store/centralizedClip/selectors';
import { extCanBeExported } from '~/utils/Imgix';
import { canDownloadVersion, canSeeAssetVersions } from '~/utils/permissions/assetPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export const usePublicDownloadOptions = () => {
  const store = useAirStore();
  const { shortId } = useShortIdContext();
  const clipId = useSelector(centralizedClipIdSelector);
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const ext = useSelector(centralizedClipExtSelector);
  const [showExportImagesModal] = useAirModal(ExportImagesModal);
  const { copyToAir } = useCopyToWorkspace();
  const { permissions } = usePublicPermissions();
  const assets = useSelector(centralizedClipAssetsSelector);
  const originalAsset = assets.original;
  const { downloadPublicAsset } = useDownloadPublicAsset();

  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canDownload = canDownloadVersion(permissions);

  const onSelectDownload = useMemo(
    () =>
      shortId && clipId && canDownload
        ? () => {
            downloadPublicAsset({
              shortId,
              clipId,
              trackLocation: 'public-asset-page',
            });
          }
        : undefined,
    [canDownload, clipId, downloadPublicAsset, shortId],
  );

  const onSelectExportModal = useMemo(
    () =>
      extCanBeExported(ext) && originalAsset && canDownload
        ? () => {
            const fileName = centralizedDisplayTitleSelector(store.getState());
            const { height, width } = centralizedClipDimensionsSelector(store.getState());

            return showExportImagesModal({
              fileName,
              height,
              imageSrc: originalAsset,
              width,
            });
          }
        : undefined,
    [canDownload, ext, originalAsset, showExportImagesModal, store],
  );

  const onSelectCopy = useMemo(
    () => () => {
      /**
       * We only allow the entire asset to be copied if canViewAssetVersions
       * is true. Else, we just let them copy that single clip (asset version)
       */
      return canViewAssetVersions && assetId
        ? copyToAir({
            assetId,
            trackLocation: 'public-asset-page',
          })
        : copyToAir({
            clipId,
            trackLocation: 'public-asset-page',
          });
    },
    [assetId, canViewAssetVersions, clipId, copyToAir],
  );

  return useMemo(() => {
    const options: DropdownMenuOption[] = [];

    if (onSelectDownload && canDownload) {
      options.push({
        id: 'download',
        label: 'Download',
        onSelect: onSelectDownload,
        type: 'item',
      });
    }

    if (onSelectCopy) {
      options.push({
        id: 'copy',
        label: 'Copy to Air workspace',
        onSelect: onSelectCopy,
        type: 'item',
      });
    }

    if (onSelectExportModal) {
      options.push({
        id: 'export',
        label: 'Export as',
        onSelect: onSelectExportModal,
        type: 'item',
      });
    }

    return options;
  }, [canDownload, onSelectCopy, onSelectDownload, onSelectExportModal]);
};
