import { useTrackClickedPublicBoardLink } from '@air/analytics';
import { DoubleChevronLeft } from '@air/next-icons';
import Router from 'next/router';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { PublicTopBarAccountInfo } from '~/components/Layouts/PublicBoardLayout/PublicTopBarAccountInfo';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { createAllContentAssetRoute } from '~/constants/routes';
import { useAccountContext } from '~/providers/AccountProvider';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';

import { AirLogoLink } from '../shared/components/AirLogoLink';

export const LoggedInBanner = memo(() => {
  const { workspaceName } = usePublicWorkspace();
  const clipId = useSelector(centralizedClipIdSelector);
  const { trackClickedPublicBoardLink } = useTrackClickedPublicBoardLink();
  const { data: account } = useAccountContext();

  const onClick = useCallback(() => {
    trackClickedPublicBoardLink({
      name: 'clicked view in workspace',
      page_area: 'banner',
      page_type: 'public asset',
    });

    Router.push(createAllContentAssetRoute(clipId));
  }, [clipId, trackClickedPublicBoardLink]);

  return (
    <div className="relative flex h-full w-full items-center justify-between bg-pigeon-900 px-3">
      <div className="flex cursor-pointer items-center justify-start text-grey-10 hover:text-grey-12" onClick={onClick}>
        <DoubleChevronLeft className="mr-1 h-5 w-5 text-inherit" />
        <div className="truncate text-16 font-medium text-inherit">View in {workspaceName}</div>
      </div>
      <div className="flex items-center gap-4">
        {!!account && <PublicTopBarAccountInfo account={account} />}
        <AirLogoLink />
      </div>
    </div>
  );
});

LoggedInBanner.displayName = 'LoggedInBanner';
