import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

export const ContentHeaderTitle = memo(({ className, ...props }: HTMLAttributes<HTMLHeadElement>) => {
  return (
    <h1
      className={classNames('my-[6px] mr-4 break-words text-24 font-semibold text-pigeon-700', className)}
      data-disableselect="true"
      {...props}
    />
  );
});

ContentHeaderTitle.displayName = 'ContentHeaderTitle';
