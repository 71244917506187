import { Account } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { PublicTopBarDesktopAccountInfo } from '~/components/Layouts/PublicBoardLayout/PublicTopBarDesktopAccountInfo';
import { PublicTopBarMobileAccountInfo } from '~/components/Layouts/PublicBoardLayout/PublicTopBarMobileAccountInfo';

export interface PublicTopBarAccountInfoProps {
  account: Pick<Account, 'email' | 'firstName' | 'lastName' | 'avatar' | 'id' | 'type'>;
}

export const PublicTopBarAccountInfo = memo(({ account }: PublicTopBarAccountInfoProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();

  const isAnonymousAccount = account?.type === 'anonymous';

  return isAboveMediumScreen ? (
    <PublicTopBarDesktopAccountInfo account={account} canCreateAccount={isAnonymousAccount} />
  ) : (
    <PublicTopBarMobileAccountInfo account={account} canCreateAccount={isAnonymousAccount} />
  );
});

PublicTopBarAccountInfo.displayName = 'PublicTopBarAccountInfo';
