import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import {
  WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED,
  WKSPC_MOBILE_HORIZONTAL_PADDING,
} from '~/constants/WorkspaceSpacing';

/**
 * responsiveHorizontalPadding: adjusts for mobile/desktop padding used in many places.
 */
export function usePublicWorkspaceHorizontalPadding() {
  const { isAboveMediumScreen } = useBreakpointsContext();

  const responsiveHorizontalPadding = useMemo(
    () =>
      !isAboveMediumScreen ? WKSPC_MOBILE_HORIZONTAL_PADDING : WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED,
    [isAboveMediumScreen],
  );

  return { responsiveHorizontalPadding };
}
