import { useIsLoggedIn } from '@air/utils-auth';
import { memo, useCallback, useMemo } from 'react';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';

import { DefaultBanner } from './components/DefaultBanner';
import { LoggedInBanner } from './components/LoggedInBanner';

export const PublicAssetModalBanner = memo(() => {
  const { data: workspaces = [] } = useWorkspaces();
  const { isLoggedIn } = useIsLoggedIn();
  const { workspaceId: assetWorkspaceId } = usePublicWorkspace();

  const isVisitBannerVisible = useMemo(() => {
    if (!isLoggedIn || !workspaces || !assetWorkspaceId) return false;
    return workspaces.some((workspace) => workspace.id === assetWorkspaceId);
  }, [isLoggedIn, assetWorkspaceId, workspaces]);

  const renderInner = useCallback(() => {
    if (isVisitBannerVisible) {
      return <LoggedInBanner />;
    }

    return <DefaultBanner />;
  }, [isVisitBannerVisible]);

  return <div className="flex h-14 w-screen shrink-0">{renderInner()}</div>;
});

PublicAssetModalBanner.displayName = 'PublicAssetModalBanner';
