import { memo } from 'react';

import { usePublicCustomFieldsPanel } from '~/components/PublicBoard/components/PublicBoardCustomFieldsPanelContent/hooks/usePublicCustomFieldsPanel';
import { BoardBlurbCustomFields } from '~/components/Workspace/BoardHeader/BoardCrumbs/BoardBlurbCustomFields';
import { usePublicBoardCustomFieldsData } from '~/swr-hooks/customFields/usePublicBoardCustomFieldsData';

export const PublicBoardHeaderCustomFields = memo(() => {
  const { data: boardCustomFields } = usePublicBoardCustomFieldsData();
  const { togglePanel } = usePublicCustomFieldsPanel();

  return <BoardBlurbCustomFields customFields={boardCustomFields?.data} onPanelClick={togglePanel} />;
});

PublicBoardHeaderCustomFields.displayName = 'PublicBoardHeaderCustomFields';
