import { PublicClip } from '@air/api';
import { useBreakpointsContext } from '@air/provider-media-query';
import React, { CSSProperties, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { ItemProps } from '~/components/Gallery/types';
import { useGetPublicAssetMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicAssetMenuOptionsCallback';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { AssetTableRow, AssetTableRowProps } from '~/components/TableView/AssetTableRow';
import { MobileTableRowContainer } from '~/components/TableView/MobileTableRow/MobileTableRowContainer';
import { PublicAssetNameCell } from '~/components/TableView/PrivateTableView/PublicAssetNameCell';
import { PublicAssetCustomFieldTableCell } from '~/components/TableView/TableRow/CustomFieldTableCell/PublicAssetCustomFieldTableCell';
import { DesktopTableRowContainer } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { BasicTableRowProps } from '~/components/TableView/types';
import { QueryParamNames } from '~/constants/search';
import { TABLE_ROW } from '~/constants/testIDs';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { usePublicWorkspaceHorizontalPadding } from '~/hooks/usePublicWorkspaceHorizontalPadding';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';
import { canChangeAssetCustomFields, canDownloadVersion } from '~/utils/permissions/assetPermissions';

export interface PublicAssetTableRowProps extends BasicTableRowProps<PublicClip> {
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style: CSSProperties;
}

export const PublicAssetTableRow = memo(({ data: asset, style }: PublicAssetTableRowProps) => {
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
  const { responsiveHorizontalPadding } = usePublicWorkspaceHorizontalPadding();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const itemType = getAssetGalleryItemType(asset);
  const { permissions } = usePublicPermissions();
  const getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();
  const onItemClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, goToAssetPage],
  );
  const onItemCmdClick = useCallback(
    () =>
      openAssetInNewTab({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, openAssetInNewTab],
  );

  const onCommentsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [asset, goToAssetPage],
  );

  const onVersionsClick = useCallback(() => {
    goToAssetPage({
      asset,
      query: {
        [QueryParamNames.versions]: true,
      },
      trackLocation: 'versions-click',
    });
  }, [asset, goToAssetPage]);

  const canDownload = canDownloadVersion(permissions);
  const canEditCustomFields = canChangeAssetCustomFields(permissions);

  const isSelectable = canDownload || canEditCustomFields;

  const memoizedAssetProps: ItemProps = useMemo(
    () => ({
      getItemMenuOptions,
      getSelectionMenuOptions,
      isSelectable,
      onCommentsClick,
      onItemClick,
      onItemCmdClick,
      onVersionsClick,
    }),
    [
      getItemMenuOptions,
      getSelectionMenuOptions,
      isSelectable,
      onCommentsClick,
      onItemClick,
      onItemCmdClick,
      onVersionsClick,
    ],
  );

  const renderNameCell: AssetTableRowProps<PublicClip>['renderNameCell'] = useCallback(
    ({ isSelected, isHovering }) => (
      <PublicAssetNameCell isHovering={isHovering} isSelected={isSelected} clip={asset} {...memoizedAssetProps} />
    ),
    [asset, memoizedAssetProps],
  );

  return isAboveMediumScreen ? (
    <DesktopTableRowContainer
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        ...style,
      }}
      data-testid={`${TABLE_ROW}-${itemType}`}
      data-title={asset.title}
    >
      <AssetTableRow
        renderNameCell={renderNameCell}
        CustomFieldTableCell={PublicAssetCustomFieldTableCell}
        assetProps={memoizedAssetProps}
        data={asset}
      />
    </DesktopTableRowContainer>
  ) : (
    <MobileTableRowContainer
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        ...style,
      }}
    >
      {renderNameCell({ isSelected: false, isHovering: false })}
    </MobileTableRowContainer>
  );
}, isEqual);

PublicAssetTableRow.displayName = 'PrivateAssetTableRow';
