import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicVideoFrameRatesListKey = ({
  shortId,
  boardId,
}: {
  shortId: string | undefined;
  boardId: string | undefined;
}) => ['PUBLIC_VIDEO_FRAME_RATES', { shortId, boardId }];

export const usePublicVideoFrameRatesList = ({
  shortId,
  boardId,
}: {
  shortId: string;
  boardId: string | undefined;
}) => {
  const key = getPublicVideoFrameRatesListKey({ shortId, boardId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 50,
        shortId,
        field: 'videoFrameRate',
        descendantBoardId: boardId,
      });

      return data.lookups.map(({ value }) => value.toString());
    },
    enabled: !!shortId,
  });
};
