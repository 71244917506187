import { Air } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';

export const PasswordPageHeader = () => (
  <header className="absolute left-0 top-0 z-[3] w-full border-t-2 border-teal-7 pl-6 pt-1 md:pl-8">
    <Link href={Routes.root} passHref={true}>
      <Air className="w-20 text-blue-9" />
    </Link>
  </header>
);

PasswordPageHeader.displayName = 'PasswordPageHeader';
