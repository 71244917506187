import { memo } from 'react';

import { useCanAnnotateCentralizedPublicClip } from '~/components/Annotations/hooks/useCanAnnotateCentralizedPublicClip';
import { ImageVisualizer } from '~/components/AssetModal/Visualizer/ImageVisualizer/ImageVisualizer';

export const PublicImageVisualizer = memo(() => {
  const { canEditAnnotation } = useCanAnnotateCentralizedPublicClip();

  return <ImageVisualizer canAnnotateClip={canEditAnnotation} />;
});

PublicImageVisualizer.displayName = 'PublicImageVisualizer';
