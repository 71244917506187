import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';

import { PublicExtensionsSelect } from '~/components/Filters/ExtensionFilter/components/PublicExtensionsSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PublicExtensionsFilterCardProps extends BaseFilterCardProps {
  boardId: Board['id'] | undefined;
}

export const PublicExtensionsFilterCard = memo(({ boardId, ...props }: PublicExtensionsFilterCardProps) => {
  const renderExtensionsSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicExtensionsSelect {...props} boardId={boardId} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="extensions"
      filterCardType="extension"
      StringSelect={renderExtensionsSelect}
      {...props}
    />
  );
});

PublicExtensionsFilterCard.displayName = 'PublicExtensionsFilterCard';
