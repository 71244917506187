import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useGetPublicKanbanCustomField } from '~/components/KanbanView/KanbanColumnsManagers/DndKanbanManager/useUpdateKanbanItemPosition/useGetPublicKanbanCustomField';
import { KanbanView } from '~/components/KanbanView/KanbanView';
import { KanbanProvider } from '~/components/KanbanView/Providers/KanbanProvider';
import { KanbanNoCustomFieldPermissions } from '~/components/PublicBoard/components/PublicKanbanView/components/KanbanNoCustomFieldPermissions';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { ONE_HOUR } from '~/constants/unitsOfTime';
import { useTableHeaderPosition } from '~/hooks/useTableHeaderPosition';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PublicKanbanNullState } from './components/PublicKanbanNullState';

export const PublicKanbanView = memo(() => {
  const { tableHeaderTopPos } = useTableHeaderPosition();
  const { customFields } = usePublicCustomFieldsContext();

  const { permissions } = usePublicPermissions();
  const canViewCustomFields = canSeeBoardCustomFields(permissions);
  const baseCustomField = useGetPublicKanbanCustomField();
  const customFieldId = useSelector(currentKanbanGroupByCustomFieldIdSelector);
  const { data: options } = useGetPublicCustomFieldOptions({
    customFieldId,
    staleTime: ONE_HOUR,
  });
  const { workspaceId } = usePublicWorkspace();

  const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

  return (
    <KanbanProvider
      customFieldValues={options?.data}
      onUpdateItems={async (params) => {
        await updateItemsCustomFieldValue({
          ...params,
          trackingLocation: 'kanban-view',
          workspaceId: undefined,
        });
      }}
      workspaceId={workspaceId}
    >
      <KanbanView
        NullState={canViewCustomFields ? PublicKanbanNullState : KanbanNoCustomFieldPermissions}
        tableHeaderTopPos={tableHeaderTopPos}
        customFields={customFields}
        baseCustomField={baseCustomField}
        customFieldValues={options?.data}
        canViewCustomFields={canViewCustomFields}
      />
    </KanbanProvider>
  );
});

PublicKanbanView.displayName = 'PublicKanbanView';
