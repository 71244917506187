import { useTrackAddedComment } from '@air/analytics';
import { type PublicClip } from '@air/api';
import { type Clip } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import Router from 'next/router';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAnnotationAnalyticsType, hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { type ClipDiscussion } from '~/components/Annotations/shared/types';
import { useDiscussionPanelContextSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { discussionsVersionFilterSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { useOnAnnotatedCommentClick } from '~/components/AssetModal/Visualizer/VideoVisualizer/utils';
import { DiscussionsList, type DiscussionsListProps } from '~/components/Discussions/DiscussionsList';
import { DiscussionPermissionsProvider } from '~/components/Discussions/providers/DiscussionPermissionsProvider';
import { EmptyComments } from '~/components/PublicAssetModal/components/EmptyComments';
import { PublicCommentAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicCommentAnonymousSignUpModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { type SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { QueryParamNames } from '~/constants/search';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useAccountContext } from '~/providers/AccountProvider';
import { centralizedBoardAncestorsSelector } from '~/store/centralizedBoard/selectors';
import {
  centralizedClipAssetIdSelector,
  centralizedClipIdSelector,
  centralizedClipTypeSelector,
  centralizedClipVersionNumberSelector,
} from '~/store/centralizedClip/selectors';
import { type CreateCommentParams, useCreateComment } from '~/swr-hooks/discussions/useCreateComment';
import { useDeleteCommentClick } from '~/swr-hooks/discussions/useDeleteCommentClick';
import { useGetDiscussionMentionsMembers } from '~/swr-hooks/discussions/useGetDiscussionMentionsMembers';
import { usePublicDiscussions } from '~/swr-hooks/discussions/usePublicDiscussions';
import { useSaveEditedCommentClick } from '~/swr-hooks/discussions/useSaveCommentEditClick';
import { usePublicVersionsList } from '~/swr-hooks/versions/usePublicVersionsList';
import { useSwitchVersion } from '~/swr-hooks/versions/useSwitchVersion';
import { setQueryParams } from '~/utils/PathUtils';
import {
  canEditAssetDiscussions,
  canSeeAssetVersions,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export const PublicDiscussionsList = memo(() => {
  const store = useAirStore();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { workspaceId: clipWorkspaceId } = usePublicWorkspace();
  const { permissions } = usePublicPermissions();
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const { shortId } = useShortIdContext();
  const boardId = useURLBoardIdSelector();
  const clipId = useSelector(centralizedClipIdSelector);
  const { data: account } = useAccountContext();
  const ancestors = useAirSelector(centralizedBoardAncestorsSelector);
  const mentionsList = useGetDiscussionMentionsMembers({ boardId, ancestors, workspaceId: clipWorkspaceId });
  const [showSignUpModal] = useAirModal(PublicCommentAnonymousSignUpModal);
  const versionNumber = useSelector(centralizedClipVersionNumberSelector);
  const discussionsVersionFilter = useDiscussionPanelContextSelector(discussionsVersionFilterSelector);
  const { switchVersion } = useSwitchVersion();
  const { createComment } = useCreateComment();
  const { trackAddedComment } = useTrackAddedComment();
  const { handleCommentDeleteClick } = useDeleteCommentClick({ trackLocation: 'public-board' });

  const {
    data: discussions,
    isLoading: isInitialLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    total,
  } = usePublicDiscussions({
    boardId,
    clipId: discussionsVersionFilter === 'CURRENT' || !canViewAssetVersions ? clipId : undefined,
    assetId,
    shortId,
    isEnabled: canViewDiscussions,
  });

  const { onAnnotatedCommentClick } = useOnAnnotatedCommentClick();

  const { data: versions } = usePublicVersionsList();
  const { handleSaveEditedCommentClick } = useSaveEditedCommentClick({ trackLocation: 'public-board' });
  const canEditDiscussions = canEditAssetDiscussions(permissions);

  const onSwitchVersion = useCallback(
    (clipId: PublicClip['id']) => {
      const clip = versions?.data.clips.find((c) => c.id === clipId);
      clip && switchVersion({ version: clip, location: 'discussion' });
    },
    [switchVersion, versions?.data.clips],
  );

  const filteredDiscussions = useMemo(() => {
    switch (discussionsVersionFilter) {
      case 'ALL':
        return discussions;
      case 'CURRENT':
        return discussions.filter((d) => d.assetVersion === versionNumber);
    }
    return [];
  }, [discussions, discussionsVersionFilter, versionNumber]);

  const handleAnnotatedCommentClick: DiscussionsListProps['onAnnotatedCommentClick'] = useCallback(
    ({ clipId, discussionId, timestamp, hasAnnotation }) => {
      onAnnotatedCommentClick({
        onSwitchVersion: () => onSwitchVersion(clipId),
        discussionId,
        timestamp,
        hasAnnotation,
      });
    },

    [onAnnotatedCommentClick, onSwitchVersion],
  );

  const handleCommentSubmit = useCallback(
    (newComment: string, existingDiscussion: ClipDiscussion, clipId: Clip['id']) => {
      Router.ready(() => {
        const commentSubmitParams: CreateCommentParams = {
          newComment,
          existingDiscussion,
          boardId,
          shortId,
        };

        trackAddedComment({
          boardId,
          clipId,
          comment: newComment,
          location: 'public-board',
          public: true,
          startedDiscussion: false,
          has_timestamp: !!existingDiscussion.timestamp,
          has_annotation: hasAnnotation(existingDiscussion),
          annotationType: getAnnotationAnalyticsType(existingDiscussion.annotation),
          assetType: centralizedClipTypeSelector(store.getState()),
        });

        if (account) {
          return createComment(commentSubmitParams);
        } else {
          const ssoCallbackData: SSOCallbackData = {
            url: Router.asPath,
            action: {
              action: 'reply-to-public-comment',
              data: {
                newComment,
                existingDiscussion,
                boardId,
                shortId,
              },
            },
          };

          showSignUpModal({
            onLoginSuccess: () => createComment(commentSubmitParams),
            ssoCallbackData,
          });
        }
      });
    },
    [boardId, shortId, trackAddedComment, store, account, createComment, showSignUpModal],
  );

  const handleSwitchVersion = useCallback(
    (clipId: Clip['id']) => {
      onSwitchVersion(clipId);
      setQueryParams({ [QueryParamNames.timestamp]: null, [QueryParamNames.discussionId]: null }, 'replace');
    },
    [onSwitchVersion],
  );
  return (
    <DiscussionPermissionsProvider permissions={permissions}>
      <DiscussionsList
        totalDiscussions={total}
        hasNextPage={!!hasNextPage}
        fetchNextPage={fetchNextPage}
        discussions={filteredDiscussions}
        isLoading={isInitialLoading || isFetchingNextPage}
        mentionsList={mentionsList}
        onToggleCommentResolve={undefined}
        onAnnotatedCommentClick={handleAnnotatedCommentClick}
        onCommentDelete={canEditDiscussions ? handleCommentDeleteClick : undefined}
        onCommentEdit={canEditDiscussions ? handleSaveEditedCommentClick : undefined}
        onCommentSubmit={canEditDiscussions ? handleCommentSubmit : undefined}
        canReply={canEditDiscussions}
        onSwitchVersion={canViewAssetVersions ? handleSwitchVersion : undefined}
        shouldShowMentionsButton={true}
        emptyState={<EmptyComments />}
      />
    </DiscussionPermissionsProvider>
  );
});

PublicDiscussionsList.displayName = 'PublicDiscussionsList';
