import { Close } from '@air/next-icons';
import { useRouter } from 'next/router';
import React, { memo, useEffect } from 'react';

import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { createAllContentAssetRoute, Routes } from '~/constants/routes';
import { CTWTask, GetCompletedMetadata } from '~/store/tasks/types';

import { CompletedAvatar } from '../FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type CopyToWorkspaceCompletedPanelItemProps = {
  metadata: Pick<GetCompletedMetadata<CTWTask>, 'duplicatedRootBoards' | 'duplicatedRootClips' | 'targetWorkspaceId'>;
  onClear: () => void;
};

export const CopyToWorkspaceCompletedPanelItem = memo(
  ({
    metadata: { duplicatedRootBoards, duplicatedRootClips, targetWorkspaceId },
    onClear,
  }: CopyToWorkspaceCompletedPanelItemProps) => {
    const { push } = useRouter();
    const copiedBoard = !!duplicatedRootBoards?.length;
    const itemId = duplicatedRootBoards?.[0]?.id || duplicatedRootClips?.[0]?.id;

    const workspaceUrl = itemId
      ? copiedBoard
        ? `/b/${itemId}`
        : createAllContentAssetRoute(itemId)
      : `${Routes.media.all}?wsid=${targetWorkspaceId}`;

    useEffect(() => {
      setTimeout(() => {
        window.open(workspaceUrl, '_blank');
      }, 2000);
    }, [workspaceUrl]);

    return (
      <PaneItemTW
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Save complete!</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle>
            {
              <>
                {`If you weren't redirected, `}
                <button
                  className="text-12 text-blue-9 hover:text-blue-11 hover:underline"
                  onClick={() => push(workspaceUrl)}
                >
                  click here
                </button>
              </>
            }
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={() => onClear()} />}
      />
    );
  },
);

CopyToWorkspaceCompletedPanelItem.displayName = 'CopyToWorkspaceCompletedPanelItem';
