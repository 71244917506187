import { memo } from 'react';

import { BasicAssetHeaderLocation } from '~/components/AssetModal/Header/components/BasicAssetHeaderLocation';
import { HeaderBoardAndFieldsInfo } from '~/components/AssetModal/Header/components/HeaderBoardAndFieldsInfo';
import { PublicAssetHeaderShareButton } from '~/components/AssetModal/Header/components/PublicAssetHeaderShareButton';
import { Header } from '~/components/AssetModal/Header/Header';
import { PublicAssetModalHeaderTitle } from '~/components/PublicAssetModal/components/PublicAssetModalHeaderTitle';
import { PublicDownloadButton } from '~/components/PublicAssetModal/components/PublicDownloadButton';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { usePublicAssetCustomFieldsData } from '~/hooks/usePublicAssetCustomFieldsData';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';

import { PublicAssetModalBanner } from '../shared/components/PublicAssetModalBanner/PublicAssetModalBanner';

export const PublicAssetModalHeader = memo(() => {
  const { permissions } = usePublicPermissions();
  const canDiscuss = canViewAssetDiscussions(permissions);
  const canDownload = canDownloadVersion(permissions);
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const { data: customFields } = usePublicAssetCustomFieldsData();
  const { workspaceName } = usePublicWorkspace();

  return (
    <>
      <PublicAssetModalBanner />
      <Header
        headerLeft={
          <>
            <PublicAssetModalHeaderTitle />
            <HeaderBoardAndFieldsInfo
              assetLocation={<BasicAssetHeaderLocation workspaceName={workspaceName} />}
              fields={customFields?.data}
              canEditCustomFields={canEditCustomFields}
              canViewCustomFields={canViewCustomFields}
              showComments={canDiscuss}
            />
          </>
        }
        headerRight={
          <>
            <PublicAssetHeaderShareButton />
            {canDownload && (
              <div className="ml-3">
                <PublicDownloadButton />
              </div>
            )}
          </>
        }
      />
    </>
  );
});

PublicAssetModalHeader.displayName = 'PublicAssetModalHeader';
