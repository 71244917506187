import { AssetCustomFieldValue } from '@air/api/types';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { centralizedClipAssetIdSelector } from '~/store/centralizedClip/selectors';
import { canChangeAssetCustomFields } from '~/utils/permissions/assetPermissions';

import { PublicAssetCustomFieldSelect } from './PublicAssetCustomFieldSelect';
import { PublicAssetPanelCustomFieldContainer } from './PublicAssetPanelCustomFieldContainer';

export interface PublicAssetPanelCustomFieldSelectProps {
  field: AssetCustomFieldValue;
}

export const PublicAssetPanelCustomFieldSelect = memo(({ field }: PublicAssetPanelCustomFieldSelectProps) => {
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeAssetCustomFields(permissions);

  return (
    <PublicAssetPanelCustomFieldContainer field={field}>
      <PublicAssetCustomFieldSelect
        field={field}
        trackingLocation="asset-details"
        assetId={assetId}
        canEditCustomFields={!!canEditCustomFields}
      />
    </PublicAssetPanelCustomFieldContainer>
  );
});

PublicAssetPanelCustomFieldSelect.displayName = 'PublicAssetPanelCustomFieldSelect';
