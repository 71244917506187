import { PublicClip, ShortUrl } from '@air/api';
import { Clip } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { setCentralizedClipAction } from '~/store/centralizedClip/actions';

interface UsePublicAssetClipParams {
  clipId: Clip['id'];
}

const PUBLIC_ASSET_CLIP = 'PUBLIC_ASSET_CLIP';

export const getPublicAssetClipKey = ({ clipId, shortId }: { clipId: Clip['id']; shortId: string }) => [
  PUBLIC_ASSET_CLIP,
  { clipId, shortId },
];

/**
 * This hook loads in the current clip/version for the public asset.
 * It takes a clipId so that it can load in the different versions if the user
 * changes versions in the modal
 */
export function usePublicAssetClip({
  clipId,
  initialData,
}: UsePublicAssetClipParams & {
  initialData: PublicClip | undefined;
}) {
  const dispatch = useDispatch();
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getPublicAssetClipKey({ clipId, shortId }),

    queryFn: async () => {
      const { clip } = await ShortUrl.getClip({ clipId, shortId });

      dispatch(setCentralizedClipAction({ clip }));

      return clip;
    },
    enabled: !!clipId,
    staleTime: 0,
    // @ts-ignore until we fix ShortUrl.getClip to return PublicClip
    initialData,
  });
}
