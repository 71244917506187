import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebouncedCallback } from 'use-debounce';

import { PreviousAndNextAssetModalArrows } from '~/components/PreviousAndNextAssetModalArrows';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { usePublicMediaPlayerData } from '~/swr-hooks/mediaPlayer/usePublicMediaPlayerData';

export const PublicPreviousAndNextArrows = memo(() => {
  const { shortId } = useShortIdContext();
  const { loadMore, nextAsset, previousAsset, isNearEnd } = usePublicMediaPlayerData(shortId);
  const { goToAssetPage } = useGoToAssetPage();

  const onPreviousClick = useCallback(() => {
    if (previousAsset) {
      goToAssetPage({
        asset: previousAsset,
      });
    }
  }, [goToAssetPage, previousAsset]);

  const onNextClick = useCallback(() => {
    if (nextAsset) {
      goToAssetPage({
        asset: nextAsset,
      });
      if (isNearEnd) {
        loadMore();
      }
    }
  }, [goToAssetPage, isNearEnd, loadMore, nextAsset]);

  const [delayedOnPrevious] = useDebouncedCallback(onPreviousClick, 100);
  const [delayedOnNext] = useDebouncedCallback(onNextClick, 100);

  useHotkeys(
    'left,right',
    (_keyboardEvent, hotkeysEvent) => {
      if (hotkeysEvent.keys?.includes('left')) {
        delayedOnPrevious();
      } else if (hotkeysEvent.keys?.includes('right')) {
        delayedOnNext();
      }
    },
    [delayedOnNext, delayedOnPrevious, !!nextAsset, !!previousAsset],
  );

  return (
    <PreviousAndNextAssetModalArrows
      onNextClick={!!nextAsset ? onNextClick : undefined}
      onPreviousClick={!!previousAsset ? onPreviousClick : undefined}
    />
  );
});

PublicPreviousAndNextArrows.displayName = 'PublicPreviousAndNextArrows';
