import { useShortIdContext } from '@air/provider-short-id';
import { memo } from 'react';

import { VideoFrameRatesSelect } from '~/components/Filters/VideoFrameRateFilter/components/VideoFrameRatesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePublicVideoFrameRatesList } from '~/swr-hooks/filters/usePublicVideoFrameRatesList';

interface PublicVideoFrameRatesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicVideoFrameRatesSelect = memo(({ boardId, ...props }: PublicVideoFrameRatesSelectProps) => {
  const { shortId } = useShortIdContext();

  const { data = [], isLoading } = usePublicVideoFrameRatesList({
    shortId,
    boardId,
  });

  return <VideoFrameRatesSelect isLoading={isLoading} frameRates={data} {...props} />;
});

PublicVideoFrameRatesSelect.displayName = 'PublicVideoFrameRatesSelect';
