import { memo, useCallback } from 'react';

import { PublicCountriesSelect } from '~/components/Filters/CountryFilter/components/PublicCountriesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PublicCountriesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicCountryFilterCard = memo(({ boardId, ...props }: PublicCountriesFilterCardProps) => {
  const renderCountriesSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicCountriesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="countries"
      filterCardType="country"
      StringSelect={renderCountriesSelect}
      {...props}
    />
  );
});

PublicCountryFilterCard.displayName = 'PublicCountryFilterCard';
