import { useAnalyticsUtilities, useTrackViewedBoard } from '@air/analytics';
import { GetByShortIDBoardResponse, ScopedPermissions } from '@air/api';
import { ActionBarProvider } from '@air/provider-action-bar';
import { ModalProvider, useAirModal } from '@air/provider-modal';
import { ShortIdProvider, useShortIdContext } from '@air/provider-short-id';
import { useToasts } from '@air/provider-toast';
import { ErrorBoundary } from '@air/utils-error';
import { ConnectedRouter } from 'connected-next-router';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { AccountProvisionerProvider } from '~/components/AccountProvisioner/providers/AccountProvisionerProvider';
import { usePublicPageViewNotification } from '~/components/AirNotifications/hooks/usePublicPageViewNotification';
import { PublicFiltersReduxObserver } from '~/components/Filters/PublicFiltersReduxObserver';
import { usePublicBoardPage } from '~/components/Layouts/PublicBoardLayout/hooks/usePublicBoardPage';
import { MaintenanceMode } from '~/components/MaintenanceMode/MaintenanceMode';
import { PublicBoardReduxProvider } from '~/components/PublicBoardReduxProvider';
import { PublicBoardSocketContextProvider } from '~/components/PublicBoardSocketContextProvider';
import { PublicBoardAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicBoardAnonymousSignUpModal';
import { PublicPermissionsProvider } from '~/components/PublicPermissionsProvider';
import { usePublicBoardSSOCallback } from '~/components/PublicSSOCallback/usePublicBoardSSOCallback';
import { PublicWorkspaceProvider } from '~/components/PublicWorkspaceProvider';
import { useOnLocationChange } from '~/hooks/router/useOnLocationChange';
import { useOnMissingUserSession } from '~/hooks/useOnMissingUserSession';
import { ThumbnailPreferenceContextProvider } from '~/hooks/useThumbnailPreference';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLClipIdSelector } from '~/hooks/useURLClipIdSelector';
import { PublicAccountProvider, useAccountContext } from '~/providers/AccountProvider';
import { FiltersProvider, GetImmutableFilters } from '~/providers/FiltersProvider';
import { ModifiersKeysPressedProvider } from '~/providers/ModifiersKeysPressedProvider';
import { PublicBoardCustomFieldsProvider } from '~/providers/PublicBoardCustomFieldsProvider';
import { SearchHeaderHeightProvider } from '~/providers/SearchHeaderHeightProvider';
import { resetCentralizedBoardAction } from '~/store/centralizedBoard/actions';
import { resetConfigViewsAction, setSavedConfigurableViewsAction } from '~/store/configViews/actions';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { GlobalStyles } from '../GlobalStyles';
import { PublicBoardSEO } from './PublicBoardSEO';
import { ViewableBoard } from './ViewableBoard';

export type PublicBoardInnerProps = Pick<PublicBoardProps, 'boardId' | 'initialData'>;

const PublicBoardInner = ({ initialData, boardId: boardIdFromProps }: PublicBoardInnerProps) => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const urlBoardId = useURLBoardIdSelector();
  const boardId = urlBoardId || boardIdFromProps;
  const clipId = useURLClipIdSelector();
  const { shortId } = useShortIdContext();
  const { trackViewedBoard } = useTrackViewedBoard();
  const { setGlobalEventProps } = useAnalyticsUtilities();
  const { dismissAllToasts } = useToasts();

  const { data: account } = useAccountContext();

  usePublicBoardSSOCallback();

  const [showAnonymousSignupModal] = useAirModal(PublicBoardAnonymousSignUpModal);

  const { sendPublicPageViewEvent } = usePublicPageViewNotification();

  const requireAccount = initialData.requireAccount;

  useOnMissingUserSession({
    onMissingSession: () => showAnonymousSignupModal({}),
    enabled: requireAccount,
  });

  useEffect(() => {
    if (!requireAccount || (requireAccount && !!account)) {
      sendPublicPageViewEvent(shortId);
    }
  }, [account, requireAccount, sendPublicPageViewEvent, shortId]);

  const { data: board } = usePublicBoardPage({
    boardId,
    initialData: boardId === initialData.board.id ? { ...initialData.board, ancestors: [] } : undefined,
  });

  useEffect(() => {
    setGlobalEventProps({
      // workspace information whether the user is logged in or not
      workspaceId: initialData.workspace.id,
      workspaceName: initialData.workspace.name,
    });
  }, [setGlobalEventProps, initialData.workspace.id, initialData.workspace.name]);

  useEffect(() => {
    if (board?.id && !clipId) {
      trackViewedBoard({
        board_id: board.id,
        board_title: board.title,
        isDemo: board.isDemo,
        board_view: board.views?.find(({ isDefault }) => !!isDefault)?.viewType.name,
      });
    }
    // We only want to track when a user navigates to a new board (or on load)
    // so we want the id instead of the entire board object cause a board could
    // get modified in local cache (unlikely in a public board but better to be safe)
    // we watch clipId because we don't want to consider it a board view if they're
    // just looking at the asset modal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board?.id, clipId]);

  useEffect(() => {
    dispatch(resetConfigViewsAction());
  }, [boardId, dispatch]);

  useEffect(() => {
    const currentViewTypeName = currentViewTypeNameSelector(store.getState());
    if (board?.views) {
      const expectedViewTypeName = board.views.find((view) => view.isDefault)?.viewType.name;
      if (!currentViewTypeName || currentViewTypeName !== expectedViewTypeName) {
        dispatch(
          setSavedConfigurableViewsAction({
            savedConfigurableViews: board.views,
          }),
        );
      }
    }
  }, [dispatch, board?.views, store]);

  useOnLocationChange(dismissAllToasts);

  // this can't really happen on a public board URL cause where can the user go?
  // but just for safe measure and future-proofing
  useUnmount(() => {
    dispatch(resetCentralizedBoardAction());
  });

  return (
    <>
      {board && <PublicBoardSEO board={board} />}
      <ViewableBoard />
    </>
  );
};

export interface PublicBoardProps {
  initialData: {
    board: GetByShortIDBoardResponse['data']['board'];
    workspace: GetByShortIDBoardResponse['data']['workspace'];
    permissions: ScopedPermissions;
    requireAccount: boolean;
  };
  shortId: string;
  boardId: string;
}

export const PublicBoard = ({ initialData, shortId, boardId }: PublicBoardProps) => {
  const { asPath } = useRouter();

  const { name: workspaceName, image: workspaceImage, id: workspaceId } = initialData.workspace;

  const getImmutableFilters = useCallback<GetImmutableFilters>(
    () => ({
      boardId: getBoardIdFromPath(asPath),
    }),
    [asPath],
  );

  return (
    <AccountProvisionerProvider>
      <PublicAccountProvider>
        <PublicPermissionsProvider permissions={initialData.permissions}>
          <PublicBoardReduxProvider initialBoard={initialData.board}>
            <ShortIdProvider shortId={shortId} objectId={initialData.board.id} objectType="board">
              <PublicWorkspaceProvider
                workspaceId={workspaceId}
                workspaceImage={workspaceImage}
                workspaceName={workspaceName}
              >
                <GlobalStyles />
                <ErrorBoundary>
                  <ConnectedRouter>
                    {/*
            SearchHeaderHeightProvider should probably be a different instance than what's in private
            because the two designs/componetns should be separate since they don't depend on each other
          */}
                    <SearchHeaderHeightProvider>
                      <FiltersProvider getImmutableFilters={getImmutableFilters}>
                        <ModifiersKeysPressedProvider>
                          <ModalProvider>
                            <ThumbnailPreferenceContextProvider>
                              <MaintenanceMode>
                                <PublicBoardCustomFieldsProvider>
                                  <PublicBoardSocketContextProvider>
                                    <ActionBarProvider>
                                      <PublicBoardSEO board={initialData.board} />

                                      <PublicFiltersReduxObserver />
                                      <PublicBoardInner initialData={initialData} boardId={boardId} />
                                    </ActionBarProvider>
                                  </PublicBoardSocketContextProvider>
                                </PublicBoardCustomFieldsProvider>
                              </MaintenanceMode>
                            </ThumbnailPreferenceContextProvider>
                          </ModalProvider>
                        </ModifiersKeysPressedProvider>
                      </FiltersProvider>
                    </SearchHeaderHeightProvider>
                  </ConnectedRouter>
                </ErrorBoundary>
              </PublicWorkspaceProvider>
            </ShortIdProvider>
          </PublicBoardReduxProvider>
        </PublicPermissionsProvider>
      </PublicAccountProvider>
    </AccountProvisionerProvider>
  );
};
