import { BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { PublicBoardCustomFieldPlainText } from '~/components/PublicBoard/PublicBoardCustomFieldPlainText';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PublicBoardPanelCustomFieldContainer } from './PublicBoardPanelCustomFieldContainer';

export interface PublicBoardPanelCustomFieldPlainTextProps {
  field: BoardCustomFieldValue;
}

export const PublicBoardPanelCustomFieldPlainText = memo(({ field }: PublicBoardPanelCustomFieldPlainTextProps) => {
  const boardId = useURLBoardIdSelector();
  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeBoardCustomFields(permissions);

  if (!boardId) {
    return null;
  }

  return (
    <PublicBoardPanelCustomFieldContainer field={field}>
      <PublicBoardCustomFieldPlainText
        field={field}
        trackingLocation="board-side-panel"
        boardId={boardId}
        canEditCustomFields={canEditCustomFields}
      />
    </PublicBoardPanelCustomFieldContainer>
  );
});

PublicBoardPanelCustomFieldPlainText.displayName = 'PublicBoardPanelCustomFieldPlainText';
