import { PublicClip } from '@air/api';
import { ClipAndBoardListOptions, PublicBoard } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { GALLERY_MIXED_DATA } from '~/constants/react-query-keys';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useRegisterClipEvents } from '~/hooks/useRegisterClipEvents';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  currentViewTypeNameSelector,
} from '~/store/configViews/selectors';
import { usePublicTableViewBoardsDataFetcher } from '~/swr-hooks/gallery/tableView/usePublicTableViewBoardsDataFetcher';
import { useTableViewItems } from '~/swr-hooks/gallery/tableView/useTableViewItems';
import {
  publicTableViewClipsDataFetcher,
  publicTableViewDataFetcher,
  tableViewDataToUpdateMediaPlayer,
} from '~/swr-hooks/gallery/tableView/utils';
import { itemIsPublicAsset, UseGalleryMixedData } from '~/swr-hooks/gallery/types';
import { useCanShowBoards } from '~/swr-hooks/gallery/useCanShow';
import { usePublicMediaPlayerDataUpdate } from '~/swr-hooks/mediaPlayer/usePublicMediaPlayerData';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { getTypesToFetchFromParams } from '~/swr-hooks/search/utils';

export function usePublicTableViewItems(): UseGalleryMixedData<PublicBoard, PublicClip> {
  const { publicTableViewBoardsDataFetcher } = usePublicTableViewBoardsDataFetcher();
  const currentSortFieldName = useSelector(currentSortFieldNameSelector);
  const currentSortFieldDirection = useSelector(currentSortFieldDirectionSelector);
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const { shortId } = useShortIdContext();
  const { updatePublicMediaPlayerData } = usePublicMediaPlayerDataUpdate(shortId);
  const currentBoardId = useURLBoardIdSelector();
  const { typesParam } = useFilterParams();
  const { workspaceId } = usePublicWorkspace();

  const clipsFetchParams = useClipSearchParams();
  clipsFetchParams.types = getTypesToFetchFromParams(typesParam);
  const boardsFetchParams = useBoardSearchParams();

  const { canShowBoards } = useCanShowBoards();

  const fetchParams: Partial<ClipAndBoardListOptions> = {
    ...clipsFetchParams,
    ...boardsFetchParams,
    sortBy: undefined, // ensure we use the new sort fieldName and direction
  };

  const dataCacheKey = {
    ...fetchParams,
    ...typesParam,
    canShowBoards,
    currentSortFieldName,
    currentSortFieldDirection,
    shortId,
  };

  const fetcherParams = {
    params: fetchParams,
    sortFieldName: currentSortFieldName,
    sortDirection: currentSortFieldDirection,
  };

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [GALLERY_MIXED_DATA, dataCacheKey],
    queryFn: ({ pageParam }) =>
      canShowBoards
        ? typesParam.length === 1 && typesParam[0] === 'boards'
          ? publicTableViewBoardsDataFetcher({
              ...fetcherParams,
              shortId: shortId!,
              params: boardsFetchParams,
              parentId: currentBoardId!,
              cursor: pageParam || null,
            })
          : publicTableViewDataFetcher({ ...fetcherParams, shortId: shortId!, cursor: pageParam })
        : publicTableViewClipsDataFetcher({
            ...fetcherParams,
            parentId: currentBoardId!,
            shortId: shortId!,
            cursor: pageParam || null,
          }),
    enabled: !!currentViewTypeName,
    getNextPageParam: (prevPage) => prevPage.pagination.cursor,
    initialPageParam: '',
  });

  const itemsData = useTableViewItems<PublicBoard, PublicClip>({
    data: data?.pages ?? [],
    isLoading,
    hasMore: !!hasNextPage,
    isLoadingMore: isFetchingNextPage,
    loadMore: fetchNextPage,
  });

  useEffect(() => {
    updatePublicMediaPlayerData(tableViewDataToUpdateMediaPlayer(itemsData));
  }, [itemsData, updatePublicMediaPlayerData]);

  const clips = useMemo(() => itemsData.data.filter(itemIsPublicAsset).map((item) => item.item), [itemsData.data]);

  useRegisterClipEvents({
    clips,
    workspaceId,
  });

  return itemsData;
}
