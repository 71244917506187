import { AirActionTrackingLocation } from '@air/analytics';
import { AssetCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldPlainText,
  CustomFieldPlainTextProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldPlainText';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicAssetCustomFieldPlainTextProps extends Omit<CustomFieldPlainTextProps, 'onSubmit'> {
  field: AssetCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  assetId: string;
}

export const PublicAssetCustomFieldPlainText = memo(
  ({ field, trackingLocation, canEditCustomFields, assetId, ...rest }: PublicAssetCustomFieldPlainTextProps) => {
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const onSubmit = useCallback(
      async (value: string) => {
        if (!assetId) return;
        await updatePublicItemsCustomFieldValue({
          assetIds: [assetId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
        });
      },
      [assetId, updatePublicItemsCustomFieldValue, field, trackingLocation],
    );

    return <CustomFieldPlainText {...rest} onSubmit={canEditCustomFields ? onSubmit : undefined} field={field} />;
  },
);

PublicAssetCustomFieldPlainText.displayName = 'PublicAssetCustomFieldPlainText';
