import { BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { PublicBoardCustomFieldSelect } from '~/components/PublicBoard/PublicBoardCustomFieldSelect';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PublicBoardPanelCustomFieldContainer } from './PublicBoardPanelCustomFieldContainer';

export interface PublicBoardPanelCustomFieldSelectProps {
  field: BoardCustomFieldValue;
}

export const PublicBoardPanelCustomFieldSelect = memo(({ field }: PublicBoardPanelCustomFieldSelectProps) => {
  const boardId = useURLBoardIdSelector();
  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeBoardCustomFields(permissions);

  if (!boardId) {
    return null;
  }

  return (
    <PublicBoardPanelCustomFieldContainer field={field}>
      <PublicBoardCustomFieldSelect
        field={field}
        trackingLocation="board-side-panel"
        boardId={boardId}
        canEditCustomFields={canEditCustomFields}
      />
    </PublicBoardPanelCustomFieldContainer>
  );
});

PublicBoardPanelCustomFieldSelect.displayName = 'PublicBoardPanelCustomFieldSelect';
