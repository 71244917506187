import { ShortUrl } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useCallback } from 'react';

import { getPublicMediaPlayerKey } from '~/constants/react-query-keys';
import { usePublicClipMetadata } from '~/swr-hooks/clips/usePublicClipMetadata';
import { usePublicClip } from '~/swr-hooks/gallery/usePublicClip';
import {
  defaultMediaPlayerAssetInfo,
  MediaPlayerAssetsInfo,
  useBaseMediaPlayerData,
} from '~/swr-hooks/mediaPlayer/useMediaPlayerData';

export const usePublicMediaPlayerData = (shortId?: string) => {
  const queryClient = useQueryClient();

  const data = shortId
    ? queryClient.getQueryData<MediaPlayerAssetsInfo | undefined>(getPublicMediaPlayerKey(shortId))
    : defaultMediaPlayerAssetInfo;

  const { isNearEnd, nextId, previousId } = useBaseMediaPlayerData(data);

  const { data: previousClipData } = usePublicClip(shortId || '', previousId);
  const { data: nextClipData } = usePublicClip(shortId || '', nextId);

  // Load metadata for next and previous clips to have them ready
  usePublicClipMetadata({ clipId: previousId, shortId });
  usePublicClipMetadata({ clipId: nextId, shortId });

  return {
    nextAsset: nextClipData?.clip,
    previousAsset: previousClipData?.clip,
    loadMore: data?.loadMore ?? noop,
    isNearEnd,
  };
};

export const usePublicMediaPlayerDataUpdate = (shortId?: ShortUrl['id']) => {
  const queryClient = useQueryClient();

  const updatePublicMediaPlayerData = useCallback(
    (data: MediaPlayerAssetsInfo) => {
      if (shortId) {
        queryClient.setQueryData(getPublicMediaPlayerKey(shortId), data);
      }
    },
    [queryClient, shortId],
  );

  return {
    updatePublicMediaPlayerData,
  };
};
