import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicCameraMakesList } from '~/swr-hooks/filters/usePublicCameraMakesList';

interface PublicCameraMakeSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicCameraMakeSelect = memo(({ boardId, ...props }: PublicCameraMakeSelectProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { shortId } = useShortIdContext();

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicCameraMakesList({
    search: debouncedSearchValue,
    boardId,
    shortId,
  });

  return (
    <StringSelect
      data-testid="CAMERA_MAKE_SELECT"
      placeholder="Search for make"
      closeMenuOnSelect
      isSingleSelect={true}
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PublicCameraMakeSelect.displayName = 'PublicCameraMakeSelect';
