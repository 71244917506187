import { Permissions, ShortUrl } from '@air/api';
import { ShortUrlObjectType } from '@air/api/types';
import { matchesAirror, MISSING_OR_INVALID_SHORT_URL_TOKEN } from '@air/errors';
import { isArray } from 'lodash';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { PublicBoard, PublicBoardProps } from '~/components/Layouts/PublicBoardLayout/PublicBoard';
import { PublicClip, PublicClipProps } from '~/components/Layouts/PublicClipLayout/PublicClip';
import { NotFound } from '~/components/NotFound/NotFound';
import { PasswordPage } from '~/components/PasswordPage/PasswordPage';
import { createShortUrlBoardRoute, createShortUrlClipInBoardRoute } from '~/constants/routes';
import { fetchShortBoardData } from '~/swr-hooks/shortUrl/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath, getClipIdFromPath } from '~/utils/PathUtils';

type PasscodeRequiredProps = {
  shortId: string;
  passcodeRequired: true;
  boardId: string | null;
  clipId: string | null;
};

type NotFoundProps = {
  shortId: string | undefined;
  notFound: true;
  passcodeRequired?: false;
};

type ShortUrlPageProps = PublicBoardProps | PublicClipProps | PasscodeRequiredProps | NotFoundProps;

const isPasscodeProtected = (props: ShortUrlPageProps): props is PasscodeRequiredProps =>
  !!(props as PasscodeRequiredProps).passcodeRequired;
const isBoardRoute = (props: ShortUrlPageProps): props is PublicBoardProps => !!props.shortId?.startsWith('b');
const isClipRoute = (props: ShortUrlPageProps): props is PublicClipProps => !!props.shortId?.startsWith('c');

/** staticProps are props that come in once from the values from getStaticProps down below. They never change after the initial render */
export default function ShortUrlPage(staticProps: ShortUrlPageProps) {
  const { replace, query } = useRouter();
  const [verified, setVerified] = useState(!isPasscodeProtected(staticProps));
  const [initialBoardData, setInitialBoardData] = useState<PublicBoardProps['initialData'] | undefined>(
    isBoardRoute(staticProps) ? staticProps.initialData : undefined,
  );
  const [initialClipData, setInitialClipData] = useState<PublicClipProps['initialData'] | undefined>(
    isClipRoute(staticProps) ? staticProps.initialData : undefined,
  );

  const onVerified = useCallback(async () => {
    if (isPasscodeProtected(staticProps)) {
      const shortId = staticProps.shortId;

      const res = await ShortUrl.getByShortId(shortId);

      switch (res.type) {
        case ShortUrlObjectType.board:
          {
            if (isArray(query.slug)) {
              const path = query.slug.join('/');

              // boardId will be empty if user opened /a/${shortId}
              const boardId = getBoardIdFromPath(path) || res.data.board.id;
              const clipId = getClipIdFromPath(path);

              const { board, workspace, permissions, requireAccount } = await fetchShortBoardData(shortId, boardId);

              if (clipId) {
                await replace(createShortUrlClipInBoardRoute(shortId, boardId, clipId));
              } else {
                await replace(createShortUrlBoardRoute(shortId, boardId));
              }
              setInitialBoardData({ board, workspace, permissions, requireAccount });
            } else {
              const boardId = res.data.board.id;
              const { board, workspace, permissions, requireAccount } = await fetchShortBoardData(shortId, boardId);
              await replace(createShortUrlBoardRoute(shortId, boardId));

              setInitialBoardData({ board, workspace, permissions, requireAccount });
            }

            setVerified(true);
          }
          break;

        case ShortUrlObjectType.clip:
          {
            const permissions = await Permissions.getFromObjects({ shortId, objects: { clipIds: [res.data.id] } });
            setInitialClipData({
              clip: res.data,
              permissions,
              requireAccount: res.requireAccount,
            });
            setVerified(true);
          }
          break;

        default:
          throw new Error('Unexpected ShortUrlObject type');
      }
    }
  }, [query.slug, replace, staticProps]);

  if (isPasscodeProtected(staticProps) && !verified) {
    /**
     * After the user verifies the password, the verified state change causes a re-render here
     * and the shortID is passed into the corresponding layouts below where their data is loaded client side
     */
    return <PasswordPage shortId={staticProps.shortId} onVerified={onVerified} />;
  } else if (isBoardRoute(staticProps) && initialBoardData) {
    return <PublicBoard {...staticProps} initialData={initialBoardData} />;
  } else if (isClipRoute(staticProps) && initialClipData) {
    return <PublicClip {...staticProps} initialData={initialClipData} />;
  } else {
    return <NotFound />;
  }
}

export const getStaticProps: GetStaticProps<ShortUrlPageProps, { slug?: string[] }> = async ({ params }) => {
  /** If there is no slug param, it's an invalid path. */
  if (!params?.slug) {
    const props: NotFoundProps = {
      shortId: undefined,
      notFound: true,
    };

    return {
      revalidate: 1,
      props,
    };
  }

  const [shortId] = params.slug;
  const path = params.slug.join('/');
  const boardId = getBoardIdFromPath(path) || null; // to fix `undefined` cannot be serialized as JSON error
  const clipId = getClipIdFromPath(path) || null; // to fix `undefined` cannot be serialized as JSON error

  /** If there is no shortId, it's an invalid path */
  if (!shortId) {
    const props: NotFoundProps = {
      shortId: undefined,
      notFound: true,
    };

    return {
      revalidate: 1,
      props,
    };
  }

  /**
   * If there is a boardId in the URL at request time, we know it's a board.
   * TODO: handle if there is also a clipId in the url in addition to the boardId
   * */
  if (boardId) {
    try {
      const { board, workspace, permissions, requireAccount } = await fetchShortBoardData(shortId, boardId);

      const props: PublicBoardProps = {
        shortId,
        boardId,
        initialData: { board, workspace, permissions, requireAccount },
      };

      return {
        props,
        revalidate: 1,
      };
    } catch (error) {
      /**
       * If we can't fetch board data cause they don't have the short url passcode token
       */
      if (matchesAirror(error, MISSING_OR_INVALID_SHORT_URL_TOKEN)) {
        const props: PasscodeRequiredProps = {
          shortId,
          boardId,
          clipId,
          passcodeRequired: true,
        };

        return {
          revalidate: 1,
          props,
        };
      } else {
        reportErrorToBugsnag({
          error,
          context: 'Uncaught error during ISR of share route.',
          metadata: { data: { shortId } },
        });

        const props: NotFoundProps = {
          shortId,
          notFound: true,
        };

        return {
          props,
          /**
           * This is really important because this forces 404 pages on share links to revalidate in the background.
           * There was a nice disaster created by this prop not being here.
           * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1678113628505779
           */
          revalidate: 1,
        };
      }
    }
  } else {
    try {
      /** If the shortId is the only thing in the url..  */
      const res = await ShortUrl.getByShortId(shortId);

      switch (res.type) {
        /**
         * If it's a shortId for a board, redirect them to the full board path.
         * This will just redirect to the if statement above where we're looking for boardId
         */
        case ShortUrlObjectType.board:
          return {
            revalidate: 1,
            redirect: {
              destination: createShortUrlBoardRoute(shortId, res.data.board.id),
              permanent: true,
            },
          };

        case ShortUrlObjectType.clip: {
          const permissions = await Permissions.getFromObjects({ shortId, objects: { clipIds: [res.data.id] } });
          const props: PublicClipProps = {
            shortId,
            initialData: {
              clip: res.data,
              permissions,
              requireAccount: res.requireAccount,
            },
          };

          return { props, revalidate: 1 };
        }

        default:
          throw new Error('Unexpected ShortUrlObject type');
      }
    } catch (error) {
      /**
       * If we can't fetch board data cause they don't have the short url passcode token
       */
      if (matchesAirror(error, MISSING_OR_INVALID_SHORT_URL_TOKEN)) {
        const props: PasscodeRequiredProps = {
          shortId,
          clipId,
          boardId,
          passcodeRequired: true,
        };

        return {
          revalidate: 1,
          props,
        };
      } else {
        reportErrorToBugsnag({
          error,
          context: 'Uncaught error during ISR of share route.',
          metadata: { data: { shortId } },
        });

        const props: NotFoundProps = {
          shortId,
          notFound: true,
        };

        return {
          props,
          /**
           * This is really important because this forces 404 pages on share links to revalidate in the background.
           * There was a nice disaster created by this prop not being here.
           * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1678113628505779
           */
          revalidate: 1,
        };
      }
    }
  }
};

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [], // We don't want to statically pre-render path. Only the <head> element for the page on request.
  fallback: 'blocking', // https://nextjs.org/docs/basic-features/data-fetching#fallback-blocking
});
