import { memo } from 'react';

import {
  BaseCustomFieldFilterCard,
  BaseCustomFieldFilterCardProps,
} from '~/components/Filters/CustomFieldFilter/BaseCustomFieldFilterCard';
import { CustomFieldFilterType } from '~/components/FiltersMenu/types';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';

export interface PublicCustomFieldFilterCardProps extends Omit<BaseCustomFieldFilterCardProps, 'values'> {
  customField: CustomFieldFilterType;
}

export const PublicCustomFieldFilterCard = memo(({ customField, ...props }: PublicCustomFieldFilterCardProps) => {
  const { data } = useGetPublicCustomFieldOptions({
    customFieldId: customField?.id,
  });

  return <BaseCustomFieldFilterCard values={data?.data ?? []} customField={customField} {...props} />;
});

PublicCustomFieldFilterCard.displayName = 'CustomFieldFilterCard';
