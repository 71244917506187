import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { HTMLAttributes, ReactNode } from 'react';

import { SEARCH_TOKEN_CLOSE_BTN } from '~/constants/testIDs';
import { preventDefaultMouseEvent } from '~/utils/ClickUtils';

const searchTokenVariants = tailwindVariants({
  base: 'flex max-w-[120px] items-center rounded-md py-0.5 pl-2.5 pr-1',
  variants: {
    color: {
      grey: 'bg-grey-4 text-grey-11',
      blue: 'bg-blue-1 text-blue-11',
    },
  },
});

type SearchTokenVariant = VariantProps<typeof searchTokenVariants>['color'];

interface SearchTokenProps extends Pick<HTMLAttributes<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'> {
  tokenName?: string;
  onCloseClick?: () => void;
  color: SearchTokenVariant;
  icon?: ReactNode;
  tooltipText?: string;
  testId?: string;
}

export const SearchToken = ({
  icon,
  tokenName,
  onCloseClick,
  color,
  tooltipText,
  onMouseLeave,
  onMouseEnter,
  testId,
}: SearchTokenProps) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    data-testid={testId}
    className={searchTokenVariants({ color })}
  >
    {icon}
    {!!tokenName && (
      <Tooltip label={tooltipText || tokenName} side="top">
        <p className="mr-1 truncate text-14 font-medium">{tokenName}</p>
      </Tooltip>
    )}

    {!!onCloseClick && (
      <IconButton
        label="Remove from search"
        data-testid={SEARCH_TOKEN_CLOSE_BTN}
        icon={Close}
        onMouseDown={preventDefaultMouseEvent}
        onClick={onCloseClick}
        appearance={color === 'grey' ? 'filled' : 'ghost'}
        color={color === 'grey' ? 'grey' : 'blue'}
        size="extra-small"
        className="!h-3 !w-3"
      />
    )}
  </div>
);
