import { AssetCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { PanelCustomFieldContainer, PanelCustomFieldContainerProps } from './PanelCustomFieldContainer';

export type PublicAssetPanelCustomFieldContainerProps = Omit<
  PanelCustomFieldContainerProps<AssetCustomFieldValue>,
  'canDeleteCustomFields' | 'canEditCustomFields' | 'onClick'
>;

export const PublicAssetPanelCustomFieldContainer = memo((params: PublicAssetPanelCustomFieldContainerProps) => {
  return <PanelCustomFieldContainer canDeleteCustomFields={false} canEditCustomFields={false} {...params} />;
});

PublicAssetPanelCustomFieldContainer.displayName = 'PublicAssetPanelCustomFieldContainer';
