import { PublicClip } from '@air/api';
import { memo, ReactNode, useMemo } from 'react';
import { Provider } from 'react-redux';

import { makeStore } from '~/store';

export interface PublicAssetReduxProviderProps {
  children: ReactNode;
  initialClip: PublicClip;
}

export const PublicAssetReduxProvider = memo(({ children, initialClip }: PublicAssetReduxProviderProps) => {
  const { store } = useMemo(() => {
    const { store } = makeStore({
      centralizedClip: {
        clip: {
          ...initialClip,
          boards: [],
        },
        loading: false,
      },
    });

    return { store };
  }, [initialClip]);

  return <Provider store={store}>{children}</Provider>;
});

PublicAssetReduxProvider.displayName = 'PublicAssetReduxProvider';
