import { Modal } from '@air/primitive-modal';
import { memo, PropsWithChildren } from 'react';

import { AnonymousSignUpOverlay } from '~/components/PublicLinkAnonymousSignUpModal/AnonymousSignUpOverlay';

interface AnonymousSignUpModalProps {
  ['data-testid']?: string;
  isOpen: boolean;
}

export const AnonymousSignUpModal = memo(
  ({ 'data-testid': testId, children, isOpen }: PropsWithChildren<AnonymousSignUpModalProps>) => {
    return (
      <>
        <AnonymousSignUpOverlay />
        <Modal data-testid={testId} overlayClassName="bg-transparent" isOpen={isOpen} className="p-0">
          {children}
        </Modal>
      </>
    );
  },
);

AnonymousSignUpModal.displayName = 'AnonymousSignUpModal';
