import { Comment, Stack } from '@air/next-icons';
import { memo, useCallback, useMemo } from 'react';

import { PublicAssetMobileNavigationEllipsisMenu } from '~/_app/(public-asset)/_components/PublicAssetMobileNavigationEllipsisMenu';
import { MobileAssetModalNavigation } from '~/_components/MobileAssetModalNavigation';
import { MobileAssetModalNavigationItem } from '~/_components/MobileAssetModalNavigationItem';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicMobileAssetModalNavigation = memo(() => {
  const { panel, togglePanel } = useAssetModalPanelContext();
  const { permissions } = usePublicPermissions();

  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canViewAllVersions = canSeeAssetVersions(permissions);

  const navigationItems = useMemo(() => {
    const navigationItemsArray = [];

    if (canViewDiscussions) {
      navigationItemsArray.push({
        id: AssetModalPanel.COMMENTS,
        icon: Comment,
        label: 'Comments',
        order: 2,
      });
    }

    if (canViewAllVersions) {
      navigationItemsArray.push({
        id: AssetModalPanel.VERSIONS,
        icon: Stack,
        label: 'Version',
        order: 7,
      });
    }

    return navigationItemsArray;
  }, [canViewAllVersions, canViewDiscussions]);

  const onClick = useCallback(
    (panel: AssetModalPanel) => {
      togglePanel(panel);

      // @TODO - Future change
      // Scroll to position when panel is changed
      // if (panel === AssetModalPanel.COMMENTS) {
      //   scrollToBottom();
      // } else {
      //   scrollToPanel();
      // }
    },
    [togglePanel],
  );

  return (
    <MobileAssetModalNavigation>
      {navigationItems.map((item) => {
        const isActive =
          panel === item.id ||
          // @TODO - Remove once new UI is fully implemented
          (item.id === AssetModalPanel.INFO && (!panel || panel === AssetModalPanel.NONE));

        return (
          <MobileAssetModalNavigationItem
            icon={item.icon}
            id={item.id}
            isActive={isActive}
            label={item.label}
            key={item.id}
            onClick={() => onClick(item.id)}
            order={item.order}
          />
        );
      })}

      <PublicAssetMobileNavigationEllipsisMenu />
    </MobileAssetModalNavigation>
  );
});

PublicMobileAssetModalNavigation.displayName = 'PublicMobileAssetModalNavigation';
