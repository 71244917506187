import { useTrackViewedDiscussion } from '@air/analytics';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { MobileAssetModalPanel } from '~/_components/MobileAssetModalPanel';
import { MobileAssetModalPanelHeader } from '~/_components/MobileAssetModalPanelHeader';
import { PublicDiscussionsForm } from '~/components/PublicAssetModal/components/PublicDiscussionsForm';
import { PublicDiscussionsList } from '~/components/PublicAssetModal/components/PublicDiscussionsList';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { canEditAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicAssetMobileCommentsPanel = memo(() => {
  const { permissions } = usePublicPermissions();
  const { trackViewedDiscussion } = useTrackViewedDiscussion();
  const boardId = useURLBoardIdSelector();
  const clipId = useSelector(centralizedClipIdSelector);
  const canEditDiscussions = canEditAssetDiscussions(permissions);

  useMount(() => {
    if (clipId) {
      trackViewedDiscussion({
        boardId,
        clipId,
        location: 'asset-page',
      });
    }
  });

  return (
    <MobileAssetModalPanel>
      <MobileAssetModalPanelHeader title="Comments" />
      <PublicDiscussionsList />

      {canEditDiscussions && (
        <div className="border-t border-t-grey-5 p-6">
          <PublicDiscussionsForm />
        </div>
      )}
    </MobileAssetModalPanel>
  );
});

PublicAssetMobileCommentsPanel.displayName = 'PublicAssetMobileCommentsPanel';
