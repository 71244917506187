import { Assets } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { getVersionsListKey } from '~/constants/react-query-keys';
import { centralizedClipAssetIdSelector } from '~/store/centralizedClip/selectors';
import { canSeeAssetVersions } from '~/utils/permissions/assetPermissions';
import { filterOutNullVersions } from '~/utils/VersionUtils';

export const usePublicVersionsList = () => {
  const { shortId } = useShortIdContext();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { permissions } = usePublicPermissions();
  const canViewAssetVersions = canSeeAssetVersions(permissions);

  return useQuery({
    queryKey: getVersionsListKey({ assetId, shortId }),

    queryFn: async () => {
      const versions = await Assets.listVersions({
        assetId,
        shortId,
        limit: 500,
      });

      /** There is a bug in backend that returns versions with status "created" and "version: null"
       * @see https://air-labs-team.slack.com/archives/CCS4C8ALT/p1701368808930219
       */
      return filterOutNullVersions(versions);
    },
    enabled: canViewAssetVersions && !!assetId,
  });
};
