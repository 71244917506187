import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

export const getPublicClipMetadata = ({ clipId, shortId }: { clipId: string; shortId: string | undefined }) => [
  'PUBLIC_CLIP_METADATA',
  { clipId, shortId },
];

export const usePublicClipMetadata = ({ clipId, shortId }: { clipId: string; shortId: string | undefined }) => {
  return useQuery({
    queryKey: getPublicClipMetadata({ shortId, clipId }),

    queryFn: () => {
      invariant(shortId, 'shortId is required for usePublicClipMetadata');

      // This request is paginated, but there are only 55 supported fields (state for 2/23/24);
      // Asset modal presents some fields on info panel - with pagination we may not have them in the first page
      return ShortUrl.listClipMetadata({
        clipId,
        shortId,
        limit: 100,
      });
    },
    staleTime: Infinity,
    enabled: Boolean(clipId && shortId),
  });
};
