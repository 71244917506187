import { Board } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicCopyrightsList } from '~/swr-hooks/filters/usePublicCopyrightList';

interface PublicCopyrightSelectProps extends BaseStringSelectProps {
  boardId: Board['id'] | undefined;
}

export const PublicCopyrightSelect = memo(({ boardId, ...props }: PublicCopyrightSelectProps) => {
  const { shortId } = useShortIdContext();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicCopyrightsList({
    search: debouncedSearchValue,
    shortId,
    boardId,
  });

  return (
    <StringSelect
      placeholder="Search for copyright"
      isLoading={isLoading}
      values={data}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PublicCopyrightSelect.displayName = 'PublicCopyrightSelect';
