import { ShortUrl } from '@air/api';
import { BoardsListOptions, SortDirection, SortField } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  getPublicBoardPageKey,
  PublicBoardPage,
} from '~/components/Layouts/PublicBoardLayout/hooks/usePublicBoardPage';
import { PageLimits } from '~/constants/PageLimits';

export const usePublicBoardsFetcher = () => {
  const queryClient = useQueryClient();

  const publicBoardsFetcher = useCallback(
    async ({
      params,
      sortFieldName,
      sortDirection,
      parentId,
      shortId,
      cursor,
    }: {
      shortId: string;
      parentId: string;
      params: Partial<BoardsListOptions>;
      sortFieldName: SortField['name'];
      sortDirection: SortDirection;
      cursor: string | null;
    }) => {
      const res = await ShortUrl.listChildrenBoardsByShortId(shortId, parentId, {
        ...params,
        limit: !cursor ? PageLimits.subBoards.init : PageLimits.subBoards.more,
        cursor,
        sortField: {
          direction: sortDirection,
          name: sortFieldName,
        },
        numThumbnails: 1,
        includeAncestors: true,
      });

      res.data.forEach((board) => {
        const cachedBoard = queryClient.getQueryData<PublicBoardPage>(
          getPublicBoardPageKey({
            shortId,
            boardId: board.id,
          }),
        );

        if (!cachedBoard) {
          queryClient.setQueryData<PublicBoardPage>(
            getPublicBoardPageKey({
              shortId,
              boardId: board.id,
            }),
            board,
          );
        }
      });

      return res;
    },
    [queryClient],
  );

  return {
    publicBoardsFetcher,
  };
};
