import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { usePublicBoardCustomFieldsData } from '~/swr-hooks/customFields/usePublicBoardCustomFieldsData';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export const useCanOpenPublicCustomFieldsPanel = () => {
  const { data: customFieldsResponse } = usePublicBoardCustomFieldsData();
  const { permissions } = usePublicPermissions();
  const canEditCustomFieldsBoard = canChangeBoardCustomFields(permissions);
  const canOpenCustomFieldsPanel = canEditCustomFieldsBoard && !!customFieldsResponse?.data.length;

  return { canOpenCustomFieldsPanel };
};
