import { ShortUrl } from '@air/api';
import { Board } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

import { getPublicBoardStatsKey } from '~/constants/react-query-keys';

export type UseGetPublicBoardStatsParams = {
  boardId: Board['id'];
  includeDescendants?: boolean;
};

export const useGetPublicBoardStats = ({ boardId, includeDescendants = false }: UseGetPublicBoardStatsParams) => {
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getPublicBoardStatsKey({ shortId, boardId, includeDescendants }),
    queryFn: () => ShortUrl.getStatsByShortId(shortId, boardId, { includeDescendants }),
    enabled: !!boardId,
  });
};
