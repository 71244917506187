import { memo, useCallback } from 'react';

import { PublicAudioSampleRatesSelect } from '~/components/Filters/AudioSampleRateFilter/components/PublicAudioSampleRatesSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard, StringArrayFilterCardProps } from '~/components/Filters/StringArrayFilterCard';

interface PublicAudioSampleRateFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicAudioSampleRateFilterCard = memo(({ boardId, ...props }: PublicAudioSampleRateFilterCardProps) => {
  const renderAudioSampleRatesSelect = useCallback<StringArrayFilterCardProps['StringSelect']>(
    (props) => <PublicAudioSampleRatesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="audioSampleRates"
      filterCardType="audioSampleRate"
      StringSelect={renderAudioSampleRatesSelect}
      {...props}
    />
  );
});

PublicAudioSampleRateFilterCard.displayName = 'PublicAudioSampleRateFilterCard';
