import { tailwindMerge, tailwindVariants, VariantProps } from '@air/tailwind-variants';

const workspaceAvatarContainerVariants = tailwindVariants({
  base: 'relative mr-3 h-8 min-w-[32px] overflow-hidden rounded',
  variants: {
    size: {
      medium: 'mr-3 h-8 w-8 min-w-[32px]',
      large: 'mr-4 h-10 w-10 min-w-[40px]',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

const workspaceAvatarVariants = tailwindVariants({
  base: 'h-full object-cover',
  variants: {
    size: {
      medium: 'w-8',
      large: 'w-10',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

const workspaceAvatarNameVariants = tailwindVariants({
  base: 'truncate font-medium text-grey-10',
  variants: {
    size: {
      medium: 'text-16',
      large: 'text-16',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type WorkspaceAvatarAndNameVariants = VariantProps<typeof workspaceAvatarContainerVariants>;

export interface WorkspaceAvatarAndNameProps {
  image: string;
  name: string;

  /**
   * @default medium
   */
  size?: WorkspaceAvatarAndNameVariants['size'];
  className?: string;
}

export const WorkspaceAvatarAndName = ({ name, image, size = 'medium', className }: WorkspaceAvatarAndNameProps) => {
  return (
    <div className={tailwindMerge('flex items-center', className)} data-testid="WORKSPACE_AVATAR_AND_NAME">
      <div className={workspaceAvatarContainerVariants({ size })} data-testid="WORKSPACE_AVATAR">
        <img alt={`Logo for ${name}`} className={workspaceAvatarVariants({ size })} src={image} />
      </div>
      <div className={workspaceAvatarNameVariants({ size })} data-testid="WORKSPACE_NAME">
        {name}
      </div>
    </div>
  );
};
