import { memo, useCallback } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PublicStatesSelect } from '~/components/Filters/StateFilter/components/PublicStatesSelect';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

interface PublicCitiesFilterCardProps extends BaseFilterCardProps {
  boardId: string | undefined;
}

export const PublicStateFilterCard = memo(({ boardId, ...props }: PublicCitiesFilterCardProps) => {
  const renderStatesSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicStatesSelect boardId={boardId} {...props} />,
    [boardId],
  );

  return <StringArrayFilterCard filter="states" filterCardType="state" StringSelect={renderStatesSelect} {...props} />;
});

PublicStateFilterCard.displayName = 'PublicStateFilterCard';
