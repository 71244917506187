import { memo, ReactNode } from 'react';

interface GalleryCardFooterProps {
  children: ReactNode;
}

export const GalleryCardFooter = memo(({ children }: GalleryCardFooterProps) => (
  <div className="flex grow items-end">
    <div className="flex grow">
      <div className="mx-3 mb-3.5">{children}</div>
    </div>
  </div>
));

GalleryCardFooter.displayName = 'GalleryCardFooter';
