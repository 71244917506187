import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { getAssetCustomFieldsKey } from '~/constants/react-query-keys';
import { centralizedClipAssetIdSelector } from '~/store/centralizedClip/selectors';
import { canSeeAssetCustomFields } from '~/utils/permissions/assetPermissions';

export const usePublicAssetCustomFieldsData = () => {
  const { shortId } = useShortIdContext();
  const { permissions } = usePublicPermissions();
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const assetId = useSelector(centralizedClipAssetIdSelector);

  return useQuery({
    queryKey: getAssetCustomFieldsKey(assetId),
    queryFn: () => ShortUrl.listAssetCustomFields(shortId, assetId!, { limit: 100 }),
    enabled: !!assetId && canViewCustomFields,
  });
};
