import { ShortUrl } from '@air/api';
import { TaskType as RemoteTaskType } from '@air/api/types';
import { useCallback } from 'react';

import { TaskType as LocalTaskType } from '~/store/tasks/types';

import { SharedSyncTasksParams, useSharedSyncTasks } from './useSharedSyncTasks';

interface PublicSyncTasksParams<LocalType extends LocalTaskType, RemoteType extends RemoteTaskType>
  extends Omit<SharedSyncTasksParams<LocalType, RemoteType>, 'getTask'> {
  shortId: string;
}

export const usePublicSyncTasks = <
  LocalType extends LocalTaskType = LocalTaskType,
  RemoteType extends RemoteTaskType = RemoteTaskType,
>({
  shortId,
  ...rest
}: PublicSyncTasksParams<LocalType, RemoteType>) => {
  const getTask = useCallback(
    (taskId: string) => {
      return ShortUrl.Tasks.getTask(shortId, { taskId });
    },
    [shortId],
  );

  return useSharedSyncTasks({
    ...rest,
    getTask,
  });
};
