import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AssetMobileVersions, AssetMobileVersionsProps } from '~/_components/AssetMobileVersions';
import { QueryParamNames } from '~/constants/search';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { usePublicVersionsList } from '~/swr-hooks/versions/usePublicVersionsList';

export const PublicAssetMobileVersion = memo(() => {
  const { data: versions } = usePublicVersionsList();
  const clipId = useSelector(centralizedClipIdSelector);
  const { goToAssetPage } = useGoToAssetPage();

  const onVersionClick = useCallback<Required<AssetMobileVersionsProps>['onVersionClick']>(
    (version) => {
      if (version.id === clipId) {
        goToAssetPage({
          asset: version,
          query: {
            [QueryParamNames.tab]: null,
            [QueryParamNames.timestamp]: null,
            [QueryParamNames.discussionId]: null,
          },
        });
      }
    },
    [clipId, goToAssetPage],
  );

  return (
    <div className="min-h-full w-full">
      <AssetMobileVersions
        isActiveVersion={(version) => version.id === clipId}
        onVersionClick={onVersionClick}
        versions={versions?.data.clips}
      />
    </div>
  );
});

PublicAssetMobileVersion.displayName = 'PublicAssetMobileVersion';
