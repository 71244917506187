import { useSelector } from 'react-redux';

import { SearchResultsNumber } from '~/components/SearchResultsNumber';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { usePublicSearchTotals } from '~/swr-hooks/search/usePublicSearchTotals';

export const PublicSearchResultsNumber = () => {
  const boardName = useSelector(centralizedBoardTitleSelector);

  const { total } = usePublicSearchTotals({ trackLocation: 'board' });

  return total ? <SearchResultsNumber total={total} location={boardName} /> : null;
};
