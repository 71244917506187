import { Link } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';

import { SharePublicBoardModal } from '~/components/Modals/SharePublicBoardModal';
import { SHARE_BUTTON } from '~/constants/testIDs';
import { getPublicBoardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export const PublicBoardMobileShareButton = memo(() => {
  const [showSharePublicBoardModal] = useAirModal(SharePublicBoardModal);
  const { shortId } = useShortIdContext();

  const onShareClick = useCallback(() => {
    const boardId = getBoardIdFromPath(window.location.pathname);
    if (!boardId) return;

    showSharePublicBoardModal({
      id: boardId,
      url: getPublicBoardShareUrl(shortId),
      trackLocation: 'mobile-bottom-nav',
    });
  }, [shortId, showSharePublicBoardModal]);

  const label = 'Share board';

  return (
    <Tooltip label={label}>
      <IconButton
        appearance="ghost"
        color="grey"
        data-testid={SHARE_BUTTON}
        icon={Link}
        label={label}
        onClick={onShareClick}
        size="large"
      />
    </Tooltip>
  );
});

PublicBoardMobileShareButton.displayName = 'PublicBoardMobileShareButton';
