import { useEffect } from 'react';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { useCreateComment } from '~/swr-hooks/discussions/useCreateComment';
import { useSubmitNewDiscussion } from '~/swr-hooks/discussions/useSubmitNewDiscussion';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';
import { useAirSelector } from '~/utils/ReduxUtils';

import { clearSSOCallbackData, getSSOCallbackData } from './utils';

/**
 * Use this hook to continue what user tried to do before SSO login
 */
export const usePublicSSOAssetCallback = () => {
  const centralizedClipId = useAirSelector(centralizedClipIdSelector);

  const { copyToAir } = useCopyToWorkspace();
  const { submitNewDiscussion } = useSubmitNewDiscussion();
  const { createComment } = useCreateComment();
  const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

  useEffect(() => {
    if (!centralizedClipId) {
      return;
    }

    const ssoCallbackData = getSSOCallbackData();
    if (ssoCallbackData) {
      switch (ssoCallbackData.action) {
        case 'copy-to-air':
          if (ssoCallbackData.data) {
            copyToAir(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
        case 'create-public-discussion':
          if (ssoCallbackData.data) {
            submitNewDiscussion(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
        case 'reply-to-public-comment':
          if (ssoCallbackData.data) {
            createComment(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
        case 'edit-custom-field-value':
          if (ssoCallbackData.data) {
            updateItemsCustomFieldValue(ssoCallbackData.data);
          }
          clearSSOCallbackData();
          break;
      }
    }
  }, [centralizedClipId, copyToAir, createComment, submitNewDiscussion, updateItemsCustomFieldValue]);
};
