import { memo } from 'react';

import { NonContributableEmptyState } from '~/components/NonContributableNullState/NonContributableNullState';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';

import { PublicBoardNoSearchResults } from './PublicBoardNoSearchResults';

export const PublicBoardNullState = memo(() => {
  const { isSearchActive } = useIsSearchActive();

  if (isSearchActive) return <PublicBoardNoSearchResults />;

  return <NonContributableEmptyState />;
});

PublicBoardNullState.displayName = 'PublicBoardNullState';
