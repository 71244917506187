import { Air } from '@air/next-icons';
import { memo } from 'react';

export const PoweredByAir = memo(() => (
  <div className="fixed bottom-4 flex w-full  items-center justify-center">
    <p className="text-16 text-pigeon-100">Powered by</p>
    <Air width={43} className="ml-1 text-blue-9" />
  </div>
));

PoweredByAir.displayName = 'PoweredByAir';
