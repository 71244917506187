import { ShortUrl } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicCameraModelsListKey = ({
  shortId,
  search,
  boardId,
  make,
}: {
  shortId: string;
  search: string;
  boardId: string | undefined;
  make: string | undefined;
}) => {
  return ['PUBLIC_CAMERA_MODELS', { shortId, search, boardId, make }];
};

export const usePublicCameraModelsList = ({
  search,
  shortId,
  boardId,
  make,
}: {
  search: string;
  shortId: string;
  boardId: string | undefined;
  make: string | undefined;
}) => {
  const key = getPublicCameraModelsListKey({ shortId, search, boardId, make });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 20,
        shortId,
        field: 'camera.model',
        camera: make ? { make } : undefined,
        descendantBoardId: boardId,
        search,
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!shortId,
  });
};
