import { Account } from '@air/api/types';
import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';
import { formatFullName } from '~/utils/formatFullName';

interface PublicTopBarAccountInfoProps {
  account: Pick<Account, 'email' | 'firstName' | 'lastName'>;
  canCreateAccount: boolean;
}

export const PublicTopBarDesktopAccountInfo = memo(
  ({ account: { lastName, firstName, email }, canCreateAccount }: PublicTopBarAccountInfoProps) => {
    return (
      <div className="flex items-center gap-2">
        <p data-testid="PUBLIC_TOP_BAR_ACCOUNT_NAME" className="truncate text-16 text-grey-10">
          {formatFullName(firstName, lastName)} ({email})
        </p>
        {canCreateAccount && (
          <Link
            data-testid="PUBLIC_TOP_BAR_CREATE_ACCOUNT_LINK"
            className="text-14 font-semibold text-grey-10 hover:underline"
            href={`${Routes.auth.signUp}?email=${encodeURIComponent(email)}`}
          >
            Create account
          </Link>
        )}
      </div>
    );
  },
);

PublicTopBarDesktopAccountInfo.displayName = 'PublicTopBarAccountInfo';
