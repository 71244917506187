import { FiltersButton } from '~/components/Filters/FiltersButton/FiltersButton';
import { FiltersPopoverTriggerProps } from '~/components/Filters/FiltersButton/FiltersDropdownTrigger';
import { useAppliedFiltersCount } from '~/hooks/filters/useAppliedFiltersCount';

export type BoardFiltersButtonProps = FiltersPopoverTriggerProps;

export const BoardFiltersButton = (props: BoardFiltersButtonProps) => {
  const { filtersCount } = useAppliedFiltersCount();

  return <FiltersButton filtersCount={filtersCount} {...props} />;
};
