import { useShortIdContext } from '@air/provider-short-id';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { getTimestampMarkers } from '~/components/AssetModal/Visualizer/VideoVisualizer/utils';
import { VideoVisualizerInner } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/VideoVisualizerInner';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedClipSelector } from '~/store/centralizedClip/selectors';
import { usePublicDiscussions } from '~/swr-hooks/discussions/usePublicDiscussions';
import { canEditAssetDiscussions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicVideoVisualizer = memo(() => {
  const boardId = useURLBoardIdSelector();
  const clip = useSelector(centralizedClipSelector);
  const { shortId } = useShortIdContext();
  const { permissions } = usePublicPermissions();
  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canEditDiscussions = canEditAssetDiscussions(permissions);

  const { data: discussions } = usePublicDiscussions({
    boardId,
    assetId: clip.assetId,
    clipId: clip.id,
    shortId,
    isEnabled: canViewDiscussions,
  });

  const markers =
    discussions?.length && canViewDiscussions
      ? getTimestampMarkers({
          data: discussions,
          currentVersion: clip.version,
          filter: ASSET_MODAL_DISCUSSION_FILTERS.OPENED,
        })
      : [];

  return <VideoVisualizerInner clip={clip} markers={markers} canEditDiscussions={canEditDiscussions} />;
});

PublicVideoVisualizer.displayName = 'PublicVideoVisualizer';
