import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeZippingTasksSelectorByShortId } from '~/store/tasks/selectors';
import { ReadyState } from '~/types/sockets';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { usePublicSyncTasks } from '~/utils/taskUtils/usePublicSyncTasks';

import { usePublicWorkspace } from '../PublicWorkspaceProvider';
import { ZippingTasksPanel } from './ZippingTasksPanel';

export const PublicZippingTasksPanel = memo(() => {
  const { workspaceId } = usePublicWorkspace();
  const { shortId } = useShortIdContext();
  const tasksSelector = useMemo(() => makeZippingTasksSelectorByShortId(shortId), [shortId]);
  const dispatch = useDispatch();
  const zippingTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();

  const getDownloadUrl = useCallback(
    (taskId: string | undefined) => {
      if (!taskId) {
        throw new Error('No task id');
      }
      return ShortUrl.Tasks.ContentExporter.getDownloadUrl(shortId, { taskId });
    },
    [shortId],
  );

  const { loadFromStorage, syncLocalTasks } = usePublicSyncTasks({
    workspaceId,
    shortId,
    tasksSelector,
    localType: 'ZippingForDownload',
    remoteType: 'ContentExporter',
    onComplete: async ({ task, enrichMetadata }) => {
      const { downloadUrl } = await getDownloadUrl(task.id);

      enrichMetadata({
        url: downloadUrl,
      });
    },
    onUpdate: async ({ task, enrichMetadata }) => {
      if (task.data?.secondsRemaining) {
        enrichMetadata({
          secondsRemaining: task.data.secondsRemaining,
        });
      }
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    loadFromStorage();
  }, [loadFromStorage]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (not possible in a public board but better safe than sorry),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: zippingTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (zippingTasks.length < 1) {
    return null;
  }

  return (
    <ZippingTasksPanel
      onHandleExpiredLink={getDownloadUrl}
      zippingTasks={zippingTasks}
      onCancel={cancelTask}
      onClear={cancelTask}
    />
  );
});

PublicZippingTasksPanel.displayName = 'PublicZippingTasksPanel';
