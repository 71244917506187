import { PublicClip } from '@air/api';
import { PublicBoard } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import React, { useMemo } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import { PublicAssetTableRow } from '~/components/TableView/PublicTableView/PublicAssetTableRow';
import { PublicBoardTableRow } from '~/components/TableView/PublicTableView/PublicBoardTableRow';
import { getLoaderRow, MOBILE_TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT } from '~/components/TableView/tableRowUtils';
import { AirTableRow } from '~/components/TableView/types';
import { usePublicWorkspaceHorizontalPadding } from '~/hooks/usePublicWorkspaceHorizontalPadding';
import { UseGalleryMixedData } from '~/swr-hooks/gallery/types';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';

export interface UsePublicGetTableRowsParams
  extends Pick<UseGalleryMixedData<PublicBoard, PublicClip>, 'data' | 'hasMore' | 'isLoading' | 'isLoadingMore'> {
  loadMore: () => void;
  isBoardSelectable: boolean;
  isAssetSelectable: boolean;
}

export const usePublicTableRows = ({
  data,
  hasMore,
  isLoading,
  isLoadingMore,
  loadMore,
  isBoardSelectable,
  isAssetSelectable,
}: UsePublicGetTableRowsParams) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { responsiveHorizontalPadding } = usePublicWorkspaceHorizontalPadding();

  const rows = useMemo(() => {
    const tableRows: AirTableRow[] = [];

    data.forEach((rowData) => {
      switch (rowData.type) {
        case 'board':
          tableRows.push({
            height: !isAboveMediumScreen ? MOBILE_TABLE_ROW_HEIGHT : TABLE_ROW_HEIGHT,
            getMetadata: () => ({
              isSelectable: isBoardSelectable,
              item: rowData.item,
              type: GalleryItemType.board,
            }),
            // eslint-disable-next-line react/display-name
            render: (rowStyle, key) => (
              <PublicBoardTableRow
                key={key}
                data={rowData.item}
                style={{
                  paddingLeft: responsiveHorizontalPadding,
                  paddingRight: responsiveHorizontalPadding,
                  ...rowStyle,
                }}
              />
            ),
          });
          break;

        case 'asset':
        case 'file':
          tableRows.push({
            height: !isAboveMediumScreen ? MOBILE_TABLE_ROW_HEIGHT : TABLE_ROW_HEIGHT,
            getMetadata: () => ({
              isSelectable: isAssetSelectable,
              item: rowData.item,
              type: getAssetGalleryItemType(rowData.item),
            }),
            // eslint-disable-next-line react/display-name
            render: (rowStyle, key) => <PublicAssetTableRow key={key} data={rowData.item} style={rowStyle} />,
          });
          break;
      }
    });

    if (hasMore || isLoading || isLoadingMore) {
      tableRows.push(getLoaderRow(isLoading, loadMore));
    }

    return tableRows;
  }, [
    data,
    hasMore,
    isLoading,
    isLoadingMore,
    isAboveMediumScreen,
    isBoardSelectable,
    responsiveHorizontalPadding,
    isAssetSelectable,
    loadMore,
  ]);

  return rows;
};
