import { ShortUrl } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicCreatorsListKey = ({
  shortId,
  search,
  boardId,
}: {
  shortId: string | undefined;
  search: string;
  boardId: string | undefined;
}) => ['PUBLIC_CREATORS', { shortId, search, boardId }];

export const usePublicCreatorsList = ({
  search,
  shortId,
  boardId,
}: {
  search: string;
  shortId: string;
  boardId: Board['id'] | undefined;
}) => {
  const key = getPublicCreatorsListKey({ shortId, search, boardId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 20,
        shortId,
        descendantBoardId: boardId,
        field: 'creator',
        search,
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!shortId,
  });
};
