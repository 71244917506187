import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { getPublicBoardCustomFieldsKey } from '~/constants/react-query-keys';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export const usePublicBoardCustomFieldsData = () => {
  const { shortId } = useShortIdContext();
  const boardId = useURLBoardIdSelector();

  const { permissions } = usePublicPermissions();

  const canViewCustomFields = canSeeBoardCustomFields(permissions);

  return useQuery({
    queryKey: getPublicBoardCustomFieldsKey(boardId, shortId),
    queryFn: () => ShortUrl.listBoardCustomFields(shortId, boardId!, { limit: 100 }),
    enabled: !!boardId && canViewCustomFields,
  });
};
