import { memo } from 'react';

import {
  VersionListItem,
  VersionListItemProps,
} from '~/components/AssetModal/AssetModalFooter/VersionList/VersionListContent/VersionListItem/VersionListItem';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';

export type PublicVersionListItemProps = Omit<VersionListItemProps, 'permissions'>;

export const PublicVersionListItem = memo(({ ...rest }: PublicVersionListItemProps) => {
  const { permissions } = usePublicPermissions();

  return <VersionListItem {...rest} permissions={permissions} />;
});

PublicVersionListItem.displayName = 'PublicVersionListItem';
