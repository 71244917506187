import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';

import { PublicCopyrightSelect } from '~/components/Filters/CopyrightFilter/components/PublicCopyrightSelect';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { StringArrayFilterCard } from '~/components/Filters/StringArrayFilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';

export interface PublicCopyrightFilterCardProps extends BaseFilterCardProps {
  boardId: Board['id'] | undefined;
}

export const PublicCopyrightFilterCard = memo(({ boardId, ...props }: PublicCopyrightFilterCardProps) => {
  const renderCopyrightSelect = useCallback(
    (props: BaseStringSelectProps) => <PublicCopyrightSelect {...props} boardId={boardId} />,
    [boardId],
  );

  return (
    <StringArrayFilterCard
      filter="copyrights"
      filterCardType="copyright"
      StringSelect={renderCopyrightSelect}
      {...props}
    />
  );
});

PublicCopyrightFilterCard.displayName = 'PublicCopyrightFilterCard';
