import { useShortIdContext } from '@air/provider-short-id';
import { memo } from 'react';

import { AudioSampleRatesSelect } from '~/components/Filters/AudioSampleRateFilter/components/AudioSampleRatesSelect';
import { BaseStringSelectProps } from '~/components/StringSelect';
import { usePublicAudioSampleRatesList } from '~/swr-hooks/filters/usePublicAudioSampleRatesList';

interface PublicAudioSampleRatesSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicAudioSampleRatesSelect = memo(({ boardId, ...props }: PublicAudioSampleRatesSelectProps) => {
  const { shortId } = useShortIdContext();

  const { data = [], isLoading } = usePublicAudioSampleRatesList({
    shortId,
    boardId,
  });

  return <AudioSampleRatesSelect isLoading={isLoading} audioRates={data} {...props} />;
});

PublicAudioSampleRatesSelect.displayName = 'PublicAudioSampleRatesSelect';
