import { useSelector } from 'react-redux';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useDownloadPublicBoard } from '~/hooks/useDownloadPublicBoard';
import { useGetPublicBoardMenuOptions } from '~/hooks/useGetPublicBoardMenuOptions';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPublicBoard } from '~/store/centralizedBoard/utils';
import { canExportBoard } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export const usePublicBoardHeaderMenuOptions = () => {
  const board = useSelector(centralizedBoardSelector);
  const { copyToAir } = useCopyToWorkspace();
  const { downloadPublicBoard } = useDownloadPublicBoard();
  const { getBoardMenuOptions } = useGetPublicBoardMenuOptions();
  const { permissions } = usePublicPermissions();
  const canDownload = canGenerateZip(permissions);
  const canCopyToAir = canExportBoard(permissions);

  const headerMenuOptions =
    board && isPublicBoard(board)
      ? getBoardMenuOptions({
          onCopyToAir: canCopyToAir
            ? () => {
                copyToAir({
                  boardId: board.id,
                  trackLocation: 'public-gallery-page',
                });
              }
            : undefined,
          onDownload: canDownload
            ? () => {
                downloadPublicBoard({
                  boardId: board.id,
                  trackLocation: 'public-gallery-page',
                });
              }
            : undefined,
        })
      : [];

  return {
    headerMenuOptions,
  };
};
