import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo } from 'react';

import { useAnnotationsToggle } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/hooks/useAnnotationsToggle';

export interface ToggleAnnotationsButtonProps {
  className?: string;
}

export const ToggleAnnotationsButton = memo(({ className }: ToggleAnnotationsButtonProps) => {
  const { showAnnotations, isAnnotationsEnabled, hideAnnotations } = useAnnotationsToggle();

  return (
    <Button
      appearance="filled"
      className={tailwindMerge(
        'bg-pigeon-700 text-pigeon-25 hover:bg-pigeon-100/25 hover:text-pigeon-25 active:bg-pigeon-800 active:text-pigeon-600 disabled:bg-pigeon-800 disabled:text-pigeon-600',
        className,
      )}
      color="grey"
      data-testid="TOGGLE_SHOW_ANNOTATIONS_BUTTON"
      onClick={isAnnotationsEnabled ? hideAnnotations : showAnnotations}
      size="large"
    >
      {isAnnotationsEnabled ? 'Hide Annotations' : 'Show Annotations'}
    </Button>
  );
});

ToggleAnnotationsButton.displayName = 'ToggleAnnotationsButton';
