import { type Board } from '@air/api/types';
import { Download } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useCallback } from 'react';

import { BoardStatsBar } from '~/_components/BoardStatsBar';
import { ToggleNestedItemsButton } from '~/_components/ToggleNestedItemsButton';
import { useFormatBoardStats } from '~/_hooks/useFormatBoardStats';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useDownloadPublicBoard } from '~/hooks/useDownloadPublicBoard';
import { useGetPublicBoardStats } from '~/swr-hooks/boards/useGetPublicBoardsStats';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export type PublicBoardStatsBarProps = {
  boardId: Board['id'];
};

export const PublicBoardStatsBar = memo(({ boardId }: PublicBoardStatsBarProps) => {
  const { isFlattenedView } = useIsFlattenedView();
  const { permissions } = usePublicPermissions();
  const { downloadPublicBoard } = useDownloadPublicBoard();
  const { data } = useGetPublicBoardStats({ boardId, includeDescendants: isFlattenedView });
  const { formatBoardStats } = useFormatBoardStats();
  const { isSearchActive } = useIsSearchActive();
  const { isAboveMediumScreen } = useBreakpointsContext();

  const isEmptyBoard =
    data?.assetCount === 0 && data?.fileCount === 0 && data?.sizeBytes === 0 && data?.subBoardCount === 0;

  const isDownloadVisible = canGenerateZip(permissions) && !!boardId;

  const onDownloadClick = useCallback(() => {
    if (boardId) {
      downloadPublicBoard({ boardId, trackLocation: 'board-stats-bar' });
    }
  }, [boardId, downloadPublicBoard]);

  return !isEmptyBoard && data ? (
    <BoardStatsBar
      actions={
        !isSearchActive && (
          <>
            {isAboveMediumScreen && (
              <>
                <ToggleNestedItemsButton />
                {isDownloadVisible && <div className="h-8 w-px bg-grey-4" />}
              </>
            )}

            {isDownloadVisible && (
              <Tooltip label="Download board">
                <IconButton
                  appearance="ghost"
                  color="grey"
                  icon={Download}
                  label="Download board"
                  onClick={onDownloadClick}
                />
              </Tooltip>
            )}
          </>
        )
      }
      stats={formatBoardStats(data)}
    />
  ) : null;
});

PublicBoardStatsBar.displayName = 'PublicBoardStatsBar';
