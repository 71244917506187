import { memo } from 'react';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { PublicCustomFieldsProvider, PublicCustomFieldsProviderProps } from '~/providers/PublicCustomFieldsProvider';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

export const PublicBoardCustomFieldsProvider = memo(
  (props: Omit<PublicCustomFieldsProviderProps, 'canViewCustomFields'>) => {
    const { permissions } = usePublicPermissions();
    const canViewCustomFields = canSeeCustomFields(permissions);

    return <PublicCustomFieldsProvider canViewCustomFields={canViewCustomFields} {...props} />;
  },
);

PublicBoardCustomFieldsProvider.displayName = 'PublicBoardCustomFieldsProvider';
