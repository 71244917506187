import { Button } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER } from '~/constants/testIDs';
import { useDownloadPublicBoard } from '~/hooks/useDownloadPublicBoard';
import { BoardPageBoard } from '~/store/centralizedBoard/types';
import { getDownloadMenuOptions } from '~/utils/menuOptions/getDownloadMenuOptions';

interface PublicDownloadButtonProps {
  board: BoardPageBoard;
}

export const PublicBoardDownloadMenu = ({ board }: PublicDownloadButtonProps) => {
  const { downloadPublicBoard } = useDownloadPublicBoard();
  const { copyToAir } = useCopyToWorkspace();

  const menuOptions = getDownloadMenuOptions({
    onDownload: () =>
      downloadPublicBoard({
        boardId: board.id,
        trackLocation: 'header',
      }),
    onSaveToAir: () =>
      copyToAir({
        boardId: board.id,
        trackLocation: 'header',
      }),
  });

  return (
    <DropdownMenu
      trigger={
        <Button
          appearance="filled"
          color="blue"
          size="large"
          className="ml-3"
          data-testid={PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER}
        >
          Save to...
        </Button>
      }
    >
      {renderDropdownItems({ options: menuOptions })}
    </DropdownMenu>
  );
};
