import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

interface MobileVersionsPanelWrapperProps {
  onClose: () => void;
  children: React.ReactNode;
}

export const MobileVersionsPanelWrapper = memo(({ onClose, children }: MobileVersionsPanelWrapperProps) => {
  return (
    <div className="fixed z-1 flex h-screen w-screen flex-col bg-grey-1">
      <div className="flex h-12 w-full items-center justify-between border-b border-grey-5 px-4">
        <h4 className="text-14 font-semibold text-grey-12">Versions</h4>
        <IconButton
          appearance="ghost"
          color="grey"
          icon={Close}
          label="Close versions"
          onClick={onClose}
          size="medium"
        />
      </div>
      <div className="flex w-full flex-1 flex-col items-start justify-start overflow-auto">{children}</div>
    </div>
  );
});

MobileVersionsPanelWrapper.displayName = 'MobileVersionsPanelWrapper';
