import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';

import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { getPublicClipShareUrl } from '~/swr-hooks/shortUrl/utils';
import { useAirStore } from '~/utils/ReduxUtils';

import { HeaderShareButton } from './HeaderShareButton';

export const PublicAssetHeaderShareButton = memo(() => {
  const store = useAirStore();
  const [sharePublicClip] = useAirModal(SharePublicClipModal);
  const { shortId } = useShortIdContext();

  const onClick = useCallback(() => {
    const clipId = centralizedClipIdSelector(store.getState());

    sharePublicClip({
      id: clipId,
      url: getPublicClipShareUrl(shortId),
      trackLocation: 'public-asset-page',
    });
  }, [sharePublicClip, shortId, store]);

  return <HeaderShareButton onClick={onClick} />;
});

PublicAssetHeaderShareButton.displayName = 'PublicAssetHeaderShareButton';
