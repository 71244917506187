import { BoardAncestors, Library } from '@air/api/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';

import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export interface PrivateGalleryBoardAncestorsProps {
  libraryTitle: Library['title'] | undefined;
  ancestors: BoardAncestors | undefined;
}

export const PrivateGalleryBoardAncestors = memo(({ libraryTitle, ancestors }: PrivateGalleryBoardAncestorsProps) => {
  const { libraries: librariesFlag } = useFlags();

  const { currentWorkspace } = useCurrentWorkspace();
  const { generalLibraryTitle } = useGeneralLibraryTitle();

  if (!ancestors?.length) {
    if (librariesFlag) {
      return <>{libraryTitle ?? generalLibraryTitle}</>;
    } else {
      return <>{currentWorkspace?.name}</>;
    }
  }

  const nearestAncestorTitle = ancestors[ancestors.length - 1].title;

  return <>{ancestors.length > 1 ? `··· / ${nearestAncestorTitle}` : nearestAncestorTitle}</>;
});

PrivateGalleryBoardAncestors.displayName = 'PrivateGalleryBoardAncestors';
