import { motion, Transition } from 'framer-motion';
import { memo } from 'react';

const animationSettings: Transition = {
  type: 'ease-in-out',
  repeat: Infinity,
  repeatType: 'reverse',
  duration: 2,
};

export const Animated404 = memo(() => {
  return (
    <div className="flex font-gelica text-[72px] font-extralight text-blue-12">
      <motion.p animate={{ y: [-9, 0] }} transition={animationSettings}>
        4
      </motion.p>
      <motion.p animate={{ y: [0, -8] }} transition={animationSettings}>
        0
      </motion.p>
      <motion.p animate={{ y: [-18, -9] }} transition={animationSettings}>
        4
      </motion.p>
    </div>
  );
});

Animated404.displayName = 'Animated404';
