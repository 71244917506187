import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';

import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { getPublicClipInABoardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { getBoardIdFromPath, getClipIdFromPath } from '~/utils/PathUtils';

import { HeaderShareButton } from './HeaderShareButton';

export const PublicBoardAssetHeaderShareButton = memo(() => {
  const [sharePublicClip] = useAirModal(SharePublicClipModal);
  const { shortId } = useShortIdContext();

  const onClick = useCallback(() => {
    const clipId = getClipIdFromPath(window.location.pathname);
    const boardId = getBoardIdFromPath(window.location.pathname);

    if (!boardId || !clipId) return;
    sharePublicClip({
      id: clipId,
      url: getPublicClipInABoardShareUrl(shortId, clipId, boardId),
      trackLocation: 'public-asset-page',
    });
  }, [sharePublicClip, shortId]);

  return <HeaderShareButton onClick={onClick} />;
});

PublicBoardAssetHeaderShareButton.displayName = 'PublicBoardAssetHeaderShareButton';
