import { Permissions } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

import { ONE_DAY } from '~/constants/unitsOfTime';

const getAnyPermissionsKey = (shortId: string | undefined) => ['ANY_PUBLIC_PERMISSIONS', shortId];

export const useFetchAnyPublicPermissions = () => {
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getAnyPermissionsKey(shortId),

    queryFn: () =>
      Permissions.getFromObjects({
        shortId,
        mode: 'any',
        objects: undefined,
      }),
    staleTime: ONE_DAY,
    enabled: !!shortId,
  });
};
