import { Portal } from '@air/primitive-portal';
import { memo, useMemo } from 'react';

import { PublicAssetMobileCommentsPanel } from '~/_app/(public-asset)/_components/PublicAssetMobileCommentsPanel';
import { PublicMobileAssetModalNavigation } from '~/_app/(public-asset)/_components/PublicAssetMobileNavigation';
import { AssetModalMobileLayout } from '~/components/AssetModal/AssetModalMobileLayout';
import { MobileHeader } from '~/components/AssetModal/components/MobileHeader';
import { PublicMobileVersionsPanelWrapper } from '~/components/AssetModal/MobileTabNavigation/MobileVersionsPanelWrapper/PublicMobileVersionsPanelWrapper';
import { PublicImageVisualizer } from '~/components/AssetModal/Visualizer/ImageVisualizer/PublicImageVisualizer';
import { PublicPDFVisualizer } from '~/components/AssetModal/Visualizer/PDFVisualizer/PublicPDFVisualizer';
import { PublicAssetModalInfoPanel } from '~/components/PublicAssetModal/components/PublicAssetModalInfoPanel';
import { PublicVideoVisualizer } from '~/components/PublicAssetModal/components/PublicVideoVisualizer';
import { PublicAssetModalBanner } from '~/components/PublicAssetModal/shared/components/PublicAssetModalBanner/PublicAssetModalBanner';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { MobileAssetModalContextProvider } from '~/providers/MobileAssetModalContextProvider';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicAssetModalMobileLayout = memo(() => {
  const { panel, hidePanel } = useAssetModalPanelContext();

  const { permissions } = usePublicPermissions();

  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canViewAllVersions = canSeeAssetVersions(permissions);

  const content = useMemo(
    () => (
      <>
        {panel === AssetModalPanel.COMMENTS && canViewDiscussions ? (
          <Portal>
            <div className="fixed inset-0 flex h-screen w-screen flex-col items-start justify-start overflow-y-auto bg-grey-1">
              <PublicAssetMobileCommentsPanel />
            </div>
          </Portal>
        ) : panel === AssetModalPanel.VERSIONS && canViewAllVersions ? (
          <PublicMobileVersionsPanelWrapper onClose={hidePanel} />
        ) : null}
        <PublicAssetModalInfoPanel permissions={permissions} />
      </>
    ),
    [canViewAllVersions, canViewDiscussions, hidePanel, panel, permissions],
  );

  const header = useMemo(() => <MobileHeader />, []);

  return (
    <MobileAssetModalContextProvider stickOnTop={true}>
      <AssetModalMobileLayout
        Banner={PublicAssetModalBanner}
        PDFVisualizer={PublicPDFVisualizer}
        VideoVisualizer={PublicVideoVisualizer}
        ImageVisualizer={PublicImageVisualizer}
        panel={content}
        header={header}
        navigation={<PublicMobileAssetModalNavigation />}
      />
    </MobileAssetModalContextProvider>
  );
});

PublicAssetModalMobileLayout.displayName = 'PublicAssetModalMobileLayout';
