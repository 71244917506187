import { PublicBoard } from '@air/api/types';
import { memo } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import { TableAssetTitle } from '~/components/TableView/NameCell/NameCellContent/TableAssetTitle';

export interface PublicBoardNameCellTitleProps {
  onClick: ItemProps['onItemClick'];
  onCmdClick?: ItemProps['onItemCmdClick'];
  board: PublicBoard;
}

export const PublicBoardNameCellTitle = memo(({ onClick, onCmdClick, board }: PublicBoardNameCellTitleProps) => {
  return (
    <div className="relative ml-3" data-disableselect="true">
      <TableAssetTitle title={board.title} onClick={onClick} onCmdClick={onCmdClick} />
    </div>
  );
});

PublicBoardNameCellTitle.displayName = 'PublicBoardNameCellTitle';
