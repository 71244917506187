import { BoardCustomFieldValue, CustomFieldTypeName, PublicBoard } from '@air/api/types';
import { useCallback } from 'react';

import { PublicBoardCustomFieldDateInput } from '~/components/PublicBoard/PublicBoardCustomFieldDateInput';
import { PublicBoardCustomFieldPlainText } from '~/components/PublicBoard/PublicBoardCustomFieldPlainText';
import { PublicBoardCustomFieldSelect } from '~/components/PublicBoard/PublicBoardCustomFieldSelect';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { BaseCustomFieldTableCell } from './BaseCustomFieldTableCell';
import { BoardCustomFieldTableCellProps } from './types';

export const PublicBoardCustomFieldTableCell = ({
  board,
  customFieldName,
  customFieldId,
}: BoardCustomFieldTableCellProps<PublicBoard>) => {
  const actualCustomFieldValue = board.customFields?.find((customField) => customField.id === customFieldId);
  const assignedCustomFieldValue = actualCustomFieldValue as AssignedCustomFieldValue | undefined;

  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const { customFields } = usePublicCustomFieldsContext();
  const customField = customFields?.find((field) => field.id === customFieldId);

  const renderEditableCustomField = useCallback(
    (closeEditor: () => void) => {
      if (!customField) return null;
      if (!canEditCustomFields) return null;
      const boardCustomFieldValue: BoardCustomFieldValue = actualCustomFieldValue || {
        ...customField,
        boardId: board.id,
        value: null,
        values: null,
      };

      switch (customField.type.title) {
        case CustomFieldTypeName.singleSelect:
        case CustomFieldTypeName.multiSelect: {
          return (
            <PublicBoardCustomFieldSelect
              autoFocus
              boardId={board.id}
              canEditCustomFields={canEditCustomFields}
              onOptionsClose={closeEditor}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.plainText: {
          return (
            <PublicBoardCustomFieldPlainText
              isEditing
              boardId={board.id}
              canEditCustomFields={canEditCustomFields}
              onBlur={closeEditor}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.date: {
          return (
            <PublicBoardCustomFieldDateInput
              isEditing
              boardId={board.id}
              canEditCustomFields={canEditCustomFields}
              field={boardCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
      }
    },
    [actualCustomFieldValue, board.id, canEditCustomFields, customField],
  );

  return (
    <BaseCustomFieldTableCell
      customFieldName={customFieldName}
      assignedCustomFieldValue={assignedCustomFieldValue}
      renderEditableCustomField={canEditCustomFields ? renderEditableCustomField : undefined}
    />
  );
};
