import { Portal } from '@air/primitive-portal';
import { memo } from 'react';

import { PoweredByAir } from '~/components/PoweredByAir';

export const AnonymousSignUpOverlay = memo(() => (
  <Portal>
    <div
      data-testid="ANONYMOUS_SIGNUP_MODAL_OVERLAY"
      className="pointer-events-none fixed inset-0 bg-black/60 backdrop-blur-sm"
    >
      <PoweredByAir />
    </div>
  </Portal>
));

AnonymousSignUpOverlay.displayName = 'AnonymousSignUpOverlay';
