import { useTrackClosedVersionsTray } from '@air/analytics';
import { memo, useMemo } from 'react';

import { useCanAnnotateCentralizedPublicClip } from '~/components/Annotations/hooks/useCanAnnotateCentralizedPublicClip';
import { ToggleAnnotationsButton } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/components/ToggleAnnotationsButton';
import { EditAnnotationsButton } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/EditAnnotationsButton';
import { AssetModalFooter } from '~/components/AssetModal/AssetModalFooter/AssetModalFooter';
import { ToggleVersionsPanelButton } from '~/components/AssetModal/AssetModalFooter/VersionList/components/ToggleVersionsPanelButton';
import { VersionListHeader } from '~/components/AssetModal/AssetModalFooter/VersionList/components/VersionListHeader';
import { useVersionsPanelState } from '~/components/AssetModal/AssetModalFooter/VersionList/hooks/useVersionsPanelState';
import { VersionList } from '~/components/AssetModal/AssetModalFooter/VersionList/VersionList';
import { PublicVersionsListContent } from '~/components/PublicAssetModal/components/PublicVersionsListContent/PublicVersionsListContent';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { canSeeAssetVersions } from '~/utils/permissions/assetPermissions';

export const PublicAssetModalFooter = memo(() => {
  const { permissions } = usePublicPermissions();
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const { trackClosedVersionsTray } = useTrackClosedVersionsTray();
  const { isVersionsPanelOpen, setVersionsPanelOpen } = useVersionsPanelState();

  const { canSeeClipAnnotation, canEditAnnotation } = useCanAnnotateCentralizedPublicClip();

  const closePanel = () => {
    trackClosedVersionsTray();
  };

  const AnnotateButton = useMemo(() => {
    if (canEditAnnotation) {
      return <EditAnnotationsButton className="absolute right-4" />;
    } else if (canSeeClipAnnotation) {
      return <ToggleAnnotationsButton className="absolute right-4" />;
    }
  }, [canEditAnnotation, canSeeClipAnnotation]);

  return (
    <AssetModalFooter
      header={
        <>
          {canViewAssetVersions && (
            <ToggleVersionsPanelButton isVersionsOpen={isVersionsPanelOpen} setIsVersionsOpen={setVersionsPanelOpen} />
          )}
          {AnnotateButton}
        </>
      }
      className={isVersionsPanelOpen ? 'h-[280px]' : 'h-16'}
    >
      {canViewAssetVersions && (
        <VersionList isOpen={isVersionsPanelOpen}>
          <>
            <VersionListHeader closePanel={closePanel} />
            <PublicVersionsListContent />
          </>
        </VersionList>
      )}
    </AssetModalFooter>
  );
});

PublicAssetModalFooter.displayName = 'PublicAssetModalFooter';
