import { ShortUrl } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

const getPublicExtensionsListKey = ({
  shortId,
  search,
  boardId,
}: {
  shortId: string | undefined;
  search: string;
  boardId: Board['id'] | undefined;
}) => ['PUBLIC_EXTENSIONS', { shortId, search, boardId }];

export const usePublicExtensionsList = ({
  search,
  shortId,
  boardId,
}: {
  search: string;
  shortId: string;
  boardId: Board['id'] | undefined;
}) => {
  const key = getPublicExtensionsListKey({ shortId, search, boardId });

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(shortId, 'Short ID is required');

      const data = await ShortUrl.getAssetVersionFilterLookups({
        limit: 20,
        descendantBoardId: boardId,
        shortId,
        field: 'extension',
        search,
      });

      return data.lookups.map((lookup) => lookup.value.toString());
    },
    enabled: !!shortId,
  });
};
