import { memo } from 'react';

import { AssetModalHeaderTitle } from '~/components/AssetModal/Header/components/AssetModalHeaderTitle';
import { AssetModalHeaderTitleContainer } from '~/components/AssetModal/Header/components/AssetModalHeaderTitleContainer';

export const PublicAssetModalHeaderTitle = memo(() => {
  return (
    <AssetModalHeaderTitleContainer>
      <AssetModalHeaderTitle />
    </AssetModalHeaderTitleContainer>
  );
});

PublicAssetModalHeaderTitle.displayName = 'PublicAssetModalHeaderTitle';
