import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback, useMemo } from 'react';

import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { getPublicClipShareUrl } from '~/swr-hooks/shortUrl/utils';
import { useAirStore } from '~/utils/ReduxUtils';

import { usePublicDownloadOptions } from '../shared/hooks/usePublicDownloadOptions';

export const PublicMobileEllipsisMenu = memo(() => {
  const downloadOptions = usePublicDownloadOptions();
  const [sharePublicClip] = useAirModal(SharePublicClipModal);
  const store = useAirStore();
  const { shortId } = useShortIdContext();

  const onShareClick = useCallback(() => {
    const clipId = centralizedClipIdSelector(store.getState());

    if (!clipId) return;
    sharePublicClip({
      id: clipId,
      url: getPublicClipShareUrl(shortId),
      trackLocation: 'public-asset-page',
    });
  }, [sharePublicClip, shortId, store]);

  const options = useMemo<DropdownMenuOption[]>(() => {
    return [
      {
        id: 'share',
        label: 'Share',
        onSelect: onShareClick,
        type: 'item',
      },
      ...downloadOptions,
    ];
  }, [downloadOptions, onShareClick]);

  return (
    <DropdownMenu
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          icon={EllipsisVertical}
          size="large"
          className="mr-3"
          label="See options"
        />
      }
    >
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

PublicMobileEllipsisMenu.displayName = 'PublicMobileEllipsisMenu';
