import { ShortUrl } from '@air/api';
import { subWeeks } from 'date-fns';
import { useCallback } from 'react';

const getPageViewNotificationKey = (shortId: string) => `airShortUrlPageViewNotificationTimestamp_${shortId}`;

/**
 * This hook returns a method that will trigger a page view notification if the user has not
 * visited the page within the last week.
 */
export const usePublicPageViewNotification = () => {
  const sendPublicPageViewEvent = useCallback((shortId: string) => {
    const key = getPageViewNotificationKey(shortId);
    const localStorageValue = window.localStorage.getItem(key);
    const storageDate = localStorageValue ? new Date(localStorageValue) : null;

    const currentDate = new Date();
    const oneWeekFromCurrentDate = subWeeks(currentDate, 1);

    if ((!storageDate || storageDate < oneWeekFromCurrentDate) && !!shortId) {
      ShortUrl.view({ shortId });
      window.localStorage.setItem(key, currentDate.toISOString());
    }
  }, []);

  return {
    sendPublicPageViewEvent,
  };
};
