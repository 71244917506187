import { Ellipsis } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { usePublicBoardHeaderMenuOptions } from '~/components/PublicBoard/usePublicBoardHeaderMenuOptions';
import { BOARD_ACTIONS_SUBMENU_BUTTON } from '~/constants/testIDs';

export const PublicBoardMoreOptionsButton = memo(() => {
  const { headerMenuOptions } = usePublicBoardHeaderMenuOptions();

  if (!headerMenuOptions.length) {
    return null;
  }

  return (
    <DropdownMenuWithActionSheet
      isTitleHidden
      options={headerMenuOptions}
      title="More options"
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          data-testid={BOARD_ACTIONS_SUBMENU_BUTTON}
          icon={Ellipsis}
          label="More options"
          size="large"
        />
      }
    />
  );
});

PublicBoardMoreOptionsButton.displayName = 'PublicBoardMoreOptionsButton';
