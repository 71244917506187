import { useShortIdContext } from '@air/provider-short-id';
import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { BaseStringSelectProps, StringSelect } from '~/components/StringSelect';
import { usePublicAudioCodingsList } from '~/swr-hooks/filters/usePublicAudioCodingsList';

interface PublicAudioCodingsSelectProps extends BaseStringSelectProps {
  boardId: string | undefined;
}

export const PublicAudioCodingsSelect = memo(({ boardId, ...props }: PublicAudioCodingsSelectProps) => {
  const { shortId } = useShortIdContext();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data = [], isLoading } = usePublicAudioCodingsList({
    search: debouncedSearchValue,
    shortId,
    boardId,
  });

  return <StringSelect isLoading={isLoading} values={data} onInputChange={setSearchValue} {...props} />;
});

PublicAudioCodingsSelect.displayName = 'PublicAudioCodingsSelect';
