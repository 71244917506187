import { memo, useMemo } from 'react';

import { VersionListContentContainer } from '~/components/AssetModal/AssetModalFooter/VersionList/VersionListContent/components/VersionListContentContainer';
import { VersionListContentHeader } from '~/components/AssetModal/AssetModalFooter/VersionList/VersionListContent/components/VersionListContentHeader';
import { VersionsListContainer } from '~/components/AssetModal/AssetModalFooter/VersionList/VersionListContent/components/VersionsListContainer';
import { PublicVersionListItem } from '~/components/AssetModal/AssetModalFooter/VersionList/VersionListContent/VersionListItem/PublicVersionListItem';
import { usePublicVersionsList } from '~/swr-hooks/versions/usePublicVersionsList';

export const PublicVersionsListContent = memo(() => {
  const { data: versionsData } = usePublicVersionsList();
  const versions = useMemo(() => versionsData?.data?.clips ?? [], [versionsData?.data?.clips]);

  return (
    <VersionListContentContainer>
      {!!versions.length && (
        <>
          <VersionListContentHeader hasOtherVersions={versions.length > 1} />
          <VersionsListContainer>
            {versions.map((version) => (
              <PublicVersionListItem version={version} key={version.id} />
            ))}
          </VersionsListContainer>
        </>
      )}
    </VersionListContentContainer>
  );
});

PublicVersionsListContent.displayName = 'PublicVersionsListContent';
