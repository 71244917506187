import { Button } from '@air/primitive-button';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { memo } from 'react';

import { AppErrorBackground } from '~/components/AppError/AppErrorBackground';
import { Animated404 } from '~/components/NotFound/Animated404';
import { I18n } from '~/constants/I18n';
import { Routes } from '~/constants/routes';
import { NOT_FOUND_CONTENT } from '~/constants/testIDs';

export const NotFound = memo(() => (
  <>
    <NextSeo title={I18n.notFoundPageTitle} description={I18n.notFoundPageDescription} />
    <AppErrorBackground>
      <Animated404 />
      <h1 className="text-center text-16 font-normal text-grey-11">{I18n.notFoundPageTitle}</h1>
      <p className="mb-3 text-center text-12 text-grey-8" data-testid={NOT_FOUND_CONTENT}>
        {I18n.notFoundPageDescription}
      </p>

      <Button asChild size="extra-large" color="grey">
        <Link href={Routes.media.all}>Go to Air</Link>
      </Button>
    </AppErrorBackground>
  </>
));

NotFound.displayName = 'NotFound';
