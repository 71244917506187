import { memo } from 'react';
import { useSelector } from 'react-redux';

import { centralizedBoardDescriptionSelector } from '~/store/centralizedBoard/selectors';

import { BoardHeaderDescription } from '../../../components/Workspace/BoardHeader/BoardHeaderDescription';

export const PublicBoardHeaderDescription = memo(() => {
  const description = useSelector(centralizedBoardDescriptionSelector);

  if (!description) return null;

  return <BoardHeaderDescription description={description} />;
});

PublicBoardHeaderDescription.displayName = 'PublicBoardHeaderDescription';
