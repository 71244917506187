import { PublicBoard } from '@air/api/types';
import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { itemIsHighlightedOnShiftSelectSelector } from '@air/redux-selected-items';
import { memo, useMemo, useState } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import { BoardCellThumbnail } from '~/components/TableView/NameCell/BoardCellThumbnail';
import { NameCellContent } from '~/components/TableView/NameCell/NameCellContent';
import { PublicBoardNameCellTitle } from '~/components/TableView/PrivateTableView/PublicBoardNameCellTitle';
import { BOARD_NAME_CELL } from '~/constants/testIDs';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface PublicBoardNameCellProps extends ItemProps {
  isHovering: boolean;
  isSelected: boolean;
  board: PublicBoard;
}

export const PublicBoardNameCell = memo(
  ({
    isHovering,
    isSelected,
    board,
    getItemMenuOptions,
    getSelectionMenuOptions,
    onItemClick,
    onItemCmdClick,
  }: PublicBoardNameCellProps) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const isHighlightedFromShiftSelect = useAirSelector((st) => itemIsHighlightedOnShiftSelectSelector(st, board.id));

    const shouldShowMenu = !isHighlightedFromShiftSelect && (isHovering || menuIsOpen);

    const _title = useMemo(() => {
      return <PublicBoardNameCellTitle board={board} onClick={onItemClick} onCmdClick={onItemCmdClick} />;
    }, [board, onItemClick, onItemCmdClick]);

    const _image = useMemo(() => {
      return <BoardCellThumbnail thumbnail={board.thumbnails?.[0]} title={board.title} />;
    }, [board]);

    const dataTestId = BOARD_NAME_CELL;

    const ellipsisMenuOptions = useMemo(
      () => getItemMenuOptions({ trackLocation: 'table-item-ellipsis-menu' }),
      [getItemMenuOptions],
    );

    const selectionMenuOptions = getSelectionMenuOptions('table-multi-select-ellipsis-menu');
    const menuOptions = isSelected && selectionMenuOptions.length ? selectionMenuOptions : ellipsisMenuOptions;

    return (
      <NameCellContent data-testid={dataTestId} title={_title} image={_image}>
        {shouldShowMenu && !!menuOptions.length && (
          <DropdownMenu
            onOpenChange={setMenuIsOpen}
            trigger={
              <IconButton
                appearance="ghost"
                color="grey"
                data-testid={`${dataTestId}-menu`}
                icon={EllipsisVertical}
                label="More options"
                onClick={(event) => event.stopPropagation()}
                onDoubleClick={(event) => event.stopPropagation()}
                size="medium"
              />
            }
          >
            {renderDropdownItems({ options: menuOptions })}
          </DropdownMenu>
        )}
      </NameCellContent>
    );
  },
);

PublicBoardNameCell.displayName = 'PublicBoardNameCell';
