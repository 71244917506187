import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldPlainText,
  CustomFieldPlainTextProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldPlainText';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicBoardCustomFieldPlainTextProps extends Omit<CustomFieldPlainTextProps, 'onSubmit'> {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  boardId: string;
}

export const PublicBoardCustomFieldPlainText = memo(
  ({ field, trackingLocation, canEditCustomFields, boardId, ...rest }: PublicBoardCustomFieldPlainTextProps) => {
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const updateCustomFieldValue = useCallback(
      async (value: string) => {
        await updatePublicItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
        });
      },
      [boardId, updatePublicItemsCustomFieldValue, field, trackingLocation],
    );

    return (
      <CustomFieldPlainText
        {...rest}
        onSubmit={canEditCustomFields ? updateCustomFieldValue : undefined}
        field={field}
      />
    );
  },
);

PublicBoardCustomFieldPlainText.displayName = 'PublicBoardCustomFieldPlainText';
