import { memo, ReactNode } from 'react';

interface GalleryCardImageWrapperProps {
  children?: ReactNode;
}

export const GalleryCardImageWrapper = memo(({ children }: GalleryCardImageWrapperProps) => (
  <div className="pointer-events-none absolute inset-0 overflow-hidden rounded">
    {children}
    <div className="absolute inset-0 bg-gradient-to-t from-black/60 from-20% to-black/20 " />
  </div>
));

GalleryCardImageWrapper.displayName = 'GalleryCardImageWrapper';
