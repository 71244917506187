import { useBreakpointsContext } from '@air/provider-media-query';
import { useCallback, useEffect, useState } from 'react';

export interface UseSearchTokenLogicParams {
  isFocused: boolean;
  hasValue: boolean;
}

export const useSearchTokenLogic = ({ isFocused, hasValue }: UseSearchTokenLogicParams) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const [hasToken, setHasToken] = useState(true);
  const isTokenVisible = hasToken && isAboveMediumScreen && (isFocused || hasValue);

  const showToken = useCallback(() => setHasToken(true), []);

  const hideToken = useCallback(() => setHasToken(false), []);

  useEffect(() => {
    if (!isFocused) {
      showToken();
    }
  }, [isFocused, showToken]);

  return {
    isTokenVisible,
    wasTokenRemoved: !hasToken,
    showToken,
    hideToken,
  };
};
