import { memo, useMemo } from 'react';

import { AssetModalPanelContainer } from '~/components/AssetModal/Panel/Content/Container';
import { CustomFieldsPanel } from '~/components/AssetModal/Panel/Content/ContentRenderer/components/CustomFieldsPanel';
import { AssetCustomFieldsPanelContent } from '~/components/RightPanel/CustomFields/AssetCustomFieldsPanelContent';
import { PublicAssetPanelCustomFieldDateInput } from '~/components/RightPanel/CustomFields/PublicAssetPanelCustomFieldDateInput';
import { PublicAssetPanelCustomFieldPlainText } from '~/components/RightPanel/CustomFields/PublicAssetPanelCustomFieldPlainText';
import { PublicAssetPanelCustomFieldSelect } from '~/components/RightPanel/CustomFields/PublicAssetPanelCustomFieldSelect';
import { usePublicAssetCustomFieldsData } from '~/hooks/usePublicAssetCustomFieldsData';

export const PublicCustomFieldsPanelContainer = memo(() => {
  const { data: customFields } = usePublicAssetCustomFieldsData();

  const fields = useMemo(
    () => (
      <AssetCustomFieldsPanelContent
        fields={customFields?.data}
        SelectCustomField={PublicAssetPanelCustomFieldSelect}
        DateCustomField={PublicAssetPanelCustomFieldDateInput}
        PlainTextCustomField={PublicAssetPanelCustomFieldPlainText}
      />
    ),
    [customFields?.data],
  );

  const panel = useMemo(() => <CustomFieldsPanel fields={fields} />, [fields]);

  return <AssetModalPanelContainer title="CUSTOM FIELDS" panel={panel} />;
});

PublicCustomFieldsPanelContainer.displayName = 'PublicCustomFieldsPanelContainer';
