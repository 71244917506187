import { memo, useMemo } from 'react';

import { AssetModalPanelContainer } from '~/components/AssetModal/Panel/Content/Container';
import { CommentsFilterBar } from '~/components/PrivateAssetModal/PrivateCommentsPanel/components/CommentsFilterBar';
import { CommentsVersionsDropdown } from '~/components/PrivateAssetModal/PrivateCommentsPanel/components/CommentsVersionsDropdown';
import { PublicComments } from '~/components/PublicAssetModal/components/PublicCommentsPanel';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { canSeeAssetVersions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicAssetCommentsPanelContainer = memo(() => {
  const { permissions } = usePublicPermissions();
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canDiscuss = canViewAssetDiscussions(permissions);

  const panel = useMemo(() => <PublicComments />, []);
  const contentHeader = useMemo(
    () =>
      canDiscuss && canViewAssetVersions ? (
        <CommentsFilterBar>
          <CommentsVersionsDropdown />
        </CommentsFilterBar>
      ) : undefined,
    [canDiscuss, canViewAssetVersions],
  );

  return <AssetModalPanelContainer title="COMMENTS" contentHeader={contentHeader} panel={panel} />;
});

PublicAssetCommentsPanelContainer.displayName = 'PublicAssetCommentsPanelContainer';
