import { ViewTypeName } from '@air/api/types';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PublicBoardHeader } from '~/_app/(public-board)/_components/PublicBoardHeader';
import { PublicBoardStatsBar } from '~/_app/(public-board)/_components/PublicBoardStatsBar';
import { BOARD_STATS_BAR_HEIGHT } from '~/_components/BoardStatsBar';
import { PublicBoardCustomFieldsPanel } from '~/components/PublicBoard/components/PublicBoardCustomFieldsPanelContent/PublicBoardCustomFieldsPanel';
import { PublicBoardGalleryView, PublicBoardGalleryViewProps } from '~/components/PublicBoard/PublicBoardGalleryView';
import { PublicBoardTableView } from '~/components/PublicBoard/PublicBoardTableView';
import { PublicBoardsAndAssetsSelectionBar } from '~/components/PublicGallery/PublicBoardsAndAssetsSelectionBar';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useRerenderOnMount } from '~/hooks/useRerenderOnMount';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { setConfigurableViewFieldsAction } from '~/store/configViews/actions';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useGetPublicConfigViewOptions } from '~/swr-hooks/gallery/viewFields/useGetPublicConfigViewsOptions';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PublicKanbanView } from './components/PublicKanbanView/PublicKanbanView';
import { PublicBoardViewControls, PublicBoardViewControlsProps } from './PublicBoardViewControls';

export interface PublicBoardViewProps extends Pick<PublicBoardGalleryViewProps, 'scrollElementRef'> {
  headerRef: PublicBoardViewControlsProps['headerRef'];
}

export const PublicBoardView = memo(({ scrollElementRef, headerRef }: PublicBoardViewProps) => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const { permissions } = usePublicPermissions();
  const canViewCustomFields = canSeeBoardCustomFields(permissions);
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const dispatch = useDispatch();
  const { data: configViewOptions } = useGetPublicConfigViewOptions();
  const { isSearchActive } = useIsSearchActive();

  useEffect(() => {
    if (configViewOptions?.data) {
      dispatch(setConfigurableViewFieldsAction({ configurableViewFields: configViewOptions?.data }));
    }
  }, [configViewOptions?.data, dispatch]);

  /**
   * In order to prevent a rehydration issue, we force this page to re-render once after mount.
   * The scenario we're preventing for is that when a user goes directly to a search result url
   * for a public board, on the server side, isSearchActive is false but on first render client side,
   * it's true. So we need to use isMounted checks and also ensure there are 2 renders to fix this.
   */
  const { isMounted } = useRerenderOnMount();

  if (!boardId) return null;

  return (
    <>
      <div className="grow" style={{ height: `calc(100% - ${BOARD_STATS_BAR_HEIGHT}px)` }}>
        {!isSearchActive && isMounted && <PublicBoardHeader />}
        <PublicBoardViewControls headerRef={headerRef} scrollRef={scrollElementRef} />
        {currentViewTypeName === ViewTypeName.gallery && <PublicBoardGalleryView scrollElementRef={scrollElementRef} />}
        {currentViewTypeName === ViewTypeName.table && <PublicBoardTableView scrollElementRef={scrollElementRef} />}
        {currentViewTypeName === ViewTypeName.kanban && <PublicKanbanView />}

        <PublicBoardsAndAssetsSelectionBar />
      </div>
      <PublicBoardStatsBar boardId={boardId} />
      {canViewCustomFields && <PublicBoardCustomFieldsPanel />}
    </>
  );
});

PublicBoardView.displayName = 'PublicBoardView';
